import { commentsLabelProperties } from '@/config';
import { Box, Grid, Typography } from '@mui/material';

export const StyledComment = ({ comment }) => {
  var moment = require('moment');
  return (
    <Grid container>
      <Grid container item md={12}>
        <Grid item container md={7}>
          <Box sx={{ width: '24px', height: '24px', borderRadius: '50%', overflow: 'hidden', mr: 1 }}>
            <img
              src={
                comment.user.profile_picture_url == ''
                  ? '/assets/images/blank-profile.png'
                  : comment.user.profile_picture_url
              }
              width='100%'
              height='100%'
            />
          </Box>
          <Typography color='#3A3E50' fontWeight={700} fontSize={16}>
            {comment.user.name}{' '}
            {comment.user_type && (
              <span style={{ color: '#8B8D98', fontWeight: 400, fontSize: 14, marginInlineStart: '.25rem' }}>
                {comment.user_type}
              </span>
            )}
          </Typography>
        </Grid>
        <Grid item container md={5} justifyContent='flex-end'>
          <Typography color='text.third' variant='caption'>
            {moment(new Date(comment.created_at)).fromNow()}
          </Typography>
        </Grid>
      </Grid>
      {comment.label && (
        <Grid item md={12} pt={1}>
          <Typography variant='subtitle2' color={commentsLabelProperties[comment.label].textColor}>
            {comment.title}
          </Typography>
        </Grid>
      )}
      <Grid pt={1} item md={12}>
        <Typography variant='body2' color='text.disabled' sx={{ overflow: 'hidden' }}>
          {comment.text}
        </Typography>
      </Grid>
    </Grid>
  );
};
