import { CompanyUserInfo, ConfirmPopup, Loading, SettingsWithCheck, UserSettingsSection, UserTypeSection } from '@/components';
import { AuthenticateStatus, Permissions, statusesTableData } from '@/config';
import { getUsersTranslationKey } from '@/hooks';
import { useAuthorizeUser, useCompanyUserInfo, useUnauthorizeUser } from '@/services';
import { useProcurementStore, useUsersStore } from '@/zustand';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { AllowedTo, NotAllowedTo } from 'react-abac';
import usePopup from 'src/logic/hooks/use-popup';
import { useUserData } from 'src/logic/zustand/user';

type Props = { userId: string };

export const CompanyUsersMainComponent: React.FC<Props> = ({ userId }) => {
    const authenticationStatus = useUserData(state => state.authenticationStatus);
    const { isFetching: isLoading, isSuccess, refetch } = useCompanyUserInfo(userId)
    const user_info = useUsersStore(state => state.user_info);
    const presentable_roles = useUsersStore(state => state.presentable_roles);
    const presentable_permissions = useUsersStore(state => state.presentable_permissions);
    const { t } = useTranslation();
    const onSuccess = () => refetch()
    const unauthorize = useUnauthorizeUser(onSuccess);
    const authorize = useAuthorizeUser(onSuccess);
    const handleUnautherizeAction = () => {
        unauthorize.mutate(userId)
        unAutherizePopup.handleClose()
    }
    const unAutherizePopup = usePopup()
    return (
        <>
            <Grid item xs={12} mt={2}>
                <Typography variant='h1' fontWeight={'bold'}>{t(getUsersTranslationKey('company_user'))}</Typography>
            </Grid>
            {(authenticationStatus == AuthenticateStatus.AUTHENTICATED && !isLoading && isSuccess && user_info) ? (
                <Grid container xs={12} md={8} mb={3} mt={1}>
                    <Grid item xs={12} mb={5} mt={2}>
                        <CompanyUserInfo userData={user_info} userId={userId} refetch={refetch} />
                    </Grid>
                    <Grid item xs={12} mb={5} mt={2}>
                        <AllowedTo perform={Permissions.EDIT_USERS}>
                            <UserTypeSection userData={user_info} userId={userId} refetch={refetch} disabled={false} />
                        </AllowedTo>
                        <NotAllowedTo perform={Permissions.EDIT_USERS}>
                            <UserTypeSection userData={user_info} userId={userId} refetch={refetch} disabled={true} />
                        </NotAllowedTo>
                    </Grid>
                    <Grid item container xs={12} mb={5}>
                        <UserSettingsSection anotherUser={true} userData={user_info} />
                    </Grid>
                    <Grid item container xs={12} mb={5}>
                        <SettingsWithCheck data={presentable_roles} type={'ROLE'} titleKey="roles" userId={userId} canEditPermission={Permissions.EDIT_USERS} />
                    </Grid>
                    <Grid item container xs={12} mb={10}>
                        <SettingsWithCheck data={presentable_permissions} type={'PERMISSION'} titleKey="permissions" userId={userId} canEditPermission={Permissions.EDIT_USERS} />
                    </Grid>
                    <AllowedTo perform={Permissions.EDIT_USERS}>
                        <Grid item container xs={12} mb={5}>
                            {user_info.account_status === statusesTableData.ACTIVE.text ?
                                <Button sx={{ width: 171 }} variant='contained' color="error" onClick={unAutherizePopup.handleOpen}>  {t(getUsersTranslationKey('menu_items.unauthorize'))}</Button>
                                : user_info.account_status === statusesTableData.UNAUTHORIZED.text ?
                                    <Button sx={{ width: 171 }} variant='contained' color="primary" onClick={() => authorize.mutate(userId)}>  {t(getUsersTranslationKey('menu_items.authorize'))}</Button>
                                    : <></>
                            }
                        </Grid>
                    </AllowedTo>
                    <ConfirmPopup
                        buttonTitleKey={t(getUsersTranslationKey('menu_items.unauthorize'))}
                        handleClose={unAutherizePopup.handleClose}
                        open={unAutherizePopup.isOpen}
                        handleConfirm={handleUnautherizeAction}
                        subTitleKey={t('popups.titles.unauthorize_user')}
                        titleKey={t(getUsersTranslationKey('menu_items.unauthorize'))} />
                </Grid>
            ) : (
                <Loading fullHeight={true} />
            )}
        </>
    );
};