import { Loading, RadioButtonGroup, SearchTextField } from '@/components';
import { AuthenticateStatus, productManagerEnv } from '@/config';
import { Product, Requisition } from '@/models';
import { NotifySuccess } from '@/services';
import { useProcurementStore, useUserData } from '@/zustand';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import { useGetEditableRequisitions } from 'src/logic/services/requisitions/queries';

const useRequisitions = () => {
  const isGuest = useUserData().authenticationStatus === AuthenticateStatus.NOT_AUTHENTICATED;
  const { data: requisitions, isFetching } = useGetEditableRequisitions({ enabled: !isGuest });
  const { requisitions: cachedRequisitions } = useProcurementStore();

  if (isGuest) return { requisitions: cachedRequisitions, isFetching: false };
  return { requisitions, isFetching };
};

export const SelectRequisitionPoppup = (product: Product & { onClose: () => void }) => {
  const { onClose } = product;
  const { t } = useTranslation();
  const [searchText, setsearchText] = useState('');
  const [highlitedRequisitionId, setHighlitedRequisitionId] = useState('');

  const { requisitions, isFetching } = useRequisitions();

  const { requisitionSliceActions } = useProcurementStore();
  const { actions } = useProcurementStore(state => state.productFormManagerProperties);
  const handleChangeRequisition = (requisitionId: string) => setHighlitedRequisitionId(requisitionId);
  const addToRequisition = () =>
    actions.selectForm(
      { requisitionId: highlitedRequisitionId, product: product },
      'requisitionSliceActions',
      productManagerEnv.requisition,
      requisitions
    );

  const createNewRequisitionLoading = useLoadingState();
  const { getProductsArrayFromRequisition } = useProcurementStore(state => state.requisitionSliceActions);
  const { setDetails, addProduct } = useProcurementStore(state => state.productFormManagerProperties.actions);

  const handleCreateRequisition = () => {
    createNewRequisitionLoading.startLoading();
    requisitionSliceActions.create(
      { projectId: '', projectName: '' },
      req => {
        setDetails({ ...req, products: getProductsArrayFromRequisition(req) }, productManagerEnv.requisition);
        addProduct(product);
        createNewRequisitionLoading.finishLoading();
        // NotifySuccess('Requisition Created');
        NotifySuccess(t('notifications.create_form', { formName: req.name }));
        onClose();
      },
      createNewRequisitionLoading.finishLoading
    );
  };

  React.useEffect(() => {
    if (!isFetching && requisitions?.length === 0) {
      handleCreateRequisition();
    }
  }, [isFetching, requisitions]);

  return (
    <Stack>
      <Typography color='primary' variant='overline' fontWeight='700' mt={1}>
        {t('buttons.add_to_requisition')}
      </Typography>
      <Typography variant='body2' color='text.disabled'>
        {t('texts.select_requisition')}
      </Typography>
      <Box my={2} height={40}>
        <SearchTextField
          translateKey='search_draft_requisitions'
          value={searchText}
          onChange={e => setsearchText(e.target.value)}
        />
      </Box>
      {isFetching ? (
        <Stack height={'300px'}>
          <Loading fullHeight={false} />
        </Stack>
      ) : (
        <>
          <Stack mb={2} height='220px'>
            <Stack pl={1}>
              <RadioButtonGroup
                data={requisitions?.filter((contentItem: any) =>
                  contentItem.name.toLowerCase().includes(searchText.toLowerCase()) ? true : false
                )}
                handleChange={handleChangeRequisition}
                value={highlitedRequisitionId}
                getSubTitle={(requisition: Requisition) => {
                  let numberOfItems = requisition?.products?.length ?? 0;

                  const productsCount = requisition?.number_of_products;
                  const servicesCount = requisition?.number_of_services;
                  if (typeof productsCount === 'number' && typeof servicesCount === 'number') {
                    numberOfItems = productsCount + servicesCount;
                  }

                  return (
                    numberOfItems + ' products/services, ' + requisition.project.name ?? `0  ${t('texts.products')}`
                  );
                }}
              />
            </Stack>
          </Stack>
          <Button
            variant='contained'
            onClick={addToRequisition}
            disabled={createNewRequisitionLoading.isLoading || !highlitedRequisitionId}
          >
            {t('buttons.select_draft_requisition')}
          </Button>
          {t('texts.or')}
          <LoadingButton
            loading={createNewRequisitionLoading.isLoading}
            variant='outlined'
            onClick={handleCreateRequisition}
          >
            {t('buttons.create_new_requisition')}
          </LoadingButton>
        </>
      )}
    </Stack>
  );
};
