import React from 'react';
import { useEventListener } from '../use-event-listener';

type IProps = {
  ref: React.RefObject<HTMLDivElement>;
  offset?: number;
  onBottomReached?: () => void;
};

const useOnReachBottomScroll = ({ ref, offset = 1, onBottomReached }: IProps) => {
  const handleScroll = () => {
    const container = ref.current;
    if (!container) return;
    const { scrollTop, clientHeight, scrollHeight } = container;
    const isReachedBottom = scrollTop + clientHeight + offset >= scrollHeight;
    if (isReachedBottom) {
      onBottomReached?.();
    }
  };
  useEventListener('scroll', handleScroll, ref);
};

export default useOnReachBottomScroll;
