import { FormControlLabel, FormControlLabelProps, Radio, RadioGroup, RadioProps, styled, Typography, useRadioGroup } from "@mui/material";
import React from "react";

interface RadioButtonGroupProps {
    data: { id: string, name: string }[],
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    value: any
    getSubTitle: (el: any) => string | number
    isItemDisabled?: (el: any) => boolean
    disableTypography?: string
    getLabelSuffix?: (el: any) => React.ReactNode
}

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : theme.palette.secondary.contrastText,
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});

interface StyledFormControlLabelProps extends FormControlLabelProps {
    checked: boolean;
    subTitle?: string
    labelSuffix?: React.ReactNode
}

function MyFormControlLabel(props: StyledFormControlLabelProps) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <>
        <FormControlLabel {...props}
            label={<Typography sx={{ fontSize: 14 }} lineHeight={1.1} fontWeight={checked ? '700' : '400'} color={props.disabled ? 'info.light' : checked ? "primary.main" : "text.disabled"}>
                {props.label} {props.labelSuffix}
            </Typography>}
        />
        {props.subTitle && !props.disabled && <Typography mt={-1} ml={3} variant="caption" color="text.third" textAlign='start'>{props.subTitle + " "}</Typography>}
        {props.disabled && <Typography mt={-1} ml={3} variant="caption" color={props.disabled ? 'info.light' : "text.third"} textAlign='start'>{props.disableTypography + " "}</Typography>}
    </>;
}

const BpRadio = (props: RadioProps) => {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = (props: RadioButtonGroupProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.handleChange((event.target as HTMLInputElement).value);
    };
    return (
        <RadioGroup onChange={handleChange} value={props.value}>
            {props.data
                .sort((a, b) => {
                    // Compare disabled status
                    const isADisabled = props.isItemDisabled ? props.isItemDisabled(a) : false;
                    const isBDisabled = props.isItemDisabled ? props.isItemDisabled(b) : false;

                    // Sort disabled items to the end
                    if (isADisabled && !isBDisabled) {
                        return 1;
                    } else if (!isADisabled && isBDisabled) {
                        return -1;
                    }

                    // Keep the original order for non-disabled items
                    return 0;
                })
                .map(el => <MyFormControlLabel
                    key={el.id}
                    value={el.id.toString()}
                    label={el.name} control={<BpRadio />}
                    subTitle={props.getSubTitle(el)}
                    labelSuffix={props?.getLabelSuffix?.(el)}
                    disabled={props.isItemDisabled ? props.isItemDisabled(el) : false}
                    disableTypography={props.disableTypography ?? ''}
                />)}
        </RadioGroup>
    )
}

// products?.length