import { SvgIcon, SvgIconProps } from '@mui/material';

export const CatalogueSideSVG: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        {...props}
        width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M1.01318 19.475C0.728599 19.475 0.490057 19.3792 0.29755 19.1875C0.105043 18.9958 0.00878906 18.7583 0.00878906 18.475V12.475C0.00878906 12.1917 0.105043 11.9542 0.29755 11.7625C0.490057 11.5708 0.728599 11.475 1.01318 11.475H7.03949C7.32407 11.475 7.56261 11.5708 7.75512 11.7625C7.94762 11.9542 8.04388 12.1917 8.04388 12.475V18.475C8.04388 18.7583 7.94762 18.9958 7.75512 19.1875C7.56261 19.3792 7.32407 19.475 7.03949 19.475H1.01318ZM12.7896 8.975H5.30693C4.92191 8.975 4.63315 8.80417 4.44064 8.4625C4.24814 8.12083 4.25232 7.78333 4.4532 7.45L8.19454 1.375C8.39541 1.04167 8.67999 0.875 9.04826 0.875C9.41654 0.875 9.70111 1.04167 9.90199 1.375L13.6433 7.45C13.8442 7.78333 13.8484 8.12083 13.6559 8.4625C13.4634 8.80417 13.1746 8.975 12.7896 8.975ZM18.9666 21.225L17.008 19.275C16.6565 19.5083 16.2757 19.6833 15.8655 19.8C15.4554 19.9167 15.0244 19.975 14.5724 19.975C13.3169 19.975 12.2497 19.5375 11.3709 18.6625C10.4921 17.7875 10.0526 16.725 10.0526 15.475C10.0526 14.225 10.4921 13.1625 11.3709 12.2875C12.2497 11.4125 13.3169 10.975 14.5724 10.975C15.8279 10.975 16.895 11.4125 17.7739 12.2875C18.6527 13.1625 19.0921 14.225 19.0921 15.475C19.0921 15.9083 19.0335 16.3292 18.9164 16.7375C18.7992 17.1458 18.6318 17.525 18.4142 17.875L20.3727 19.825C20.5569 20.0083 20.6489 20.2417 20.6489 20.525C20.6489 20.8083 20.5569 21.0417 20.3727 21.225C20.1886 21.4083 19.9542 21.5 19.6696 21.5C19.3851 21.5 19.1507 21.4083 18.9666 21.225ZM14.5724 17.975C15.2755 17.975 15.8697 17.7333 16.3552 17.25C16.8406 16.7667 17.0834 16.175 17.0834 15.475C17.0834 14.775 16.8406 14.1833 16.3552 13.7C15.8697 13.2167 15.2755 12.975 14.5724 12.975C13.8693 12.975 13.2751 13.2167 12.7896 13.7C12.3041 14.1833 12.0614 14.775 12.0614 15.475C12.0614 16.175 12.3041 16.7667 12.7896 17.25C13.2751 17.7333 13.8693 17.975 14.5724 17.975Z" fill="#13628C" />

    </SvgIcon>
);
