import {
  BundleRFQHeaderDetails,
  NewCommentsPopup,
  RFQAllDetails,
  StyledTabs
} from '@/components';
import { ProcurementButtons } from '@/config';
import { RFQDetails, TabModel } from '@/models';
import { ResponsiveContainer } from '@/styles';
import { useProcurementStore, useUserData } from '@/zustand';
import { Grid } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';
import useLinkStateToQueryParam from 'src/logic/hooks/use-link-state-to-query-param';
import usePopup from 'src/logic/hooks/use-popup';
import QueryStatusComponent from 'src/logic/services/query-handlers/QueryStatusComponent';
import { sm } from 'src/site-map';
import GeneralTerms from '../general-terms/GeneralTerms';
import useSetHeaderData from 'src/logic/hooks/use-header';

export const BundleRFQDetailsPage = ({}) => {
  const { t } = useTranslation();

  const bundleRFQDetails = useProcurementStore(state => state.bundleRFQDetailsSliceProperties.bundleRFQDetails);
  const fetchBundleRfqDetailsQueryStatus = useProcurementStore(
    state => state.bundleRFQDetailsSliceProperties.fetchBundleRfqDetailsQueryStatus
  );
  const tabs = useProcurementStore(state => state.bundleRFQDetailsSliceProperties.tabs);
  const selectedRFQ = useProcurementStore(state => state.bundleRFQDetailsSliceProperties.selectedRFQ);
  const setSelectedRFQ = useProcurementStore(state => state.bundleRFQDetailsSliceActions.setSelectedRFQ);
  const closeNewCommentsPopup = useProcurementStore(state => state.bundleRFQDetailsSliceActions.closeNewCommentsPopup);
  const newComments = useProcurementStore(state => state.bundleRFQDetailsSliceProperties.newComments);
  const customLoadingButton = useProcurementStore(state => state.bundleRFQDetailsSliceProperties.customLoadingButton);
  const DeclineAllRequestsExtension = useProcurementStore(
    state => state.bundleRFQDetailsSliceActions.declineAllRequestsExtension
  );
  const declineRequestExtension = useProcurementStore(
    state => state.bundleRFQDetailsSliceActions.declineRequestExtension
  );

  const handleCloseNewCommentsPopup = () => closeNewCommentsPopup();

  useSetHeaderData(bundleRFQDetails?.bundleRFQModel?.name, null)


  let isLastRFQ: boolean = false;
  isLastRFQ = bundleRFQDetails?.requestForQuotes?.length == 1;
  const userInfo = useUserData().userInfo;
  const isOwner = userInfo?.id == bundleRFQDetails?.bundleRFQModel?.owner?.id;

  let multipleRequestExtension = bundleRFQDetails?.requestForQuotes?.filter(
    (item: RFQDetails) => item.rfqModel?.form_action_permissions?.can_accept_request_extension === true
  );
  let isThereMultipleRequestExtension = multipleRequestExtension?.length > 1;

  let canUpdateValidityDate = bundleRFQDetails?.requestForQuotes?.some(
    (item: RFQDetails) =>
      item.rfqModel?.form_action_permissions?.can_decline_request_extension === true &&
      item.rfqModel?.form_action_permissions?.can_accept_request_extension === true
  );

  useLinkStateToQueryParam([{ name: 'rfqId', value: selectedRFQ?.rfqModel?.id }], [selectedRFQ?.rfqModel?.id]);

  const handleDeclineRequestExtension = () => {
    let rfqId = bundleRFQDetails?.requestForQuotes?.find(
      (item: RFQDetails) =>
        item.rfqModel?.form_action_permissions?.can_decline_request_extension === true &&
        item.rfqModel?.form_action_permissions?.can_accept_request_extension === true
    );

    declineRequestExtension(rfqId?.rfqModel.id);
  };

  const editValidityPopper = usePopup();

  const handleClickEditExpiryDate = () => {
    let FirstExpiredRFQ =
      multipleRequestExtension?.length != 0 ? multipleRequestExtension?.[0].rfqModel?.id : selectedRFQ?.rfqModel?.id;
    setSelectedRFQ(FirstExpiredRFQ);
    closeNewCommentsPopup();
    editValidityPopper.handleOpen();
  };

  const handleDeclineAllRequestsExtension = () => {
    let FirstExpiredRFQ =
      multipleRequestExtension?.length != 0 ? multipleRequestExtension?.[0].rfqModel?.id : selectedRFQ?.rfqModel?.id;
    setSelectedRFQ(FirstExpiredRFQ);
    DeclineAllRequestsExtension(bundleRFQDetails?.bundleRFQModel?.id);
  };

  return (
    <QueryStatusComponent
      queryHandler={fetchBundleRfqDetailsQueryStatus}
      notFoundPageProps={{ linkText: 'Go to RFQs', linkUrl: sm.portals.buyer.procurement.requestForQuotes.index.url }}
    >
      <Grid>
        {bundleRFQDetails?.bundleRFQModel && (
          <Grid container component={ResponsiveContainer} sx={{ bgcolor: 'background.primary.main' }} gap={2} pb={40}>
            <BundleRFQHeaderDetails {...bundleRFQDetails} />

            {/* <Grid container md={12} justifyContent="flex-end" alignItems="center" mt={2}> */}
            <Grid container md={12} mb={1}>
              <GeneralTerms
                mode='view-only'
                warranty_term={bundleRFQDetails?.bundleRFQModel?.terms_bundle?.warranty_term}
                payment_term={bundleRFQDetails?.bundleRFQModel?.terms_bundle?.payment_term}
                delivery_term={bundleRFQDetails?.bundleRFQModel?.terms_bundle?.delivery_term}
              />
            </Grid>

            <Grid item md={12}>
              {selectedRFQ && (
                <>
                  <TabContent tabs={tabs} selectedRFQ={selectedRFQ} onChange={rfqId => setSelectedRFQ(rfqId)} />
                  <RFQAllDetails
                    selectedRFQ={selectedRFQ}
                    isLastRFQ={isLastRFQ}
                    bundleId={bundleRFQDetails.bundleRFQModel?.id}
                    editValidityPopper={editValidityPopper}
                  />
                </>
              )}
            </Grid>

            <NewCommentsPopup
              title={bundleRFQDetails.bundleRFQModel?.name}
              buttonTitle={t('buttons.continue_to_rfq')}
              newComments={newComments}
              closeNewCommentsPopup={handleCloseNewCommentsPopup}
              onDeclineRequestExtension={handleDeclineRequestExtension}
              onEditValidityDate={handleClickEditExpiryDate}
              canUpdateValidityDate={isOwner && canUpdateValidityDate}
              isLoadingDeclineButton={customLoadingButton == ProcurementButtons.DECLINE_REQUEST_EXTENSION}
              isThereMultipleRequestExtension={isThereMultipleRequestExtension}
              onDeclineRequests={handleDeclineAllRequestsExtension}
            />
          </Grid>
        )}
      </Grid>
    </QueryStatusComponent>
  );
};

type TabContentProps = {
  tabs: TabModel[] | null;
  selectedRFQ: RFQDetails;
  onChange: (rfqId: number) => void;
};

export const TabContent: React.FC<TabContentProps> = ({ tabs, selectedRFQ, onChange }) => {
  let selectedRFQId: number | undefined = selectedRFQ?.rfqModel?.id;
  // const [value, setValue] = React.useState(selectedRFQId);
  // const updateTab = (event, newValue) => {
  //   setValue(newValue);
  // };
  return (
    <StyledTabs tabs={tabs} value={selectedRFQId} updateTab={(_, newValue) => onChange(newValue)} onChange={() => {}} />
  );
};
