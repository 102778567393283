import { StateCreator } from 'zustand';
import produce from 'immer';
import {
  ConvertToRFQData,
  ConvertToRFQProduct,
  ConvertToRFQProducts,
  Product,
  Seller,
  SellerData,
  SellerDataDetails,
  TabModel,
} from '@/models';
import {
  NotifySuccess,
  useGenerateRequestForQuotes,
  useGetConvertRequisitionToRFQData,
} from '@/services';
import { ProcurementButtons, RequisitionStatus } from '@/config';
import { t } from 'i18next';
import { getALLExistingSellers } from 'src/logic/services/catalogue/private-seller';
import QueryHandler, { QueryHandlerModel } from 'src/logic/services/query-handlers/QueryHandler';
import { GeneralTermsSlice } from '../shared/createGeneralTermsSlice';
import { GeneralTermsPayload, TermPayload } from 'src/logic/models/procurement/general-terms';
export interface ConvertRequisitionToRFQ {
  loadingConvertRequisitionToRFQ: boolean;
  convertToRFQData: ConvertToRFQData | null;
  convertToRFQProducts: ConvertToRFQProducts[] | null;
  isAllBundleRFQProductSellersSelected: boolean;
  payment_term: TermPayload;
  warranty_term: TermPayload;
  delivery_term: TermPayload;
  selectedMainProductId: number;
  selectedMainProduct: ConvertToRFQProduct | null;
  selectedAlternativeProducts: ConvertToRFQProduct[];
  selectedSellerTabs: TabModel[] | null;
  customLoadingButton: ProcurementButtons | null;
  existingSellersInReq: Seller[] | null;
  fetchSellersQueryStatus: QueryHandlerModel;

  getConvertToRFQDetails: (requisitionId: number) => void;
  selectSellersForProduct: (product: ConvertToRFQProduct, seller: SellerData) => void;
  updateProductSellers: (product: ConvertToRFQProduct, seller: SellerData) => ConvertToRFQProduct;
  buildInitialConvertToRFQProduct: (
    product: ConvertToRFQProduct,
    quantity: number,
    additional_requirements: string | null,
    sellers: SellerData[],
    warranty_term: TermPayload,
    requisition_product_id: number
  ) => ConvertToRFQProduct;
  buildInitialConvertToRFQAlternativeProduct: (
    product: ConvertToRFQProduct,
    mainProduct: Product,
    sellers: SellerData[],
    requisition_product_id: number
  ) => ConvertToRFQProduct;
  setSelectedProduct: (mainProductId: number) => void;
  updateConvertToRFQProductQuantity: (product: ConvertToRFQProduct, quantity: number) => void;
  updateConvertToRFQProductRequirements: (product: ConvertToRFQProduct, additional_requirements: string) => void;
  updateConvertToRFQProductWarrantyTerm: (product: ConvertToRFQProduct, warranty: TermPayload) => void;
  updateSelectedMainProduct: (updatedProduct: ConvertToRFQProduct) => void;
  handleGenerateRFQ: (
    notifyNoSelectedSellersError: Function,
    handleOpenWarningPopup: () => void,
    handleOpenBundleGenerated: Function
  ) => void;
  generateRFQ: (handleOpenBundleGenerated: Function) => void;
  updateGeneralTerms: (terms: GeneralTermsPayload) => void;
  getBundleNumberOfSelectedSellers: (
    mainProduct: ConvertToRFQProduct,
    alternativeProducts: ConvertToRFQProduct
  ) => number;
  getNumberOfProductsWithNoSellerSelected: () => number | undefined;
  setSelectedSellerTabs: (tabsArray: ConvertToRFQProducts[] | null) => void;
  clearSelectedSellers: (requisitionProductId: number) => void;
  toggleSeller: (product: ConvertToRFQProduct, seller: SellerData) => void;
  setCustomLoadingButton: (isLoading: ProcurementButtons | null) => void;
  getUnlinkedSellers: (productId: number) => void;
  setFetchSellerQueryStatus: (queryHandler: QueryHandlerModel) => void;
  linkSellersInReq: (productId: number, newSellers: any) => void;
}

export const createConvertRequisitionToRFQ: StateCreator<ConvertRequisitionToRFQ> = (set, get, api) => ({
  convertToRFQData: null,
  payment_term: { id: null, name: '' },
  warranty_term: { id: null, name: '' },
  delivery_term: { id: null, name: '' },
  convertToRFQProducts: null,
  isAllBundleRFQProductSellersSelected: false,
  selectedMainProductId: 0,
  selectedMainProduct: null,
  loadingConvertRequisitionToRFQ: false,
  selectedAlternativeProducts: [],
  selectedSellerTabs: null,
  customLoadingButton: null,
  existingSellersInReq: null,
  fetchSellersQueryStatus: QueryHandler.getInitialStatus(),

  getConvertToRFQDetails: (requisitionId: number) => {
    set(
      produce(draftState => {
        draftState.loadingConvertRequisitionToRFQ = true;
      })
    );
    useGetConvertRequisitionToRFQData(requisitionId).then(data => {
      let convertToRFQData = data.data;
      const rebuiltConvertToRFQProducts = convertToRFQData.products.map(rfqProduct => {
        let mainProduct = get().buildInitialConvertToRFQProduct(
          rfqProduct.main_product.product,
          rfqProduct.quantity,
          rfqProduct.additional_requirements,
          rfqProduct.main_product.catalog_items,
          rfqProduct.warranty_term,
          rfqProduct.main_product.requisition_product_id
        );
        let alternatives = rfqProduct.alternative_products.map(alternativesProduct => {
          return get().buildInitialConvertToRFQAlternativeProduct(
            alternativesProduct.product,
            rfqProduct.main_product.product,
            alternativesProduct.catalog_items,
            alternativesProduct.requisition_product_id
          );
        });
        return {
          main_product: mainProduct,
          alternative_products: alternatives,
          numberOfBundleProductSelectedSellers: get().getBundleNumberOfSelectedSellers(mainProduct, alternatives),
        };
      });
      get().setSelectedSellerTabs(rebuiltConvertToRFQProducts);
      const generalTermsSlice = get() as GeneralTermsSlice;
      generalTermsSlice.generalTerms.actions.initializeGeneralTerms(convertToRFQData.terms);

      set(
        produce(draftState => {
          draftState.convertToRFQData = convertToRFQData;
          draftState.payment_term = { id: null, name: '' };
          draftState.warranty_term = { id: null, name: '' };
          draftState.delivery_term = { id: null, name: '' };
          draftState.convertToRFQProducts = rebuiltConvertToRFQProducts;
          draftState.selectedMainProductId = rebuiltConvertToRFQProducts[0].main_product.id;
          draftState.selectedMainProduct = rebuiltConvertToRFQProducts[0].main_product;
          draftState.selectedAlternativeProducts = rebuiltConvertToRFQProducts[0].alternative_products;
          draftState.loadingConvertRequisitionToRFQ = false;
          draftState.isAllBundleRFQProductSellersSelected = isAllSellersValid(rebuiltConvertToRFQProducts);
          draftState.customLoadingButton = null;
        })
      );
    });
  },
  getNumberOfProductsWithNoSellerSelected: () => {
    return get().convertToRFQProducts?.filter(
      convertToRFQProduct => !getProductNumberOfSelectedSellers(convertToRFQProduct.main_product.sellers)
    ).length;
  },
  getBundleNumberOfSelectedSellers: (mainProduct: ConvertToRFQProduct, alternativeProducts: ConvertToRFQProduct) => {
    let selectedSellersDetailsMainProduct: SellerDataDetails[] = mainProduct?.selectedSellersDetails.map(
      item => item.data
    );
    let selectedSellersDetailsAlternativeProduct: SellerDataDetails[] = [];

    alternativeProducts?.map((item: ConvertToRFQProduct) =>
      item.selectedSellersDetails?.map((element: SellerData) =>
        selectedSellersDetailsAlternativeProduct.push(element.data)
      )
    );

    let allSelectedSeller = selectedSellersDetailsMainProduct?.concat(selectedSellersDetailsAlternativeProduct);
    const uniqueObjects = [...new Map(allSelectedSeller?.map(item => [item.name, item])).values()];

    let uniqueNumberOfSelectedSeller = uniqueObjects.length;
    return uniqueNumberOfSelectedSeller;
  },
  selectSellersForProduct: (product: ConvertToRFQProduct, seller: SellerData) => {
    get().toggleSeller(product, seller);
    set(
      produce(draftState => {
        draftState.convertToRFQProducts = get().convertToRFQProducts?.map(convertToRFQProduct => {
          return produce(convertToRFQProduct, draftConvertToRFQProduct => {
            draftConvertToRFQProduct.numberOfBundleProductSelectedSellers = get().getBundleNumberOfSelectedSellers(
              convertToRFQProduct.main_product,
              convertToRFQProduct.alternative_products
            );
          });
        });
        draftState.isAllBundleRFQProductSellersSelected = isAllSellersValid(draftState.convertToRFQProducts);
      })
    );
    get().setSelectedSellerTabs(get().convertToRFQProducts);
  },
  setSelectedProduct: (mainProductId: number) => {
    let selectedProduct = get().convertToRFQProducts?.find(product => product.main_product.id == mainProductId);
    let selectedProductWithSortedSeller = getSelectedProductNumberOfSelectedSellers(selectedProduct);
    let convertToRFQProducts = get().convertToRFQProducts?.map(convertToRFQProduct => {
      if (convertToRFQProduct.main_product.id == selectedProductWithSortedSeller.main_product.id)
        return selectedProductWithSortedSeller;
      else return convertToRFQProduct;
    });
    set(
      produce(draftState => {
        draftState.convertToRFQProducts = convertToRFQProducts;
        draftState.selectedMainProduct = selectedProductWithSortedSeller?.main_product;
        draftState.selectedAlternativeProducts = selectedProductWithSortedSeller?.alternative_products;
        draftState.selectedMainProductId = mainProductId;
      })
    );
  },
  updateConvertToRFQProductQuantity: (product: ConvertToRFQProduct, quantity: number) => {
    let updatedQuantityProduct = produce(product, draftProduct => {
      draftProduct.quantity = quantity;
    });
    get().updateSelectedMainProduct(updatedQuantityProduct);
  },
  updateConvertToRFQProductRequirements: (product: ConvertToRFQProduct, additional_requirements: string) => {
    let updatedProduct = produce(product, draftProduct => {
      draftProduct.additional_requirements = additional_requirements;
    });
    get().updateSelectedMainProduct(updatedProduct);
  },
  updateConvertToRFQProductWarrantyTerm: (product: ConvertToRFQProduct, warranty: TermPayload) => {
    let updatedWarrantyProduct = produce(product, draftProduct => {
      draftProduct.warranty_term = warranty;
    });
    get().updateSelectedMainProduct(updatedWarrantyProduct);
  },
  updateProductSellers: (product: ConvertToRFQProduct, seller: SellerData) => {
    return produce(product, draftProduct => {
      draftProduct.sellers = product.sellers.map(sellerData => {
        return sellerData.data.id == seller.data.id
          ? produce(sellerData, draftSellerData => {
              draftSellerData.is_selected = !sellerData.is_selected;
            })
          : sellerData;
      });
      draftProduct.numberOfSelectedSellers = getProductNumberOfSelectedSellers(draftProduct.sellers);
      draftProduct.selectedSellersDetails = draftProduct.sellers.filter(seller => seller.is_selected);
    });
  },
  updateGeneralTerms: (terms: GeneralTermsPayload) => {
    set(
      produce(draftState => {
        draftState.payment_term = terms.payment_term;
        draftState.warranty_term = terms.warranty_term;
        draftState.delivery_term = terms.delivery_term;
      })
    );
  },
  handleGenerateRFQ: (
    notifyNoSelectedSellersError: Function,
    handleOpenWarningPopup: () => void,
    handleOpenBundleGenerated: Function
  ) => {
    let convertToRFQProducts = get().convertToRFQProducts?.map(convertToRFQProduct => {
      return [...convertToRFQProduct.alternative_products, convertToRFQProduct.main_product];
    });
    let oneSellerSelected = convertToRFQProducts?.some(convertToRFQProduct =>
      convertToRFQProduct.some(product => product.sellers.some(seller => seller.is_selected))
    );

    if (!oneSellerSelected) notifyNoSelectedSellersError();
    else if (get().getNumberOfProductsWithNoSellerSelected()) handleOpenWarningPopup();
    else {
      get().generateRFQ(handleOpenBundleGenerated);
    }
  },
  generateRFQ: (handleOpenBundleGenerated: Function) => {
    let selectedProducts = get().convertToRFQProducts?.map((item: ConvertToRFQProducts) =>
      handleGenerateRFQObject(item)
    );
    get().setCustomLoadingButton(ProcurementButtons.GENERATE_RFQ);
    useGenerateRequestForQuotes(
      get().currentRequisition?.id,
      { payment_term: get().payment_term, warranty_term: get().warranty_term, delivery_term: get().delivery_term  },
      selectedProducts
    )
      .then(data => {
        handleOpenBundleGenerated(data.data.id, data.data.is_bundle);
        get().setCustomLoadingButton(null);
        if (get().requisitions) {
          const requisitions = get().requisitions.map(requisition => {
            if (requisition.id == get().currentRequisition.id)
              return { ...requisition, status: RequisitionStatus.CONVERTED_TO_RFQ };
            else return requisition;
          });
          get().setRequisitions(requisitions);
        }
        NotifySuccess(t('popups.titles.rfq_generated'));
      })
      .catch(() => get().setCustomLoadingButton(null));
  },
  buildInitialConvertToRFQProduct: (
    product: ConvertToRFQProduct,
    quantity: number,
    additional_requirements: string | null,
    sellers: SellerData[],
    warranty_term: TermPayload,
    requisition_product_id: number
  ) => {
    return produce(product, draftProduct => {
      draftProduct.inCart = true;
      draftProduct.additional_requirements = additional_requirements;
      draftProduct.quantity = quantity;
      draftProduct.parentId = null;
      draftProduct.sellers = sellers.sort(function (x, y) {
        return Number(y.is_selected) - Number(x.is_selected);
      });
      draftProduct.numberOfSelectedSellers = getProductNumberOfSelectedSellers(sellers);
      draftProduct.selectedSellersDetails = sellers.filter(seller => seller.is_selected);
      draftProduct.inCartType = 'main';
      draftProduct.warranty_term = warranty_term;
      draftProduct.requisition_product_id = requisition_product_id;
    });
  },
  buildInitialConvertToRFQAlternativeProduct: (
    product: ConvertToRFQProduct,
    mainProduct: Product,
    sellers: SellerData[],
    requisition_product_id: number
  ) => {
    return produce(product, draftProduct => {
      draftProduct.inCartType = 'alternative';
      draftProduct.inCart = true;
      draftProduct.parentId = mainProduct.id;
      draftProduct.sellers = sellers.sort(function (x, y) {
        return Number(y.is_selected) - Number(x.is_selected);
      });
      draftProduct.numberOfSelectedSellers = getProductNumberOfSelectedSellers(sellers);
      draftProduct.selectedSellersDetails = sellers.filter(seller => seller.is_selected);
      draftProduct.requisition_product_id = requisition_product_id;
    });
  },
  updateSelectedMainProduct: (updatedProduct: ConvertToRFQProduct) => {
    const draftConvertToRFQProducts = get().convertToRFQProducts?.map(convertToRFQProduct => {
      if (convertToRFQProduct.main_product.id == updatedProduct.id) {
        return produce(convertToRFQProduct, draftConvertToRFQProduct => {
          draftConvertToRFQProduct.main_product = updatedProduct;
        });
      } else return convertToRFQProduct;
    });
    const draftSelectedMainProduct = draftConvertToRFQProducts?.find(
      draftConvertToRFQProduct => draftConvertToRFQProduct.main_product.id == get().selectedMainProductId
    );
    set(draftState => {
      draftState.convertToRFQProducts = draftConvertToRFQProducts;
      draftState.selectedMainProduct = draftSelectedMainProduct?.main_product;
    });
  },
  setSelectedSellerTabs: (tabsArray: ConvertToRFQProducts[] | null) => {
    let updatedTabs: TabModel[] = [];
    tabsArray?.map(element => {
      updatedTabs.push({
        id: element.main_product.id,
        name: element.main_product.name,
        count: element.main_product.sellers?.length,
        detail: element.numberOfBundleProductSelectedSellers,
        isPrivate: element.main_product.is_private_product
      });
    });
    set(
      produce(draftState => {
        draftState.selectedSellerTabs = updatedTabs;
      })
    );
  },
  clearSelectedSellers: (requisitionProductId: number) => {
    let selectedProduct = get().convertToRFQProducts?.find(
      element => element.main_product.requisition_product_id == requisitionProductId
    );
    let updatedAlternativeProducts = selectedProduct?.alternative_products?.map(alt =>
      clearSelectedSellersForSelectedProduct(alt)
    );
    let product = produce(selectedProduct, draftSelectedProduct => {
      draftSelectedProduct.main_product = clearSelectedSellersForSelectedProduct(selectedProduct?.main_product);
      draftSelectedProduct.alternative_products = updatedAlternativeProducts;
      draftSelectedProduct.numberOfBundleProductSelectedSellers = 0;
    });
    let updatedConvertToRFQProducts = get().convertToRFQProducts?.map(convertToRFQProduct => {
      if (convertToRFQProduct.main_product.id == product?.main_product.id) return product;
      else return convertToRFQProduct;
    });
    set(
      produce(draftState => {
        draftState.selectedMainProduct = product?.main_product;
        draftState.selectedAlternativeProducts = product?.alternative_products;
        draftState.convertToRFQProducts = updatedConvertToRFQProducts;
        draftState.isAllBundleRFQProductSellersSelected = false;
      })
    );
    get().setSelectedSellerTabs(updatedConvertToRFQProducts);
  },
  toggleSeller: (product: ConvertToRFQProduct, seller: SellerData) => {
    const draftConvertToRFQProducts = get().convertToRFQProducts?.map(convertToRFQProduct => {
      if (product.inCartType == 'main' && convertToRFQProduct.main_product.id == product.id) {
        const updatedProduct = get().updateProductSellers(convertToRFQProduct.main_product, seller);
        return produce(convertToRFQProduct, draftConvertToRFQProduct => {
          draftConvertToRFQProduct.main_product = updatedProduct;
        });
      } else if (product.inCartType == 'alternative') {
        return produce(convertToRFQProduct, draftConvertToRFQProduct => {
          draftConvertToRFQProduct.alternative_products = convertToRFQProduct.alternative_products.map(
            alternativesProduct => {
              if (alternativesProduct.id == product.id && alternativesProduct.parentId == product.parentId)
                return get().updateProductSellers(alternativesProduct, seller);
              else return alternativesProduct;
            }
          );
        });
      } else return convertToRFQProduct;
    });
    const draftSelectedMainProduct = draftConvertToRFQProducts?.find(
      draftConvertToRFQProduct => draftConvertToRFQProduct.main_product.id == get().selectedMainProductId
    );

    set(
      produce(draftState => {
        draftState.convertToRFQProducts = draftConvertToRFQProducts;
        draftState.selectedMainProduct = draftSelectedMainProduct?.main_product;
        draftState.selectedAlternativeProducts = draftSelectedMainProduct?.alternative_products;
      })
    );
  },
  setCustomLoadingButton: (customButton: ProcurementButtons | null) => {
    set(
      produce(draftState => {
        draftState.customLoadingButton = customButton;
        if (!customButton) {
          draftState.customLoadingButton = null;
        }
      })
    );
  },
  getUnlinkedSellers: (productId: number) => {
    get().setFetchSellerQueryStatus(QueryHandler.getStartStatus());
    getALLExistingSellers(productId)
      .then(data => {
        set(
          produce(draftState => {
            draftState.existingSellersInReq = data.data;
          })
        );
        get().setFetchSellerQueryStatus(QueryHandler.getSuccessStatus());
      })
      .catch(err => {
        get().setFetchSellerQueryStatus(QueryHandler.getErrorStatus(err));
      });
  },
  setFetchSellerQueryStatus: (queryHandler: QueryHandlerModel) => {
    set(
      produce(draftState => {
        draftState.fetchSellersQueryStatus = queryHandler;
      })
    );
  },
  linkSellersInReq: (productId: number, newSellers: any) => {
    const products = get().convertToRFQProducts;
    if (!Array.isArray(products)) {
      console.error('Products is not an array or is undefined.');
      return;
    }

    const updatedProducts = products.map(rfqProduct => {
      let newProduct = { ...rfqProduct };
      if (rfqProduct.main_product.id === productId) {
        newProduct = {
          ...newProduct,
          main_product: {
            ...newProduct.main_product,
            sellers: [...newProduct.main_product.sellers, ...newSellers],
          },
        };
      }
      newProduct = {
        ...newProduct,
        alternative_products: newProduct.alternative_products.map(alternativeProduct => {
          if (alternativeProduct.id === productId) {
            return {
              ...alternativeProduct,
              sellers: [...alternativeProduct.sellers, ...newSellers],
            };
          }
          return alternativeProduct;
        }),
      };
      return newProduct;
    });

    const selectedMainProductId = get().selectedMainProductId;
    const selectedProductAsRfqItem = updatedProducts.find(product => product.main_product.id == selectedMainProductId);
    const selectedMainProduct = selectedProductAsRfqItem?.main_product;
    const selectedAlternativeProducts = selectedProductAsRfqItem?.alternative_products ?? [];

    set(
      produce(draftState => {
        draftState.convertToRFQData.products = updatedProducts;
        draftState.convertToRFQProducts = updatedProducts;
        draftState.selectedMainProduct = selectedMainProduct;
        draftState.selectedAlternativeProducts = selectedAlternativeProducts;
      })
    );
    get().setSelectedSellerTabs(updatedProducts);
  },
});

const isAllSellersValid = (bundleRFQProducts: ConvertToRFQProduct[]) => {
  return !bundleRFQProducts.filter(bundleRFQProduct => !bundleRFQProduct.numberOfSelectedSellers);
};

function getSelectedProductNumberOfSelectedSellers(product: ConvertToRFQProducts) {
  const mainProduct = product.main_product;
  const alternativeProducts = product.alternative_products?.map(altProd => {
    let sortedSeller = sortSellers(altProd.sellers);
    return { ...altProd, sellers: sortedSeller };
  });
  let sortedMainProduct = { ...mainProduct, sellers: sortSellers(mainProduct.sellers) };
  let sortedAlternativeProducts = alternativeProducts;
  return {
    ...product,
    main_product: sortedMainProduct,
    alternative_products: sortedAlternativeProducts,
  };
}
function handleGenerateRFQObject(item: ConvertToRFQProducts) {
  return {
    id: item.main_product.id,
    quantity: item.main_product.quantity,
    additional_requirements: item.main_product.additional_requirements,
    warranty_term_id: item.main_product.warranty_term?.id ?? null,
    warranty_term_custom_value: item.main_product.warranty_term?.value ?? null,
    catalog_item_ids: item.main_product.sellers
      ?.filter((seller: SellerData) => seller.is_selected)
      .map((seller: SellerData) => seller.data.id),
    alternatives: item.alternative_products
      ?.filter(alt => alt.sellers?.some((seller: SellerData) => seller.is_selected))
      .map(alt => ({
        id: alt.id,
        catalog_item_ids: alt.sellers
          ?.filter((seller: SellerData) => seller.is_selected)
          .map((seller: SellerData) => seller.data.id),
      })),
  };
}

const sortSellers = sellers => {
  return sellers?.slice().sort(function (x, y) {
    return Number(y.is_selected) - Number(x.is_selected);
  });
};

export function getProductNumberOfSelectedSellers(sellers: SellerData[] | undefined) {
  const selectedSellers = sellers?.filter(seller => seller.is_selected);
  const uniqueSellers = Array.from(new Set(selectedSellers?.map(item => item.data.name))).map(name => {
    return sellers?.find(item => item.data.name === name);
  });
  let uniqueNumberOfSelectedSeller = uniqueSellers.length;
  return uniqueNumberOfSelectedSeller;
}

function clearSelectedSellersForSelectedProduct(product: ConvertToRFQProduct) {
  let unSelectedSellers = product.sellers?.map(seller => {
    return { ...seller, is_selected: false };
  });
  return produce(product, draftProduct => {
    draftProduct.sellers = unSelectedSellers;
    draftProduct.selectedSellersDetails = [];
    draftProduct.numberOfSelectedSellers = 0;
  });
}
