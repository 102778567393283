import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Typography } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { InitialAddProps } from 'src/logic/models/catalogue/PrivateItem';
import { sm } from 'src/site-map';

type IProps = InitialAddProps;

const AddNewPrivateItemLink = (props: IProps) => {
  const router = useRouter();

  const link = React.useMemo(() => {
    const queryString = new URLSearchParams(props).toString();
    if (queryString) {
      return `${sm.catalogue.products.add.url}?${queryString}`;
    }
    return sm.catalogue.products.add.url;
  }, [props]);

  const isAddProductPage = router.pathname === '/catalogue/products/add';

  return (
    <>
      {!isAddProductPage && (
        <Typography lineHeight={1} variant='subtitle2' fontWeight={500} color={'text.third'}>
          Can’t find your product / service?{' '}
          <Button
            sx={{ fontWeight: 700 }}
            startIcon={<InfoOutlinedIcon sx={{ fontSize: 'sm', color: '#0074FF', mr: -0.5 }} />}
          >
            <Link href={link}>
              <Typography lineHeight={1} fontWeight={700} fontSize={'12px'} color='#0074FF'>
                Add New
              </Typography>
            </Link>
          </Button>
        </Typography>
      )}
    </>
  );
};

export default AddNewPrivateItemLink;
