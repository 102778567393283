import { CheckboxWithLabel, CustomWarrantyText, DescPopup, RemarkButton, StyledCheckbox, TableWithPagination, TextFieldCounterForm } from '@/components';
import { ProductCategoryKind, ProductCategoryKindLabel, ProductTableRow } from "@/models";
import { useProcurementStore } from '@/zustand';
import { Info } from '@mui/icons-material';
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Box, CardMedia, Grid, ThemeOptions, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { ColumnDef } from '@tanstack/react-table';
import { getIn, useFormikContext } from 'formik';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { formatNumber } from 'src/logic/utils/numbers';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';
import ProductQuickView from 'src/ui/components/smart/product/product-quick-view';
import { DescriptionIndicator } from '../../../requisition/requisition-alternatives-table/components';
import BuyerRequirementsIndicator from 'src/ui/components/smart/procurement/products-table/BuyerRequirementsIndicator';
import { isTermsEqual } from 'src/ui/components/smart/general-terms/utils';

const useStyles = makeStyles((theme: ThemeOptions) => ({
    cellHead: {
        color: '#8B8D98', fontSize: 16, fontWeight: 400, padding: 0, paddingLeft: 18, textTransform: 'uppercase', width: '400px'
    },
    cellHeadPaddingLeft: {
        paddingLeft: 40
    },
    cellHeadpaddingRight: {
        paddingRight: 30
    },
    columnMarginTop: {
        marginTop: 5, fontSize: 18
    },
    columnNoPadding: {
        fontWeight: 400, padding: 0,
    },
    columnPaddingLeft: {
        paddingLeft: 30
    },
    columnPaddingRight: {
        paddingRight: 10
    },
    head: {
        backgroundColor: theme?.palette?.secondary.contrastText,
        '& th:first-child': { borderRadius: '6px 0 0 6px', marginTop: 10 },
        '& th:last-child': { borderRadius: '0 6px 6px 0' },
    },
    rowBody: { borderRadius: '6px 6px 6px 6px' },
    rowBodyWhite: { backgroundColor: theme?.palette?.secondary.contrastText },
    rowBodyRedBorder: {
        backgroundColor: theme?.palette?.secondary.contrastText, borderBottom: '1px solid #ff0000', "& .MuiTableCell-root": {
            borderBottom: "1px solid red",
            borderTop: "1px solid red",
        },
        '& .MuiTableCell-root:first-child': {
            borderLeft: '1px solid red',
        },
        '& .MuiTableCell-root:last-child': {
            borderRight: '1px solid red',
        },
    },
    w_0: {
        width: 'fit-content'
    },
    shrink: { width: '1%' },
    pl: { paddingLeft: '85px' }
}));


type BuyerQuoteProductsTableProps = {
    key: string
    rowsData: ProductTableRow

}
const EMPTY_ARR = [] as any[];

export const BuyerQuoteProductsTable: React.FC<BuyerQuoteProductsTableProps> = ({ key, rowsData }) => {
    const classes = useStyles()
    const { values, errors } = useFormikContext();
    const formikSlice = getIn(values, name) || EMPTY_ARR;
    const [tableRows, setTableRows] = useState(formikSlice);
    const { t } = useTranslation()
    useEffect(() => { setTableRows(formikSlice) }, [formikSlice]);
    const { toggleSelectAllProducts, changeRequestedQuantity } = useProcurementStore(state => state.quoteBuyerDetailsSliceActions)
    const { quote } = useProcurementStore(state => state.quoteBuyerDetailsSliceProperties)
    const isAllProductsSelected = React.useMemo(() => tableRows?.every(prod => prod.productDetails.selected) ?? false, [tableRows])
    
    const columns = React.useMemo<ColumnDef<ProductTableRow>[]>(
        () => [
            {
                accessorKey: 'productDetails',
                id: 'toggle',
                header: 'empty',
                cell: ({ row: { index }, getValue }) => <ProductRfqToggle index={index} content={getValue()} />,
                meta: {
                    filterType: 'search',
                    columnIcon: null,
                    headerCellClass: `${classes.cellHead} ${classes.w_0}`,
                    headerRender: <StyledCheckbox value={isAllProductsSelected} onChange={(value) => toggleSelectAllProducts(value)} />,
                    tableRow: {
                        cellClass: classes.columnMarginTop
                    }
                },
                enableColumnFilter: false,
                enableSorting: false 
            },
            {
                accessorKey: 'productDetails',
                id: 'productDetails.category_kind',
                header: 'segment',
                meta: {
                    filterType: 'search',
                    columnIcon: null,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: `${classes.rowBody} ${classes.shrink}`
                    }
                },
                cell: ({ getValue }) => {
                    const product = getValue();
                    return <Typography width='100%' color="#3A3E50" fontSize={11} fontWeight={400} textTransform={'uppercase'} textAlign={'center'} px={1}>{ProductCategoryKindLabel[product?.category_kind]}</Typography>    
                },
                enableColumnFilter: false,
                enableSorting: false 
            },
            {
                accessorKey: 'productDetails',
                id: 'productDetails',
                header: 'product_details',
                cell: ({ row: { index }, getValue }) => <ProductRFQDetails index={index} content={getValue()} />,
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: `${classes.cellHead} ${classes.pl}`,
                    tableRow: {
                        cellClass: classes.columnMarginTop
                    }
                },
                enableColumnFilter: false,
                enableSorting: false 
            },
            // {
            //     accessorKey: 'brandImage',
            //     id: 'brandImage',
            //     header: 'brand',
            //     cell: info => (
            //         <Box width='88px'> <CardMedia component="img" sx={{ objectFit: 'contain', height: '25px' }} image={info.getValue()} alt={'image'} /></Box>
            //     ),
            //     meta: {
            //         filterType: 'search',
            //         columnIcon: UnfoldMoreIcon,
            //         headerCellClass: classes.cellHeadPaddingLeft,
            //     },
            //     enableColumnFilter: false
            // },
            {
                accessorKey: 'warranty_term',
                id: 'warranty_term',
                header: 'warranty',
                cell: ({ row: { index, original } }) => {
                    return <CustomWarrantyText isWarranyTermCustom={!isTermsEqual(quote?.quoteModel?.terms_bundle?.warranty_term, original?.warranty_term)} warrantyName={original.warranty_term?.name} />
                },
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHeadPaddingLeft,
                    tableRow: {
                        cellClass: classes.rowBody
                    }
                },
                enableColumnFilter: false,
                enableSorting: false 
            },
            {
                accessorKey: 'availableQuantity',
                id: 'availableQuantity',
                header: 'available',
                cell: info => (<Quantity quantity={info.getValue()} />),
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHeadpaddingRight,
                    tableRow: {
                        cellClass: classes.columnPaddingLeft
                    }
                },
                enableColumnFilter: false,
                enableSorting: false 
            },
            {
                accessorKey: 'requestedQuantity',
                id: 'requestedQuantity',
                header: 'ordered_qty',
                cell: ({ row: { index, original } }) => {
                    const { setFieldValue, values, errors } = useFormikContext();
                    return (
                        <TextFieldCounterForm onChange={(value) => value && changeRequestedQuantity(original, value)} name={`${name}[${index}].requestedQuantity`} disabled={!values[index].productDetails.selected} />
                    )
                },
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.columnPaddingLeft,
                    tableRow: {
                        cellClass: classes.cellHeadpaddingRight
                    }
                },
                enableColumnFilter: false,
                enableSorting: false 
            },
            {
                accessorKey: 'unitPrice',
                id: 'unitPrice',
                header: 'unit_price',
                cell: info => {
                    let value = info.getValue()
                    let price = parseInt(value ?? 0)
                    return (<Typography variant='subtitle2' > {t('texts.aed')} {formatNumber(price ?? 0)} </Typography>)
                },
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    tableRow: {
                        cellClass: classes.columnPaddingRight
                    }
                },
                enableColumnFilter: false,
                enableSorting: false 
            },
            {
                accessorKey: 'remark',
                id: 'remark',
                header: 'remarks',
                cell: info => (<RemarkButton remark={info.getValue()} contentDetails={info.row.original} />),
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    tableRow: {
                        cellClass: classes.columnPaddingRight
                    }
                },
                enableColumnFilter: false,
                enableSorting: false 
            }
        ]
        ,
        [isAllProductsSelected, values]
    )
    const columnsWithErrors = React.useMemo<ColumnDef<ProductTableRow>[]>(
        () => [
            {
                accessorKey: 'productDetails',
                header: 'empty',
                cell: ({ row: { index, original } }) => {
                    const { setFieldValue, values, errors } = useFormikContext();
                    return (
                        <>
                            {errors[index] && <Info color='error' />}
                        </>
                    )
                },
                meta: {
                    filterType: 'search',
                    columnIcon: null,
                    tableRow: {
                        cellClass: classes.columnPaddingRight
                    }
                },
                enableColumnFilter: false,
                enableSorting: false 
            }
        ]
        ,
        []
    )
    return (
        <>
            <TableWithPagination
                disablePagination
                columns={errors.length != 0 ? [...columns, ...columnsWithErrors] : columns}
                data={tableRows}
                key={tableRows}
                tableMeta={{
                    getRowStyle: (index) => {
                        return !values[index].productDetails.selected ? classes.rowBody : errors[index] ? classes.rowBodyRedBorder : classes.rowBodyWhite
                    },
                    headStyle: classes.head
                }}
                style={{ overflow: 'visible' }}
            />
        </>
        // <StyledTable key={'id'} rows={tableRows} columns={columns} whiteHeader />
    )
}

const ProductRfqToggle = ({ content, index }) => {
  const { values, errors } = useFormikContext();
  const { toggleSelectProductFromQuote } = useProcurementStore(state => state.quoteBuyerDetailsSliceActions);

  const onChange = () => {
    toggleSelectProductFromQuote(values[index]);
    // if (!values[index].productDetails.selected) setFieldValue(`${name}[${index}].availableQuantity`, values[index].requestedQuantity)
    // else setFieldValue(`${name}[${index}].availableQuantity`, 0)
  };

  return (
    <CheckboxWithLabel
      name={`${name}[${index}].productDetails.selected`}
      onChange={onChange}
      error={errors[index] ? true : false}
    />
  );
};

const ProductRFQDetails = ({ content, index }) => {
  const { t } = useTranslation();
  const { setFieldValue, values, errors } = useFormikContext();

  const { quote } = useProcurementStore(state => state.quoteBuyerDetailsSliceProperties);
  const product_id = values[index].id;
  const quote_product = React.useMemo(
    () => quote?.quoteModel?.quote_products.find(prod => prod.id === product_id),
    [quote, product_id]
  );
  const product = quote_product?.product;
  const additional_requirements = quote_product?.additional_requirements;

  const [productDetailsPopup, setProductDetailsPopup] = useState(false);
  const openProductDetailsPopup = () => setProductDetailsPopup(true);
  const closeProductDetailsPopup = () => setProductDetailsPopup(false);

  return (
    <Grid container alignItems={'center'}>
      <Grid item md={2}>
        <Box>
          <CardMedia
            component='img'
            sx={{ objectFit: 'contain', height: '50px', width: '50px' }}
            image={product?.image_url}
            alt={''}
          />
        </Box>
      </Grid>
      <Grid item md={10}>
        <div style={{ display: 'flex' }}>
          <Typography
            variant='body2'
            fontWeight={700}
            color={'text.disabled'}
            className={'underline-on-hover cursor-pointer'}
            onClick={openProductDetailsPopup}
            noWrap
          >
            {content.name}
          </Typography>
          {product?.is_private_product && <PrivacyButton disableText />}
        </div>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <DescriptionIndicator description={product.description} category_kind={product.category_kind} />
          {product.category_kind === ProductCategoryKind.PRODUCT ? (
            <Typography fontSize={13} fontWeight={400} color='#8B8D98'>
              {product.part_number}
            </Typography>
          ) : (
            <BuyerRequirementsIndicator additional_requirements={additional_requirements} />
          )}
        </div>
      </Grid>
      <DescPopup big handleClose={closeProductDetailsPopup} open={productDetailsPopup} paperSx={{ borderRadius: 0 }}>
        <ProductQuickView product={product} />
      </DescPopup>
    </Grid>
  );
};

const Quantity = ({ quantity }) => {
    const { t } = useTranslation();
    return (
        <Grid container alignItems="center">
            <Typography variant="subtitle2" color='text.third'>{t('texts.qty')}:</Typography>
            {quantity}
        </Grid>
    )
}