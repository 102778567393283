import { RadioButtonGroup, SearchTextField } from '@/components';
import { Add } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { t } from 'i18next';
import React, { FC, useEffect, useState } from 'react';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import usePopup from 'src/logic/hooks/use-popup';
import { Loading, StyledPopper } from 'src/ui/components/shared';
import PrivacyButton from '../buttons/privacy-button';

type SelectWithSearchAndCreateProps = {
  onSaveAction?: (itemId: string, onClose: () => void) => void;
  onCreateNew?: () => void;
  isTextualEdit?: boolean;
  sx: any;
  selectionLabel?: string;
  searchLabel?: string | undefined;
  selectionList?: any
  isButtonLoading?: boolean;
  defaultSelectedCompanyId?: number | undefined;
  dataTestId?: string;
};

const containerStyles: React.CSSProperties = {
  width: '300px',
  boxShadow: '0px 8px 32px 0px #EBEBEB',
};

export const SelectWithSearchAndCreate: FC<SelectWithSearchAndCreateProps> = ({
  onSaveAction,
  onCreateNew,
  isTextualEdit = false,
  sx = {},
  selectionLabel,
  searchLabel,
  selectionList,
  isButtonLoading,
  defaultSelectedCompanyId,
  dataTestId
}) => {
  const SelectWithSearchAndCreatePopper = usePopup();
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const [searchText, setsearchText] = useState('');
  const [itemId, setItemId] = useState(defaultSelectedCompanyId || '');
  const projectsIsFetching = useLoadingState();
  useEffect(() => {
    if (SelectWithSearchAndCreatePopper.isOpen) {
      setItemId(defaultSelectedCompanyId || '');
    }
  }, [SelectWithSearchAndCreatePopper.isOpen, defaultSelectedCompanyId]);

  return (
    <Grid item md={1} sx={{ ml: -7, ...sx }}>
      <div ref={anchorRef} style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <IconButton
          disableRipple
          sx={{ ml: 1, color: '#13628C', fontWeight: 500, display: 'flex', alignItems: 'center' }}
          onClick={SelectWithSearchAndCreatePopper.handleOpen}
          data-testid={dataTestId}
        >
          <Typography variant='subtitle1' lineHeight={1.2} fontWeight={500}
            sx={{ flexShrink: 0 }}>{isTextualEdit ? t('buttons.edit') : `Select ${selectionLabel}`}</Typography>
          <KeyboardArrowDownIcon sx={{ fontWeight: 500, fontSize: '18px' }} />
        </IconButton>
      </div>

      <StyledPopper
        open={SelectWithSearchAndCreatePopper.isOpen}
        anchorEl={anchorRef.current}
        handleClose={SelectWithSearchAndCreatePopper.handleClose}
        zIndex={2}
      >
        <div style={containerStyles}>
          {projectsIsFetching.isLoading ? (
            <div
              style={{
                minHeight: '10rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Loading fullHeight={false} />
            </div>
          ) : (
            <Stack
              width='100%'
              px={2}
              py={3.5}
            >
              <Stack width='100%'>

                <Box width={'100%'}>
                  <SearchTextField
                    translateKey={searchLabel}
                    value={searchText}
                    onChange={e => setsearchText(e.target.value)}
                  />
                </Box>

                <Button
                  sx={{ mt: 1, fontSize: 13, color: '#13628C', display: "flex", justifyContent: "flex-start" }}
                  startIcon={<Add sx={{ color: '#13628C' }} />}
                  type='button'
                  onClick={() => {
                    SelectWithSearchAndCreatePopper.handleClose();
                    onCreateNew()
                  }}
                >
                  Create New
                </Button>

                <Stack maxHeight='200px'>
                  <Stack pl={1}>
                    <RadioButtonGroup
                      data={
                        selectionList?.filter((item: any) =>
                          item.name
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                        ) ?? []
                      }
                      getLabelSuffix={(item: any) => {
                        return item.is_private ? <PrivacyButton disableText zIndex={1300} iconHeight='1.1rem' /> : null;
                      }}
                      handleChange={(id: string) => setItemId(id)}
                      getSubTitle={() => ''}
                      value={itemId}
                    />
                  </Stack>
                </Stack>

                <LoadingButton
                  sx={{ mt: 2 }}
                  fullWidth
                  variant='contained'
                  loading={isButtonLoading}
                  disabled={!itemId}
                  onClick={() => {
                    if (onSaveAction) {
                      onSaveAction(itemId, SelectWithSearchAndCreatePopper.handleClose);
                    }
                  }}
                >
                  {t('buttons.save')}
                </LoadingButton>

              </Stack>
            </Stack>
          )}
        </div>
      </StyledPopper>
    </Grid>
  );
};


