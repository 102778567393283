import { request } from '@/helpers';
import { SortConfig } from 'src/logic/models/@shared/sort';
import { EnvironmentFilters } from 'src/logic/models/catalogue/AdvancedFilters';
import { CatalogueBrand } from 'src/logic/models/catalogue/catalogue-content';
import { PaginatedResponse } from 'src/logic/models/queries';

export const getBrandsIndex = () => {
  return request({ url: `/product/brands`, method: 'GET' });
};

export const getBrandDetails = (id: number, envFilters: EnvironmentFilters) => {
  return request({ url: `/product/brands/${id}`, method: 'GET', params: envFilters });
};

export const getSortedBrands = (id: string) => {
  return request({ url: `/product/brands?sort_by=${id}`, method: 'GET' });
};

// ================================ New Catalogue ================================

export type CatalogueBrandsTabResponse = {
  featured_brands?: CatalogueBrand[];
  data: PaginatedResponse<CatalogueBrand>;
  sort_data: SortConfig;
};

export const getBrandsItems = ({
  page,
  search,
  sortBy,
  environmentFilters = {},
}: {
  page: number;
  search?: string;
  sortBy?: string;
  environmentFilters?: EnvironmentFilters;
}): Promise<{ data: CatalogueBrandsTabResponse }> => {
  return request({
    url: '/product/catalog/brands_tab',
    method: 'GET',
    params: { query: search, page, sort_by: sortBy, ...environmentFilters },
  });
};

export const getBrandsTabContent = ({
  search,
  environmentFilters = {},
}: {
  search?: string;
  environmentFilters?: EnvironmentFilters;
}): Promise<{
  data: CatalogueBrandsTabResponse;
}> => {
  return request({
    url: '/product/catalog/brands_tab',
    method: 'GET',
    params: { query: search, ...environmentFilters },
  });
};
