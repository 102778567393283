import { apiRoutes, procurementStatus } from "@/config"
import { request } from "@/helpers"
import { Quote, QuoteDetails, QuoteProductTableRow } from "@/models";
import { buildFormProductsTable } from "..";
import { useUserData } from "@/zustand";
import { TermPayload } from "src/logic/models/procurement/general-terms";

export const useGetBuyerQuoteDetails = (quoteId: number) => {
    return request({ url: apiRoutes.apiQuoteBuyer + '/' + quoteId + '/show', method: "GET" })
}

export enum QuoteFormPermission {
    CANCEL = "CANCEL",
    // DELETE = "DELETE",
    GENERATE = "GENERATE",
    // VIEW_COMPARISON_TABLE = "VIEW_COMPARISON_TABLE",
    GENERATING_PURSHASE_ORDER = "GENERATING_PURSHASE_ORDER",
    GENERATE_NEW_PURCHASE_ORDER = "GENERATE_NEW_PURCHASE_ORDER",
    VIEW_PURCHASE_ORDER = "VIEW_PURCHASE_ORDER",
    DECLINE = "DECLINE",
    CHANGE_RESPONSE = "CHANGE_RESPONSE",
    REQUEST_EXTENSION = "REQUEST_EXTENSION"
}
// export const QuoteFormPermission =Object.assign({}, ProcurementFormPermission, FormPermission)
// type QuoteFormPermissionType = ProcurementFormPermission | FormPermission

export function getQuoteFormActions(quote: Quote, editing: boolean, generatePermission?: boolean, isOwner: boolean): Record<QuoteFormPermission, boolean> {

    const formActions: Record<QuoteFormPermission, boolean> = {
        [QuoteFormPermission.CANCEL]: generatePermission == true && quote.status === procurementStatus.DECLINED && editing,
        // [QuoteFormPermission.DELETE]: quote.status === procurementStatus.DRAFT,
        // [QuoteFormPermission.GENERATE]: quote?.purchase_orders?.length == 0 && generatePermission == true && quote?.form_action_permissions?.can_receiver_accept || (quote.status === procurementStatus.DECLINED && editing),
        // [QuoteFormPermission.VIEW_COMPARISON_TABLE]: quote.status === procurementStatus.QUOTE_RECEIVED && !editing ,
        [QuoteFormPermission.GENERATING_PURSHASE_ORDER]: (quote.status === procurementStatus.DECLINED && editing) || quote?.form_action_permissions?.can_receiver_change_response == false && generatePermission == true && quote?.form_action_permissions?.can_receiver_accept == true && quote?.purchase_orders?.length == 0 ,
        [QuoteFormPermission.GENERATE_NEW_PURCHASE_ORDER]: generatePermission == true && quote?.form_action_permissions?.can_receiver_accept == true && quote?.purchase_orders?.length != 0,
        [QuoteFormPermission.VIEW_PURCHASE_ORDER]: quote.status === procurementStatus.PO_GENERATED && !editing,
        [QuoteFormPermission.DECLINE]: quote?.form_action_permissions?.can_receiver_change_response == false && generatePermission == true && quote?.form_action_permissions?.can_receiver_decline == true,
        [QuoteFormPermission.CHANGE_RESPONSE]: quote?.form_action_permissions?.can_receiver_change_response == true && !editing && generatePermission == true,
        [QuoteFormPermission.REQUEST_EXTENSION]: quote?.form_action_permissions?.can_request_extension == true &&  quote?.form_action_permissions?.can_receiver_change_response == false ,
        //   [ProcurementFormPermission.APPROVE]:  (status === procurementStatus.PENDING_MY_APPROVAL || status === procurementStatus.REJECTED_BY_ME),
        //   [ProcurementFormPermission.REJECT]:  (status === procurementStatus.PENDING_MY_APPROVAL || status === procurementStatus.APPROVED_BY_ME),
        //   [ProcurementFormPermission.EDIT_RESPONSE]:  (status === procurementStatus.REJECTED_BY_ME || status === procurementStatus.APPROVED_BY_ME),
    };
    return formActions;
}

export function buildBuyerQuoteDetails(quote: Quote, userId: number, editing: boolean) {
    let isOwner = userId == quote?.owner?.id
    const userData = useUserData.getState()
    let generatePermission = userData?.canCreatePO

    return {
        quoteModel: quote,
        numberOfProducts: quote.quote_products.length,
        quoteActions: getQuoteFormActions(quote, editing, generatePermission, isOwner)
    };
}

export const buildQuoteProductsTable = (quote: QuoteDetails | null, generalWarrantyTerm?: TermPayload) => {
    return buildFormProductsTable(quote?.quoteModel?.quote_products, generalWarrantyTerm)
}

export const useGeneratePurchaseOrder = (quoteId: string, products: QuoteProductTableRow[]) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.create, method: "POST", data: { quote_id: quoteId, products: products.map(({ remark, requestedQuantity, id }) => ({ remarks: remark, quantity: requestedQuantity, quote_product_id: id })) } })
}

export const updatePurchaseOrderFromQuote = (quoteId: string, products: QuoteProductTableRow[], poId: string) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + "/update", method: "POST", data: { purchase_order_id: poId, quote_id: quoteId, products: products.map(({ remark, requestedQuantity, id }) => ({ remarks: remark, quantity: requestedQuantity, quote_product_id: id })) } })
}

export const useFetchDeclineQuoteReasons = () => {
    return request({ url: apiRoutes.apiQuoteBuyer + apiRoutes.declineReasons, method: "GET" })
}
export const useDeclineQuote = (quoteId: number, reasonId: string, comment?: string) => {
    return request({ url: apiRoutes.apiQuoteBuyer + '/' + quoteId + apiRoutes.decline, method: "POST", data: { reason_id: reasonId, comment: comment ?? null } })
}