import { Dialog, Slide } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions"
import React, { FC } from "react"


interface SideDialogProps {
    sideDialogIsOpened: boolean
    closeSideDialog: () => void
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
})

export const SideDialog: FC<SideDialogProps> = (props) => {

    return (
        <Dialog
            onClose={props.closeSideDialog}
            TransitionComponent={Transition}
            open={props.sideDialogIsOpened}
            hideBackdrop
            keepMounted
            disableEnforceFocus
            disableAutoFocus
            disableRestoreFocus
            aria-describedby="side"
            PaperProps={{ sx: { height: '100%', position: 'absolute', top: '37px', right: '-30px', width: '100%', borderRadius: 0 }, elevation: 1 }}>
            {props.children}
        </Dialog>
    )
}