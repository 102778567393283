import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Typography } from '@mui/material';
import React from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import {
  GeneralTermItem,
  SingleSelectTermItem,
  SliderTermItem,
  TextFieldTermItem,
} from 'src/logic/models/procurement/general-terms';
import { StyledPopper } from 'src/ui/components/shared';
import SingleSelectTermComponent from './components/SingleSelectTermComponent';
import SliderTermComponent from './components/SliderTermComponent';
import TextFieldTermComponent from './components/TextFieldTermComponent';

type SelectTermsProps = {
  label: string | React.ReactNode;
  style?: React.CSSProperties;
  shouldShowErrorIndicator?: boolean;
  canSave?: boolean;
  popupLabel?: string;

  selectedValueLabel: string;
  terms: GeneralTermItem[];
  onOptionChange: (item: SingleSelectTermItem, newOption: number | null) => void;
  onSliderValueChange: (item: SliderTermItem, newValue: number) => void;
  onTextFieldValueChange: (item: TextFieldTermItem, newValue: string) => void;
  onSave: () => boolean;
  onPopupOpen?: () => void;
  onPopupClose?: () => void;

  disabled?: boolean;
};

const SelectTerms = ({
  label,
  style = {},
  shouldShowErrorIndicator,
  canSave = true,
  selectedValueLabel,
  popupLabel,
  terms,
  onOptionChange,
  onSliderValueChange,
  onTextFieldValueChange,
  onSave,
  onPopupOpen,
  onPopupClose,
  disabled = false,
}: SelectTermsProps) => {
  const selectPopup = usePopup();
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleOpenPopup = () => {
    onPopupOpen?.();
    selectPopup.handleOpen();
  };

  const onClose = () => {
    onPopupClose?.();
    selectPopup.handleClose();
  };

  const handleSave = () => {
    const isSaved = onSave();
    if (isSaved) onClose();
  };

  return (
    <div style={style}>
      <Typography color='#13628C' fontWeight={400} fontSize={12} ref={anchorRef}>
        {shouldShowErrorIndicator && (
          <ErrorOutlineIcon
            color='error'
            sx={{ fontSize: '18px', transform: 'translateY(25%)', marginInlineEnd: '.4rem' }}
          />
        )}
        <span style={{ color: '#8B8D98' }}>{label}: </span>
        <span
          className={disabled ? '' : 'cursor-pointer underline-on-hover'}
          style={{ opacity: disabled ? 0.75 : 1 }}
          onClick={disabled ? undefined : handleOpenPopup}
        >
          {selectedValueLabel || 'Select Terms'}{' '}
          <KeyboardArrowDownIcon
            sx={{
              fontWeight: 500,
              fontSize: '18px',
              transform: 'translateY(30%)',
            }}
          />
        </span>
      </Typography>
      <StyledPopper open={selectPopup.isOpen} anchorEl={anchorRef.current} handleClose={onClose}>
        <div
          style={{
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0px 8px 32px 0px #EBEBEB',
            padding: '1rem 2rem',
            maxWidth: 'min(95vw, 450px)',
          }}
        >
          <Typography textAlign={'center'} color='#3A3E50' fontSize={13} fontWeight={700}>
            {popupLabel ?? label}
          </Typography>
          <div>
            {terms.map(item => {
              if (item.type === 'SINGLE_SELECT')
                return <SingleSelectTermComponent key={item.identifier} item={item} onChange={onOptionChange} />;
              if (item.type === 'SLIDER')
                return <SliderTermComponent key={item.id} item={item} onChange={onSliderValueChange} />;
              if (item.type === 'TEXT_FIELD')
                return <TextFieldTermComponent key={item.id} item={item} onChange={onTextFieldValueChange} />;
              return null;
            })}

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBlock: '1.5rem' }}>
              <Button
                type='button'
                color='primary'
                variant='contained'
                onClick={handleSave}
                disabled={!canSave}
                sx={{ px: 8 }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </StyledPopper>
    </div>
  );
};

export default SelectTerms;
