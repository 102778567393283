import { request } from '@/helpers';
import { AdvancedFilterItem, EnvironmentFilters, FilterQueryOptions } from 'src/logic/models/catalogue/AdvancedFilters';

export const onFilterChange = (
  changedFilter: AdvancedFilterItem,
  advancedFiltersData: AdvancedFilterItem[] | null
): Promise<{ data: AdvancedFilterItem[] }> => {
  return request({
    url: `/product/advanced-filters/catalog/onChange`,
    method: 'POST',
    data: {
      filter_type: changedFilter.type,
      filter_ids: changedFilter.filter_items?.filter(item => item.selected).map(item => item.id) ?? [],

      previously_selected_filters:
        advancedFiltersData
          ?.filter(item => item.type !== changedFilter.type)
          .map(item => ({
            type: item.type,
            ids: item.filter_items?.filter(opt => opt.selected).map(opt => opt.id) ?? [],
          }))
          .filter(item => item.ids.length !== 0) ?? [],
    },
  });
};

type ApplyFilterOptions = {
  advancedFiltersData: AdvancedFilterItem[] | null;
  page_number?: number;
  filterQueryOptions?: FilterQueryOptions;
  environmentFilters?: EnvironmentFilters;
  only_my_products?: boolean;
};

export const applyFilters = ({
  advancedFiltersData,
  page_number = 1,
  filterQueryOptions = {},
  environmentFilters = {},
  only_my_products,
}: ApplyFilterOptions): Promise<{ data: any }> => {
  return request({
    url: `/product/filter/apply`,
    method: 'POST',
    data: {
      page_number: page_number || 1,
      filters:
        advancedFiltersData
          ?.map(item => ({
            type: item.type,
            ids: item.filter_items?.filter(opt => opt.selected).map(opt => opt.id) ?? [],
          }))
          .filter(item => item.ids.length !== 0) ?? [],
      ...filterQueryOptions,
      ...environmentFilters,
      only_my_products: only_my_products,
    },
  });
};

export const getTypeInitialAdvancedFilters = (
  envFilters: EnvironmentFilters,
  category_kind?: number,
  group_id?: number,
  category_id?: number,
  class_id?: number,
  type_id?: number,
): Promise<{ data: { filters: AdvancedFilterItem[] } }> => {

  const params = {
    ...(category_kind !== undefined && category_kind !== '' && { category_kind }),
    ...(group_id !== undefined && group_id !== '' && { group_id }),
    ...(category_id !== undefined && category_id !== '' && { category_id }),
    ...(class_id !== undefined && class_id !== '' && { class_id }),
    ...(type_id !== undefined && type_id !== '' && { type_id }),
    ...envFilters,
  };

  return request({
    url: `/product/filter`,
    method: 'GET',
    params,
  });
};


export const getInitialAdvancedFilters = (
  envFilters: EnvironmentFilters,
  category_kind?: number,
  group_id?: number,
  category_id?: number,
  class_id?: number,
  type_id?: number,
): Promise<{ data: { filters: AdvancedFilterItem[] } }> => {

  const params = {
    ...(category_kind !== undefined && category_kind !== '' && { category_kind }),
    ...(group_id !== undefined && group_id !== '' && { group_id }),
    ...(category_id !== undefined && category_id !== '' && { category_id }),
    ...(class_id !== undefined && class_id !== '' && { class_id }),
    ...(type_id !== undefined && type_id !== '' && { type_id }),
    ...envFilters,
  };

  return request({
    url: `/product/filter`,
    method: 'GET',
    params,
  });
};
