import produce from 'immer';
import {
  GeneralTermItem,
  GeneralTermsPayload,
  RawGeneralTermItem,
  RawGeneralTerms,
  RawSelectedGeneralTerms,
  RawSelectedNode,
  SingleSelectTermItem,
  SliderTermItem,
  TermPayload,
  TextFieldTermItem,
} from 'src/logic/models/procurement/general-terms';
import { StateCreator } from 'zustand';

export type TermType = 'payment_terms' | 'warranty_terms' | 'delivery_terms';

const LABEL_SEPARATOR = ', ';

// Assumptions:
//  1- The SLIDER & TEXT_FIELD nodes are considered as Leaf nodes
//  2- The Selected Term Item (API Payload) consists of one object (Leaf Node)

export interface GeneralTermsSlice {
  generalTerms: {
    properties: {
      payment_terms: GeneralTermItem[];
      warranty_terms: GeneralTermItem[];
      delivery_terms: GeneralTermItem[];

      draft: {
        payment_terms: GeneralTermItem[];
        warranty_terms: GeneralTermItem[];
        delivery_terms: GeneralTermItem[];
      };

      isLoading: boolean;
    };
    actions: {
      initializeGeneralTerms: (generalTerms: RawGeneralTerms, selectedTerms?: RawSelectedGeneralTerms) => void;
      cloneTermsToDraftTerms: (termType: TermType) => void;
      getSelectedGeneralTerms: () => GeneralTermsPayload;

      changeSelectedOption: (termType: TermType, targetItem: SingleSelectTermItem, optionValue: number | null) => void;
      changeSliderValue: (termType: TermType, targetItem: SliderTermItem, newValue: number) => void;
      changeTextFieldValue: (termType: TermType, targetItem: TextFieldTermItem, newValue: string) => void;

      isTermsValid: (termType: TermType) => boolean;
      isGeneralTermsValid: () => boolean;

      getTermsLabel: (termType: TermType) => string;
      saveTerms: (termType: TermType) => boolean;

      isTermsFetched: () => boolean;
      resetSelections: (options: { draft?: boolean; items?: boolean }) => void;

      setIsLoading: (isLoading: boolean) => void;
    };
    helpers: {
      getItemsToRender: (items: GeneralTermItem[], parent_id?: number | null) => GeneralTermItem[];
      isTermsValid: (items: GeneralTermItem[], parent_id?: number | null) => boolean;
      transformRawTerms: (rawItems: RawGeneralTermItem[]) => GeneralTermItem[];
      transformToTermPayload: (item: GeneralTermItem) => TermPayload;

      changeSelectedOption: (
        items: GeneralTermItem[],
        targetItem: SingleSelectTermItem,
        optionValue: number | null
      ) => GeneralTermItem[];
      changeSliderValue: (items: GeneralTermItem[], targetItem: SliderTermItem, newValue: number) => GeneralTermItem[];
      changeTextFieldValue: (
        items: GeneralTermItem[],
        targetItem: TextFieldTermItem,
        newValue: string
      ) => GeneralTermItem[];

      getTermsLabel: (items: GeneralTermItem[], parent_id?: number | null) => string;
      getSelectedNode: (items: GeneralTermItem[]) => GeneralTermItem | undefined; // Based on the Assumption
      getLeafNodes: (
        items: GeneralTermItem[],
        parent_id?: number | null,
        leafNodes?: GeneralTermItem[]
      ) => GeneralTermItem[];

      getItemsBasedOnSelection: (
        items: GeneralTermItem[],
        selectedItem: RawSelectedNode,
        visitedNodes?: Set<number>
      ) => GeneralTermItem[];

      getResetSelections: (items: GeneralTermItem[]) => GeneralTermItem[];
    };
  };
}

export const createGeneralTermsSlice: StateCreator<GeneralTermsSlice> = (set, get) => ({
  generalTerms: {
    properties: {
      payment_terms: [],
      warranty_terms: [],
      delivery_terms: [],

      draft: {
        payment_terms: [],
        warranty_terms: [],
        delivery_terms: [],
      },

      isLoading: false,
    },
    actions: {
      initializeGeneralTerms(generalTerms, selectedTerms) {
        const transformRawTerms = get().generalTerms.helpers.transformRawTerms;
        let payment_terms = transformRawTerms(generalTerms.payment_terms);
        let warranty_terms = transformRawTerms(generalTerms.warranty_terms);
        let delivery_terms = transformRawTerms(generalTerms.delivery_terms);

        if (selectedTerms) {
          // Build the tree according to the selection
          const getItemsBasedOnSelection = get().generalTerms.helpers.getItemsBasedOnSelection;

          payment_terms = getItemsBasedOnSelection([...payment_terms], selectedTerms.payment_term);
          warranty_terms = getItemsBasedOnSelection([...warranty_terms], selectedTerms.warranty_term);
          delivery_terms = getItemsBasedOnSelection([...delivery_terms], selectedTerms.delivery_term);
        }

        set(
          produce<GeneralTermsSlice>(draftState => {
            draftState.generalTerms.properties.payment_terms = payment_terms;
            draftState.generalTerms.properties.warranty_terms = warranty_terms;
            draftState.generalTerms.properties.delivery_terms = delivery_terms;
          })
        );

        get().generalTerms.actions.cloneTermsToDraftTerms('payment_terms');
        get().generalTerms.actions.cloneTermsToDraftTerms('warranty_terms');
        get().generalTerms.actions.cloneTermsToDraftTerms('delivery_terms');
      },
      resetSelections(options: { draft?: boolean; items?: boolean }) {
        if (!options.draft && !options.items) return;

        const getResetSelections = get().generalTerms.helpers.getResetSelections;

        const payment_terms = getResetSelections(get().generalTerms.properties.payment_terms);
        const warranty_terms = getResetSelections(get().generalTerms.properties.warranty_terms);
        const delivery_terms = getResetSelections(get().generalTerms.properties.delivery_terms);

        set(
          produce<GeneralTermsSlice>(draftState => {
            if (options.items) {
              draftState.generalTerms.properties.payment_terms = payment_terms;
              draftState.generalTerms.properties.warranty_terms = warranty_terms;
              draftState.generalTerms.properties.delivery_terms = delivery_terms;
            }

            if (options.draft) {
              draftState.generalTerms.properties.draft.payment_terms = payment_terms;
              draftState.generalTerms.properties.draft.warranty_terms = warranty_terms;
              draftState.generalTerms.properties.draft.delivery_terms = delivery_terms;
            }
          })
        );
      },
      setIsLoading(isLoading) {
        set(
          produce<GeneralTermsSlice>(draftState => {
            draftState.generalTerms.properties.isLoading = isLoading;
          })
        );
      },
      isTermsFetched() {
        const payment_terms = get().generalTerms.properties.payment_terms;
        const warranty_terms = get().generalTerms.properties.warranty_terms;
        const delivery_terms = get().generalTerms.properties.delivery_terms;
        return payment_terms.length > 0 && warranty_terms.length > 0 && delivery_terms.length > 0;
      },
      cloneTermsToDraftTerms(termType) {
        const terms = get().generalTerms.properties[termType];
        set(
          produce<GeneralTermsSlice>(draftState => {
            draftState.generalTerms.properties.draft[termType] = terms;
          })
        );
      },
      getSelectedGeneralTerms() {
        const payment_terms = get().generalTerms.properties.payment_terms;
        const warranty_terms = get().generalTerms.properties.warranty_terms;
        const delivery_terms = get().generalTerms.properties.delivery_terms;

        const { getSelectedNode, transformToTermPayload } = get().generalTerms.helpers;
        const selectedPaymentTermItem = getSelectedNode(payment_terms);
        const selectedWarrantyTermItem = getSelectedNode(warranty_terms);
        const selectedDeliveryTermItem = getSelectedNode(delivery_terms);

        return {
          payment_term: selectedPaymentTermItem ? transformToTermPayload(selectedPaymentTermItem) : {},
          warranty_term: selectedWarrantyTermItem ? transformToTermPayload(selectedWarrantyTermItem) : {},
          delivery_term: selectedDeliveryTermItem ? transformToTermPayload(selectedDeliveryTermItem) : {},
        };
      },
      changeSelectedOption(termType, targetItem, optionValue) {
        const terms = get().generalTerms.properties.draft[termType];
        const updatedTerms = get().generalTerms.helpers.changeSelectedOption(terms, targetItem, optionValue);
        set(
          produce<GeneralTermsSlice>(draftState => {
            draftState.generalTerms.properties.draft[termType] = updatedTerms;
          })
        );
      },
      changeSliderValue(termType, targetItem, newValue) {
        const terms = get().generalTerms.properties.draft[termType];
        const updatedItems = get().generalTerms.helpers.changeSliderValue(terms, targetItem, newValue);
        set(
          produce<GeneralTermsSlice>(draftState => {
            draftState.generalTerms.properties.draft[termType] = updatedItems;
          })
        );
      },
      changeTextFieldValue(termType, targetItem, newValue) {
        const terms = get().generalTerms.properties.draft[termType];
        const updatedItems = get().generalTerms.helpers.changeTextFieldValue(terms, targetItem, newValue);
        set(
          produce<GeneralTermsSlice>(draftState => {
            draftState.generalTerms.properties.draft[termType] = updatedItems;
          })
        );
      },

      isTermsValid(termType) {
        const terms = get().generalTerms.properties[termType];
        return get().generalTerms.helpers.isTermsValid(terms);
      },
      isGeneralTermsValid() {
        const isTermsValid = get().generalTerms.actions.isTermsValid;
        return isTermsValid('payment_terms') && isTermsValid('warranty_terms') && isTermsValid('delivery_terms');
      },

      getTermsLabel(termType) {
        const terms = get().generalTerms.properties[termType];
        return get().generalTerms.helpers.getTermsLabel(terms);
      },

      saveTerms: (termType: TermType) => {
        const draftTerms = get().generalTerms.properties.draft[termType];
        if (!get().generalTerms.helpers.isTermsValid(draftTerms)) {
          return false;
        }
        set(
          produce<GeneralTermsSlice>(draftState => {
            draftState.generalTerms.properties[termType] = draftTerms;
          })
        );
        return true;
      },
    },
    helpers: {
      getItemsToRender: (items: GeneralTermItem[], parent_id: number | null = null) => {
        const itemsToReturn = items.filter(item => item.parent_id === parent_id);
        itemsToReturn.forEach(item => {
          if (item.type === 'SINGLE_SELECT' && item.selected_option !== null) {
            itemsToReturn.push(...get().generalTerms.helpers.getItemsToRender(items, item.selected_option));
          }
        });
        return itemsToReturn;
      },
      getResetSelections(items) {
        return items.map(item => {
          if (item.type === 'SINGLE_SELECT') return { ...item, selected_option: null };
          if (item.type === 'SLIDER') return { ...item, value: item.meta.min };
          if (item.type === 'TEXT_FIELD') return { ...item, value: '' };
          return item;
        });
      },
      isTermsValid(items, parent_id = null) {
        if (items.length === 0) return false;

        const currentItems = items.filter(item => item.parent_id === parent_id);
        for (let i = 0; i < currentItems.length; i++) {
          const item = currentItems[i];
          if (item.type === 'SLIDER') {
            if (typeof item.value !== 'number') return false;
          }
          if (item.type === 'TEXT_FIELD') {
            if (!item.value) return false;
          }
          if (item.type === 'SINGLE_SELECT') {
            if (item.selected_option === null) return false;

            const childrenIsValid = get().generalTerms.helpers.isTermsValid(items, item.selected_option);
            if (!childrenIsValid) return false;
          }
        }
        return true;
      },
      changeSelectedOption(items, targetItem, optionValue) {
        return items.map(item => {
          if (
            item.type === 'SINGLE_SELECT' &&
            item.parent_id === targetItem.parent_id &&
            item.options.some(opt => opt.id === optionValue)
          ) {
            return { ...item, selected_option: optionValue };
          }
          return item;
        });
      },
      changeSliderValue(items, targetItem, newValue) {
        return items.map(item =>
          item.type === 'SLIDER' && item.id === targetItem.id ? { ...item, value: newValue } : item
        );
      },
      changeTextFieldValue(items, targetItem, newValue) {
        return items.map(item =>
          item.type === 'TEXT_FIELD' && item.id === targetItem.id ? { ...item, value: newValue } : item
        );
      },
      getTermsLabel(items, parent_id = null) {
        const currentItems = items.filter(item => item.parent_id === parent_id);
        const labels: string[] = [];

        for (let i = 0; i < currentItems.length; i++) {
          const item = currentItems[i];

          if (item.type === 'SINGLE_SELECT' && item.selected_option) {
            const option = item.options.find(opt => opt.id === item.selected_option)!;
            const nestedLabel = get().generalTerms.helpers.getTermsLabel(items, item.selected_option);

            if (!nestedLabel.includes(option.name)) {
              labels.push(option.name);
            }
            if (nestedLabel) {
              labels.push(nestedLabel);
            }
          }
          if (item.type === 'SLIDER') {
            labels.push(`${item.value} ${item.name}`);
          }
          if (item.type === 'TEXT_FIELD') {
            labels.push(item.value);
          }
        }
        return labels.join(LABEL_SEPARATOR);
      },
      transformToTermPayload(item) {
        if (item.type === 'SINGLE_SELECT')
          return {
            id: item.selected_option,
            name: item.options.find(opt => item.selected_option && opt.id === item.selected_option)?.name,
          };
        if (item.type === 'SLIDER')
          return {
            id: item.id,
            name: `${item.value} ${item.name}`,
            value: item.value,
          };
        if (item.type === 'TEXT_FIELD')
          return {
            id: item.id,
            name: `${item.name} (${item.value})`,
            value: item.value,
          };
        return {};
      },
      getSelectedNode(items) {
        const leafNodes = get().generalTerms.helpers.getLeafNodes(items);
        if (leafNodes.length === 0) return undefined;
        const item = leafNodes[leafNodes.length - 1];
        return item;
      },
      getLeafNodes(items, parent_id = null, leafNodes = []) {
        const currentItems = items.filter(item => item.parent_id === parent_id);

        for (let i = 0; i < currentItems.length; i++) {
          const item = currentItems[i];

          if (item.type === 'SINGLE_SELECT') {
            // If it does not have children
            if (!items.some(it => it.parent_id === item.selected_option)) {
              leafNodes.push(item);
            }

            if (item.selected_option) {
              get().generalTerms.helpers.getLeafNodes(items, item.selected_option, leafNodes);
            }
          }
          if (item.type === 'SLIDER') {
            // Based on the assumption, it's a leaf node
            leafNodes.push(item);
          }
          if (item.type === 'TEXT_FIELD') {
            // Based on the assumption, it's a leaf node
            leafNodes.push(item);
          }
        }

        return leafNodes;
      },
      getItemsBasedOnSelection(items, selectedItem, visitedNodes = new Set()) {
        if (visitedNodes.has(selectedItem.id)) return items;
        visitedNodes.add(selectedItem.id);

        const updatedItems = items.map(item => {
          let newItem = { ...item };

          // Update node values
          if (newItem.type === 'SLIDER' && newItem.id === selectedItem.id) {
            newItem.value = selectedItem.value! as number;
          }
          if (newItem.type === 'TEXT_FIELD' && newItem.id === selectedItem.id) {
            newItem.value = selectedItem.value as string;
          }
          if (newItem.type === 'SINGLE_SELECT' && newItem.options?.some(opt => opt.id === selectedItem.id)) {
            newItem.selected_option = selectedItem.id;
          }

          return newItem;
        });

        if (selectedItem.parent_id !== null) {
          const parentItem = updatedItems.find(item => {
            if (item.type === 'SLIDER' && item.id === selectedItem.parent_id) return true;
            if (item.type === 'TEXT_FIELD' && item.id === selectedItem.parent_id) return true;
            if (item.type === 'SINGLE_SELECT' && item.options?.some(opt => opt.id === selectedItem.parent_id))
              return true;
            return false;
          });

          if (parentItem) {
            const parentItemAsSelectedItem = {
              id: parentItem.type === 'SINGLE_SELECT' ? selectedItem.parent_id : parentItem.id,
              parent_id: parentItem.parent_id,
              value: parentItem.type === 'SINGLE_SELECT' ? undefined : parentItem.value,
            };

            return get().generalTerms.helpers.getItemsBasedOnSelection(
              updatedItems,
              parentItemAsSelectedItem,
              visitedNodes
            );
          }
        }

        return updatedItems;
      },
      transformRawTerms(rawItems) {
        const groupedTerms: Record<number, GeneralTermItem> = {};

        rawItems.forEach(item => {
          const { group_id, identifier, type, parent_id, name, id } = item;

          if (!groupedTerms[group_id]) {
            if (type === 'SINGLE_SELECT') {
              // Initialize as SingleSelectTermItem
              groupedTerms[group_id] = {
                identifier,
                type,
                parent_id,
                options: [],
                selected_option: null, // Default selected option to null
              };
            } else if (type === 'SLIDER') {
              // Initialize as SliderTermItem
              groupedTerms[group_id] = {
                id: item.id,
                identifier,
                name: item.name,
                type,
                parent_id,
                meta: item.meta!,
                value: item.meta?.min ?? 0, // Default slider value to min
              };
            } else if (type === 'TEXT_FIELD') {
              // Initialize as TextFieldTermItem
              groupedTerms[group_id] = {
                id: item.id,
                identifier,
                name: item.name,
                type,
                parent_id,
                value: '', // Default slider value to empty string
              };
            }
          }

          if (type === 'SINGLE_SELECT') {
            // Add the option for SINGLE_SELECT
            (groupedTerms[group_id] as SingleSelectTermItem).options.push({ id, name });
          }

          if (type === 'SLIDER') {
            // Handle SLIDER type items and assign the meta and value
            (groupedTerms[group_id] as SliderTermItem).value = item.meta?.min ?? 0; // Default slider value to min
          }

          if (type === 'TEXT_FIELD') {
            (groupedTerms[group_id] as TextFieldTermItem).value = '';
          }
        });

        return Object.values(groupedTerms);
      },
    },
  },
});
