import { Close, InfoOutlined } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';

type WarningPopupProps = {
    open: boolean;
    title: string;
    handleClose: () => void;
    confirmButtonTitle: string;
    handleConfirm: Function;
    cancelButtonTitle: string;
    subTitle?: string;
    handleCancel?: () => void;
    confirmButtonColor?: string;
};

export const WarningPopup: React.VFC<WarningPopupProps> = ({
    open,
    title,
    subTitle,
    handleClose,
    confirmButtonTitle,
    handleConfirm,
    cancelButtonTitle,
    handleCancel,
    confirmButtonColor,
}) => {
    const { t } = useTranslation();
    return (
        <Dialog
            onClose={handleClose}
            open={open}
            sx={{ width: '507px', margin: 'auto' }}
            disableScrollLock
        >
            <Grid item md={12} container justifyContent={"flex-end"} p={1}>
                <IconButton edge='start' color='inherit' onClick={handleClose}>
                    <Close sx={{ color: 'text.secondary', height: 18, width: 18, }} />
                </IconButton>
            </Grid>
            <Grid container sx={{ mt: -6, p: 2 }}>
                <Grid item md={12} textAlign='center'>
                    <InfoOutlined sx={{ color: 'primary.main' }} />
                </Grid>
                <Grid item md={12} textAlign='center' pb={1}>
                    <Typography variant='overline' fontWeight="700" color='primary.main'>
                        {t("popups.titles.warning")}
                    </Typography>
                </Grid>
                <Grid item md={12} textAlign='center' pb={2}>
                    <Typography variant='body2' color="text.disabled">
                        {/* <b>{t("popups.titles.warning") + ": "}</b> */}
                        <span dangerouslySetInnerHTML={{ __html: title }} />
                    </Typography>
                    {subTitle && <Typography variant='body2' color="text.disabled">
                        {subTitle}
                    </Typography>}
                </Grid>
                <Grid item container md={12}>
                    <Grid item md={12}>
                        <Button
                            onClick={() => handleConfirm()}
                            variant='contained'
                            fullWidth
                            color={confirmButtonColor ?? 'primary'}
                        >
                            {confirmButtonTitle}
                        </Button>
                    </Grid>
                    <Grid item md={12} pt={1}>
                        <Button
                            onClick={() => handleCancel ? handleCancel() : handleClose()}
                            variant='outlined'
                            fullWidth
                        >
                            {cancelButtonTitle}
                        </Button>
                    </Grid>

                </Grid>
            </Grid>
        </Dialog>
    );
};
