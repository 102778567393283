import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  },
});

export const httpConfig = {
  //baseURL: 'https://stage.eprocurement.ae/api',
  //baseURL: "http://eproc.test/api",
  domain: process.env.NEXT_PUBLIC_API_BASE_URL,
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL + '/api',
};

export const apiRoutes = {
  vacanciesURL: 'contact/open-vacancies',
  contactUs: 'contact/contact-us',
  careerInquiry: 'contact/career-inquiry',
  applyVacancy: 'contact/applicant',
  profileInfo: 'account/user',
  updateProfile: 'account/update-profile',
  modifyAccessControl: '/update-access-control',
  modifyMyAccessControl: 'account/update-access-control',
  updateProfileImage: '/account/update-profile-picture',
  productsFilterURL: '/product/filter',
  productsApplyFiltersURL: '/product/filter/apply',
  alternativesProduct: '/product/alternative',
  applyAlternativesProduct: '/product/alternative/filter-apply',

  categories: '/product/category/all',
  productDetails: '/product/',
  alternativeProductDetails: '/product/alternative/',

  changePassword: 'account/password/change',
  requestVerificationCode: 'account/password/request-not-remember-password',
  reRequestVerificationCode: 'account/password/rerequest-not-remember-password',
  checkVerificationCode: 'account/password/confirm-not-remember-password-code',
  changePasswordWithToken: 'account/password/change-not-remembered',

  phoneNumberVerification: 'account/request-verification-code',
  phoneNumberVerificationAgain:
    'account/request-verification-code-again',
  isPhoneNumberVerified: 'account/verify',

  updateDirectNumber: 'account/update-direct-number',

  requestVerificationOldPhone: 'account/change-phone/request-via-phone',
  requestVerificationOldPhoneViaEmail: 'account/change-phone/request-via-mail',
  reRequestVerificationOldPhoneViaEmail:
    'account/change-phone/request-via-mail-again',
  reRequestVerificationOldPhone: 'account/change-phone/request-via-phone-again',
  verificationOldPhone: 'account/change-phone/verify',
  requestChangePhoneViaPhone: 'account/change-phone/change-to-new-number',
  verificationNewPhone: 'account/change-phone/verify-new-number',
  reRequestVerificationNewPhone:
    'account/change-phone/resend-verification-code-to-new-number',

  requestVerificationOldEmail: 'account/change-email/request-via-email',
  reRequestVerificationOldEmailViaEmail:
    'account/change-email/request-via-email-again',
  requestVerificationOldEmailViaPhone: 'account/change-email/request-via-phone',
  reRequestVerificationOldEmailViaPhone:
    'account/change-email/request-via-phone-again',
  verificationOldEmail: 'account/change-email/verify',
  requestChangeEmail: 'account/change-email/change-to-new-email',
  verificationNewEmail: 'account/change-email/verify-new-email',
  reRequestVerificationNewEmail:
    'account/change-email/resend-verification-code-to-new-email',

  uploadDocument: 'account/upload-company-document',
  deleteDocument: 'account/company-document',
  resendVerificationEmail: '/account/resend-verification-email',
  usersInfo: 'account/user/index',
  user: 'account/user',
  accountType: '/update-type',
  approve: '/approve',
  authorize: '/authorize',
  unauthorize: '/unauthorize',
  invitation: 'account/invitation',

  //requisition
  requisition: '/requisition/',
  requisitionDetails: 'requisition/details/',
  createRequisition: '/requisition/create',
  getAllRequisitions: '/requisition/index',
  addProductToRequisition: '/add-product',
  removeProductFromRequisition: '/remove-product',
  addAlternativeProductToRequisition: '/add-product-as-alternative',
  removeAlternativeProductFromRequisition: '/remove-alternative',
  changeProductQuantity: '/change-product-quantity',
  changeProductRequirements: '/change-product-requirements',
  regenerateRFQFromRequisition: '/regenerate-bundle-rfq',
  updateRequisitionProducts: '/update-products',
  requisitionTableIndex: '/requisition/table-index',
  applyRequisitionsTableFilters: '/requisition/table-filter',

  //procurement
  procurement: '/procurement',
  project: '/project',
  procurementData: '/requisition/all-data',
  createComment: '/comment/create',
  comment: '/comment',
  count: '/count',
  changeProject: '/change-form-project',

  //convert requisition to rfq
  convertToRFQ: '/convert-to-bundle-rfq',
  selectSellers: '/select-seller-for-product',
  updateProductTerms: '/update-product-terms',
  updateTerms: '/convert-to-rfq/terms',

  //request for quote buyer
  generateRFQ: '/request-for-quote/generate-bundle',
  request_for_quotes_index: '/request-for-quote/index',
  requestForQuotesDetails: '/seller/request-for-quote/show/',
  sellerRequestForQuote: '/seller/request-for-quote',
  declineReasons: '/decline-reasons',
  applyRFQTableFilters: '/request-for-quote/filter-results',
  applyRFQSellerTableFilters: '/seller/request-for-quote/filter-table',
  applyQuoteBuyerTableFilters: '/buyer/quote/filter-table',
  applyQuoteSellerTableFilters: '/seller/quote/filter-table',
  applyCatalogSellerTableFilters: '/seller/catalog-draft/filter-table',
  applyCatalogTableFilters: '/seller/catalog/filter',

  //request for quote seller
  request_for_quotes_seller_index: '/seller/request-for-quote/index',

  catalogDraftIndex: '/seller/catalog-draft/index',
  catalogEditableIndex: `/seller/catalog-draft/editable-drafts`,

  //bundle RFQ
  getBundleRFQ: '/request-for-quote/bundle-rfq/',
  edit: '/edit',
  comments: '/comments',
  addComment: '/add-comment',
  sendReminder: '/send-reminders',
  requestForQuote: '/request-for-quote/',
  sendForApproval: '/send-to-approvers',
  cancelSendingBundleRFQToApprovals: '/cancel-request-for-approvals',
  reject: '/reject',
  sendToBuyer: '/send-to-buyer',
  rejectReasons: 'reject-reasons',
  cancelReasons: 'cancel-reasons',
  sendToAllSellers: '/send-to-sellers',
  sendToAllSeller: '/sendToSeller',
  cancelQuoteToApprovals: '/cancel-request-for-approvals',
  cancelSelectSellers: '/cancel-select-sellers',
  clearSelectedSellers: '/clear-selected-sellers',
  cancel: '/cancel',
  create: '/create',
  decline: '/decline',
  rfqProduct: '/rfq-product/',

  //quote
  quote: {
    seller: {
      index: '/seller/quote/index',
    },
    buyer: {
      index: '/buyer/quote/index',
    },
  },

  //Quote
  createQuote: '/seller/quote/create',
  apiQuote: '/seller/quote/',
  apiQuoteBuyer: '/buyer/quote',
  show: '/show/',
  index: '/index',
  confirm: '/confirm',
  createFromScratch: 'create-from-scratch',
  indexEditableQuotes: `/seller/quote/editable/all`,

  buyerPurchaseOrder: '/buyer/purchase_order',
  buyerFilterPurchaseOrder: '/buyer/purchase_order/filter',
  sellerPurchaseOrder: '/seller/purchase_order',
  sellerFilterPurchaseOrder: '/seller/purchase_order/filter',
  sendToApprovers: '/sendToApprovers/',
  bundle: '/bundle',
  createPurchaseOrderFromCT: '/bundle/create',
  sendToSeller: '/send-to-seller',
  recall: '/recall',
  recall_reasons: '/recall-reasons',
  save_buyer: '/seller/quote/save-buyer',

  //Comparison table
  indexComparisonTables: '/comparison_table/index',
  filterComparisonTables: '/comparison_table/filter',
  getCT: '/comparison_table',
  cancelApproval: '/cancel-approval-request',
  update: '/update',
  delete: '/delete',
  duplicate: '/duplicate',
  getEditCT: '/comparison-table',

  selectTerms: '/update-terms',
  allTerms: '/procurement/terms/index',
  removeProduct: '/delete-product',
  updateDetailsProduct: '/update-product-details',
  bulkApproval: '/bulk-approval',
  rfqsApproval: '/rfqs-approval',
  allData: '/all-data',
  createIndividual: '/create-individual',
  individualPurchaseOrders: '/index-for-product',
  seller: '/seller',
  sellerDraft: '/catalog-draft',
  catalog: '/catalog',
  publish: '/publish',
  getTableData: '/table-index',

  // Seller Projects
  indexProjectsSeller: `/seller/project/index`,
  applySellerProjectsTableFilters: `/seller/project/filter`,
  getCreateSellerProjectSelectionsTable: `/seller/project/create`,
  storeSellerProject: `/seller/project/store`,
  editSellerProject: `/seller/project/update`,
  deleteSellerProject: `/seller/project/delete`,
  showSellerProjectDetails: `/seller/project/show`,
  sellerProjects: '/seller/project/all',
  // Buyer Projects
  indexProjectsBuyer: `/buyer/project/index`,
  applyBuyerProjectsTableFilters: `/buyer/project/filter`,
  getCreateBuyerProjectSelectionsTable: `/buyer/project/create`,
  storeBuyerProject: `/buyer/project/store`,
  editBuyerProject: `/buyer/project/update`,
  deleteBuyerProject: `/buyer/project/delete`,
  showBuyerProjectDetails: `/buyer/project/show`,

  // Seller Buyers
  indexSellerBuyers: `/seller/company-section/index-buyers`,
  filterSellerBuyers: `/seller/company-section/filter-buyers`,
  showBuyerCompanyDetails: `/seller/company-section/show-buyer`,

  // Seller ALL Buyers
  indexSellerAllBuyers: `/seller/company-section/index-all-buyers`,
  filterSellerAllBuyers: `/seller/company-section/filter-all-buyers`,

  // Buyer Sellers
  indexBuyerSellers: `/buyer/company-section/index-sellers`,
  filterBuyerSellers: `/buyer/company-section/filter-sellers`,
  showSellerCompanyDetails: `/buyer/company-section/show-seller`,

  // Buyer ALL Sellers
  indexBuyerAllSellers: `/buyer/company-section/index-all-sellers`,
  filterBuyerAllSellers: `/buyer/company-section/filter-all-sellers`,

  // Request Extension
  requestExtension: `/procurement/expiry/request-extension`,
  editValidityDate: `/procurement/expiry/update-validity-date`,
  declineRequestExtension: `/procurement/expiry/decline-extension`,

  // Buyer Project Tabs Details
  projectTablesRequisitionIndex: `/requisition/table/for-form/index`,
  projectTablesRequisitionFilters: `/requisition/table/for-form/filter`,
};

export const apiURLs = {
  productsFilterURL: httpConfig.baseURL + '/product/filter',
  productsApplyFiltersURL: httpConfig.baseURL + '/product/filter/apply',
  autocomplete: httpConfig.baseURL + '/product/filter/autocomplete',
  categoriesURL: httpConfig.baseURL + '/product/category/all',
  alternativeProduct: httpConfig.baseURL + '/products/alternatives',
  applyAlternativeFilters:
    httpConfig.baseURL + '/products/alternatives-apply-filters',
  registerData: httpConfig.baseURL + '/account/register-data',
  companiesAutocomplete: httpConfig.baseURL + '/account/autocomplete-companies',
  register: httpConfig.baseURL + '/account/register',
  // login: httpConfig.baseURL + '/login',
  validateEmail: httpConfig.baseURL + '/account/validate-email',
  resendVerification: httpConfig.baseURL + '/account/resend-verification-email',
  crfCookie: httpConfig.domain + '/sanctum/csrf-cookie',
  forgetPassword: httpConfig.baseURL + '/account/request-forget-password',
  forgetPasswordVerify:
    httpConfig.baseURL + '/account/confirm-reset-password-code',
  resetPassword: httpConfig.baseURL + '/account/reset-password',
  user: httpConfig.domain + '/api/account/user',
  logout: httpConfig.domain + '/api/account/logout',
  login: httpConfig.domain + '/account/login',
  csrfCookieRoute: httpConfig.domain + '/sanctum/csrf-cookie',
  changeUserData: httpConfig.baseURL + '/account/update-profile',
};

export const sanctumConfig = {
  apiUrl: httpConfig.domain,
  csrfCookieRoute: 'sanctum/csrf-cookie',
  signInRoute: 'client-login',
  signOutRoute: 'api/logout',
  userObjectRoute: 'api/user',
};

export * from './access-control';
export * from './breadcrumbs-config';
export * from './i18nNamespaces';
export * from './procurement';
export * from './socket-io';
export * from './user-data-config';
export * from './sum';