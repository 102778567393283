import { ProcurementButtons, ProcurementPopupStatus } from "@/config";
import { GeneralObject, LinkType, ProcurementFormModel, ProcurementModelDetails, Product, ProductTableRow } from "@/models";
import { buildProcurementFormDetails, buildPurchaseOrderProductsTable, NotifyMessage, NotifySuccess, NotifySuccessWithDeleteIcon, NotifySuccessWithCancelIcon, useApprovePurchaseOrder, useCancelPurchaseOrder, useChangeProject, useCreateEmptyPO, useDeclineRequestExtension, useDeleteSinglePo, useDuplicateSinglePo, useEditExpiryDate, useFetchRejectPurchaseOrderReasons, useGetAllTerms, useGetBuyerPurchaseOrderDetails, useGetIndvidual, useGetRecalledReasons, useRecallSinglePO, useRejectPurchaseOrder, useRemoveProduct, useSavePurchaseOrderDetails, useSelectTerms, useSendPurchaseOrderForApproval, useSendSinglePoToSeller, useUpdateDetailsProduct, useRequestExtension } from "@/services";
import { t } from "i18next";
import produce from "immer";
import { StateCreator } from "zustand";
import { getVATTotalValues } from "./createProcurementSlice";
import QueryHandler, { QueryHandlerModel } from "src/logic/services/query-handlers/QueryHandler";
import { GeneralTermsSlice } from "../shared/createGeneralTermsSlice";
import { GeneralTermsPayload, TermPayload } from "src/logic/models/procurement/general-terms";

export interface BuyerPurchaseOrderDetailsSlice {
    purchaseOrderBuyerDetailsSliceProperties: {
        purchaseOrder: ProcurementFormModel | null
        terms: any
        payment_term: TermPayload | null
        warranty_term: TermPayload | null
        delivery_term: TermPayload | null
        isTermsValid: boolean
        fetchPoDetailsQueryStatus: QueryHandlerModel,
        // loadingDetails: boolean
        productsTableRows: ProductTableRow[]
        newComments: Comment[]
        summaryDetails: { SummarySubtotal: number, SummaryVat: number, SummaryTotal: number }
        openedPopupStatus: ProcurementPopupStatus | null
        openedSuccessPopupsStatus: ProcurementPopupStatus | null
        reasonsForPopup: GeneralObject[]
        isIndividualForm: boolean
        loadingIndividualForms: boolean
        individualForms: ProcurementFormModel[]
        checkIfTermsValid: boolean
        customLoadingButton: ProcurementButtons | null
    }
    purchaseOrderBuyerDetailsSliceActions: {
        fetchDetails: (POId: number, handlerAfterFetch?: (data: any) => void) => void
        fetchTerms: () => void
        // setIsLoading: (isLoading: boolean) => void
        setDetails: (purchaseOrderResponse: ProcurementModelDetails, disableSetProductsTableRows?: boolean) => void
        setProductsTableRows: (purchaseOrder: ProcurementFormModel | null) => void
        closeNewCommentsPopup: () => void
        setSummaryDetails: (products: ProductTableRow[]) => void
        setPopupStatus: (status: ProcurementPopupStatus | null) => void
        setSuccessPopupStatus: (status: ProcurementPopupStatus | null) => void
        getReasonsForPopup: () => void
        sendForApproval: (optionalComment: string) => void
        reject: (reasonId: string, comment: string) => void
        approve: (comment: string) => void
        cancel: (comment: string) => void
        changeProject: (project: GeneralObject) => void
        rebuildDetails: (purchaseOrder: ProcurementModelDetails, successPopupStatus?: ProcurementPopupStatus) => void
        sendSinglePoToSeller: (poId: number | undefined, date: string, optionalComment?: string) => void
        recallSinglePO: (comment: string, reasonId?: string) => void
        cancelSinglePO: (comment: string, reasonId?: string) => void
        saveFormProducts: (form: ProcurementModelDetails, products: Product[], handleSavingSucess: (data: ProcurementModelDetails) => void, onError?: () => void) => void
        selectGeneralTerms: (terms: GeneralTermsPayload) => void
        changeOrderedQty: (product: ProductTableRow, quantity: number) => void
        changePrice: (product: ProductTableRow, price: string) => void
        changeAdditionalRequirements: (product: ProductTableRow, additional_requirements: string) => void
        changeWarrantyTerm: (product: ProductTableRow, warrantyTerm: TermPayload) => void
        addNewRemark: (product: ProductTableRow, remark: string) => void
        handleSelectForm: (values: { purchase_order_id: string, product_id: string, quantity: string, price: string }, handlerAfterFetch: (data: any) => void) => void
        create: (values: { projectId: string, projectName: string, sellerId: string }, handlerAfterFetch: (data: any) => void) => void
        removeProduct: (productId: number, onEmptyState: Function) => void
        updateProductDetails: (product: ProductTableRow | undefined) => void
        getPurchaseOrderFromRelatedData: (productId: string, sellerId?: string, onSuccess?: (individualForms: ProcurementFormModel[]) => void) => void
        setCheckIfTermsValid: (value: boolean) => void
        setCustomLoadingButton: (isLoading: ProcurementButtons | null) => void
        deleteSinglePO: (poId: number, redirectToPOs: () => void) => void
        handleDuplicateSinglePO: (poId: number | undefined, project: GeneralObject, redirectToDuplicatedForm: (id: number) => void) => void
        editExpiryDate: (validityDate: string, closePopup: () => void) => void
        declineRequestExtension: (poId: number | undefined) => void
        requestExtensionForExpiredQuote: (quoteId: number | undefined) => void
    }
}

export const createBuyerPurchaseOrderDetailsSlice: StateCreator<BuyerPurchaseOrderDetailsSlice> = (set, get, api) => ({
    purchaseOrderBuyerDetailsSliceProperties: {
        purchaseOrder: null,
        fetchPoDetailsQueryStatus: QueryHandler.getInitialStatus(),
        // loadingDetails: false,
        payment_term: { id: null, name: '' },
        warranty_term: { id: null, name: '' },
        delivery_term: { id: null, name: '' },
        terms: null,
        isTermsValid: false,
        checkIfTermsValid: false,
        productsTableRows: [],
        newComments: [],
        summaryDetails: { SummarySubtotal: 0, SummaryVat: 0, SummaryTotal: 0 },
        openedPopupStatus: null,
        openedSuccessPopupsStatus: null,
        reasonsForPopup: [],
        isIndividualForm: false,
        loadingIndividualForms: false,
        individualForms: [],
        customLoadingButton: null,
    },
    purchaseOrderBuyerDetailsSliceActions: {
        fetchDetails: (POId: number, handlerAfterFetch?: (data: any) => void) => {
            set(produce(draftState => {
                draftState.purchaseOrderBuyerDetailsSliceProperties.fetchPoDetailsQueryStatus = QueryHandler.getStartStatus();
            }))
            useGetBuyerPurchaseOrderDetails(POId).then(data => {
                get().purchaseOrderBuyerDetailsSliceActions.setDetails(data.data)
                get().purchaseOrderBuyerDetailsSliceActions.fetchTerms()

                set(produce(draftState => {
                    draftState.purchaseOrderBuyerDetailsSliceProperties.fetchPoDetailsQueryStatus = QueryHandler.getSuccessStatus();
                }))
                const formDetails = data.data as ProcurementModelDetails
                const formProducts = getPurchaseOrderProductsArray(formDetails?.products)
                if (handlerAfterFetch) handlerAfterFetch({ ...formDetails, products: formProducts })
            }).catch(response => {
                set(produce(draftState => {
                    draftState.purchaseOrderBuyerDetailsSliceProperties.fetchPoDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                }))
            })
        },
        fetchTerms: () => {
            const generalTermsSlice = get() as GeneralTermsSlice;

            const terms = get().purchaseOrderBuyerDetailsSliceProperties.terms;
            const terms_bundle = get().purchaseOrderBuyerDetailsSliceProperties.purchaseOrder?.model?.terms_bundle;

            if (terms) {
                generalTermsSlice.generalTerms.actions.initializeGeneralTerms(terms, terms_bundle);
                return;
            }

            if (!terms) {
                generalTermsSlice.generalTerms.actions.setIsLoading(true);
                useGetAllTerms().then(data => {
                    const allTerms = data.data
                    set(produce(draftState => {
                        draftState.purchaseOrderBuyerDetailsSliceProperties.terms = allTerms;
                    }))
                    generalTermsSlice.generalTerms.actions.initializeGeneralTerms(allTerms, terms_bundle);
                    generalTermsSlice.generalTerms.actions.setIsLoading(false);
                })
            }
        },
        // setIsLoading: (isLoading: boolean) => set(produce(draftState => { draftState.purchaseOrderBuyerDetailsSliceProperties.loadingDetails = isLoading })),
        setDetails: (purchaseOrderResponse: ProcurementModelDetails, disableSetProductsTableRows?: boolean) => {
            set(produce(draftState => {
                draftState.purchaseOrderBuyerDetailsSliceProperties.customLoadingButton = null
                draftState.purchaseOrderBuyerDetailsSliceProperties.purchaseOrder = buildProcurementFormDetails(purchaseOrderResponse, 'canApprovePurchaseOrder', false, true)
                draftState.purchaseOrderBuyerDetailsSliceProperties.newComments = purchaseOrderResponse.new_comments?.length == 0 ? null : purchaseOrderResponse.new_comments
                draftState.purchaseOrderBuyerDetailsSliceProperties.isIndividualForm = purchaseOrderResponse.is_individual
                draftState.purchaseOrderBuyerDetailsSliceProperties.isTermsValid = purchaseOrderResponse.terms_bundle?.payment_term && purchaseOrderResponse.terms_bundle?.warranty_term && purchaseOrderResponse.terms_bundle?.delivery_term ? true : false
                draftState.purchaseOrderBuyerDetailsSliceProperties.checkIfTermsValid = false
                draftState.purchaseOrderBuyerDetailsSliceProperties.payment_term = purchaseOrderResponse.terms_bundle?.payment_term ?? { id: null, name: '' }
                draftState.purchaseOrderBuyerDetailsSliceProperties.warranty_term = purchaseOrderResponse.terms_bundle?.warranty_term ?? { id: null, name: '' }
                draftState.purchaseOrderBuyerDetailsSliceProperties.delivery_term = purchaseOrderResponse.terms_bundle?.delivery_term ?? { id: null, name: '' }
            }))
            if (!disableSetProductsTableRows) {
                get().purchaseOrderBuyerDetailsSliceActions.setProductsTableRows(get().purchaseOrderBuyerDetailsSliceProperties.purchaseOrder)

                // Set the summary values from the backend on initial load
                set(produce(draftState => {
                    draftState.purchaseOrderBuyerDetailsSliceProperties.summaryDetails.SummarySubtotal = purchaseOrderResponse.subtotal_value
                    draftState.purchaseOrderBuyerDetailsSliceProperties.summaryDetails.SummaryVat = purchaseOrderResponse.vat_value
                    draftState.purchaseOrderBuyerDetailsSliceProperties.summaryDetails.SummaryTotal = purchaseOrderResponse.total_value
                }))
            }

            get().setTimeline(purchaseOrderResponse.timeline);
        },
        changeProject: (project: GeneralObject) => {
            const modelAfterUpdateProject = useChangeProject(project, get().purchaseOrderBuyerDetailsSliceProperties.purchaseOrder?.model)
            get().purchaseOrderBuyerDetailsSliceActions.setDetails(modelAfterUpdateProject, true)
        },
        setProductsTableRows: (purchaseOrder: ProcurementFormModel | null) => {
            const generalWarrantyTerm = get().purchaseOrderBuyerDetailsSliceProperties?.warranty_term
            const ProductsArray = buildPurchaseOrderProductsTable(purchaseOrder, generalWarrantyTerm)
            set(produce(draftState => { draftState.purchaseOrderBuyerDetailsSliceProperties.productsTableRows = ProductsArray }))
            get().purchaseOrderBuyerDetailsSliceActions.setSummaryDetails(ProductsArray)
        },
        closeNewCommentsPopup: () => set(produce(draftState => { 
            const poModel = draftState.purchaseOrderBuyerDetailsSliceProperties.purchaseOrder?.model;
            if (poModel) {
                poModel.new_comments = null;
            }
            draftState.purchaseOrderBuyerDetailsSliceProperties.newComments = null 
        })),
        setSummaryDetails: (products: ProductTableRow[]) => {
            const summary = getVATTotalValues(products, 'requestedQuantity')
            set(produce(draftState => {
                draftState.purchaseOrderBuyerDetailsSliceProperties.summaryDetails.SummarySubtotal = summary.subtotal
                draftState.purchaseOrderBuyerDetailsSliceProperties.summaryDetails.SummaryVat = summary.vat
                draftState.purchaseOrderBuyerDetailsSliceProperties.summaryDetails.SummaryTotal = summary.total
            }))
        },
        setPopupStatus: (status: ProcurementPopupStatus | null) => set(produce(draftState => {
            draftState.purchaseOrderBuyerDetailsSliceProperties.openedPopupStatus = status
        })),
        setSuccessPopupStatus: (status: ProcurementPopupStatus | null) => set(produce(draftState => {
            draftState.purchaseOrderBuyerDetailsSliceProperties.openedSuccessPopupsStatus = status
        })),
        getReasonsForPopup: () => {
            if (get().purchaseOrderBuyerDetailsSliceProperties.openedPopupStatus == ProcurementPopupStatus.REJECT)
                useFetchRejectPurchaseOrderReasons().then(data => { set(produce(draftState => { draftState.purchaseOrderBuyerDetailsSliceProperties.reasonsForPopup = data.data })) })
            if (get().purchaseOrderBuyerDetailsSliceProperties.openedPopupStatus == ProcurementPopupStatus.RECALL || get().purchaseOrderBuyerDetailsSliceProperties.openedPopupStatus == ProcurementPopupStatus.CANCEL)
                useGetRecalledReasons().then(data => { set(produce(draftState => { draftState.purchaseOrderBuyerDetailsSliceProperties.reasonsForPopup = data.data })) })
        },
        sendForApproval: (optionalComment: string) => {
            get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SEND_SINGLE_PO_FOR_APPROVAL)
            const poId = get().purchaseOrderBuyerDetailsSliceProperties.purchaseOrder?.model?.id
            useSendPurchaseOrderForApproval(poId, optionalComment).then(data => {
                get().purchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data, ProcurementPopupStatus.SEND_FOR_APPROVAL)
                NotifySuccess(t("popups.titles.sent_for_approval"))
            }).catch(() => get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        reject: (reasonId: string, comment: string) => {
            get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.REJECT_SINGLE_PO)
            const poId = get().purchaseOrderBuyerDetailsSliceProperties.purchaseOrder?.model?.id
            useRejectPurchaseOrder(poId, reasonId, comment).then(data => {
                get().purchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data)
                NotifySuccessWithCancelIcon(t('notifications.form_rejected', { formName: get().purchaseOrderBuyerDetailsSliceProperties.purchaseOrder?.model?.name }))
            }).catch(() => get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        approve: (comment: string) => {
            get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.APPROVE_SINGLE_PO)
            const poId = get().purchaseOrderBuyerDetailsSliceProperties.purchaseOrder?.model?.id
            useApprovePurchaseOrder(poId, comment).then(data => {
                get().purchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data, ProcurementPopupStatus.APPROVE)
                NotifySuccess(t('notifications.form_approved', { formName: get().purchaseOrderBuyerDetailsSliceProperties.purchaseOrder?.model?.name }))
            }).catch(() => get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        cancel: (comment: string) => {
            get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.CANCEL_SINGLE_PO_APPROVAL)
            const poId = get().purchaseOrderBuyerDetailsSliceProperties.purchaseOrder?.model?.id
            useCancelPurchaseOrder(poId, comment).then(data => {
                get().purchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data, ProcurementPopupStatus.CANCEL_APPROVAL)
                NotifyMessage(t("popups.titles.approval_canceled"))
            }).catch(() => get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        rebuildDetails: (purchaseOrder: ProcurementModelDetails, successPopupStatus?: ProcurementPopupStatus) => {
            const actions = get().purchaseOrderBuyerDetailsSliceActions
            actions.setPopupStatus(null)
            if (successPopupStatus) actions.setSuccessPopupStatus(successPopupStatus)
            actions.setDetails(purchaseOrder)
        },
        sendSinglePoToSeller: (poId: number | undefined, date: string, optionalComment?: string) => {
            get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SEND_SINGLE_PO_TO_SELLER)
            useSendSinglePoToSeller(poId, date, optionalComment ?? null).then((data) => {
                get().purchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data, ProcurementPopupStatus.SENT_TO_SELLER)
                NotifySuccess(t('notifications.form_sent_to_seller', { formName: get().purchaseOrderBuyerDetailsSliceProperties.purchaseOrder?.model?.name }))
            }).catch(() => get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        recallSinglePO: (comment: string, reasonId?: string) => {
            get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.RECALL_SINGLE_PO)
            const po = get().purchaseOrderBuyerDetailsSliceProperties?.purchaseOrder?.model
            const poId = po?.id
            const poName = po?.name
            useRecallSinglePO(poId, comment, reasonId).then(data => {
                get().purchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data)
                NotifyMessage(t('notifications.po_recalled', { poName: poName }))
            }).catch(() => get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        cancelSinglePO: (comment: string, reasonId?: string) => {
            get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.CANCEL_SINGLE_PO)
            const po = get().purchaseOrderBuyerDetailsSliceProperties?.purchaseOrder?.model
            const poId = po?.id
            const poName = po?.name
            useRecallSinglePO(poId, comment, reasonId).then(data => {
                get().purchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data)
                NotifyMessage(t('notifications.form_canceled', { formType: poName }))
            }).catch(() => get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        saveFormProducts: (form: ProcurementModelDetails, products: Product[], handleSavingSucess: (data: ProcurementModelDetails) => void, onError?: () => void) => {
            useSavePurchaseOrderDetails(form, products).then(data => {
                const formDetails = data.data as ProcurementModelDetails
                const formProducts = getPurchaseOrderProductsArray(formDetails?.products)
                handleSavingSucess({ ...formDetails, products: formProducts })
            }).catch(() => {
                onError?.();
            })
        },
        selectGeneralTerms: (terms: GeneralTermsPayload) => {
            const PoId = get().purchaseOrderBuyerDetailsSliceProperties?.purchaseOrder?.model?.id

            const { payment_term, warranty_term, delivery_term } = terms;
            const isTermsValid = (payment_term?.id && warranty_term?.id && delivery_term?.id) ? true : false

            set(produce(draftState => {
                draftState.purchaseOrderBuyerDetailsSliceProperties.payment_term = payment_term ?? { id: null, name: '' }
                draftState.purchaseOrderBuyerDetailsSliceProperties.warranty_term = warranty_term ?? { id: null, name: '' }
                draftState.purchaseOrderBuyerDetailsSliceProperties.delivery_term = delivery_term ?? { id: null, name: '' }
                draftState.purchaseOrderBuyerDetailsSliceProperties.isTermsValid = isTermsValid
                draftState.purchaseOrderBuyerDetailsSliceProperties.checkIfTermsValid = false
            }))

            if(isTermsValid) {
                const generalTermsSlice = get() as GeneralTermsSlice;
                generalTermsSlice.generalTerms.actions.setIsLoading(true);
                useSelectTerms(PoId, { payment_term, warranty_term, delivery_term }).then(data => {
                    generalTermsSlice.generalTerms.actions.setIsLoading(false);
                    get().purchaseOrderBuyerDetailsSliceActions.setDetails(data.data)
                })
            }
        },
        changeOrderedQty: (product: ProductTableRow, quantity: number) => {
            let productRows = get().purchaseOrderBuyerDetailsSliceProperties.productsTableRows
            let changedProductRows = productRows.map((productRow: ProductTableRow) => {
                if (productRow.id == product.id) return produce(productRow, draftProductRow => {
                    draftProductRow.requestedQuantity = quantity
                })
                else return productRow
            })
            set(produce(draftState => {
                draftState.purchaseOrderBuyerDetailsSliceProperties.productsTableRows = changedProductRows
            }))
            get().purchaseOrderBuyerDetailsSliceActions.setSummaryDetails(changedProductRows)
        },
        changePrice: (product: ProductTableRow, price: string) => {
            let productRows = get().purchaseOrderBuyerDetailsSliceProperties.productsTableRows
            let changedProductRows = productRows.map((productRow: ProductTableRow) => {
                if (productRow.id == product.id) return produce(productRow, draftProductRow => {
                    draftProductRow.unitPrice = price !== 0 ? price : null
                })
                else return productRow
            })
            set(produce(draftState => {
                draftState.purchaseOrderBuyerDetailsSliceProperties.productsTableRows = changedProductRows
            }))
            get().purchaseOrderBuyerDetailsSliceActions.setSummaryDetails(changedProductRows)
        },
        changeAdditionalRequirements: (product: ProductTableRow, additional_requirements: string) => {
            const productRows = get().purchaseOrderBuyerDetailsSliceProperties.productsTableRows
            const changedProductRows = productRows.map((productRow: ProductTableRow) => {
                if (productRow.id == product.id) return produce(productRow, draftProductRow => {
                    draftProductRow.additional_requirements = additional_requirements
                })
                else return productRow
            })
            set(produce(draftState => {
                draftState.purchaseOrderBuyerDetailsSliceProperties.productsTableRows = changedProductRows
            }))
            get().purchaseOrderBuyerDetailsSliceActions.setSummaryDetails(changedProductRows)
            let updatedProductRow: ProductTableRow | undefined = changedProductRows?.find((updatedProductRow: ProductTableRow) => product.id == updatedProductRow.id)
            get().purchaseOrderBuyerDetailsSliceActions.updateProductDetails(updatedProductRow)
        },
        changeWarrantyTerm: (product: ProductTableRow, warranty: TermPayload) => {
            let productRows = get().purchaseOrderBuyerDetailsSliceProperties.productsTableRows
            let changedProductRows = productRows?.map((productRow: ProductTableRow) => {
                if (productRow.id == product.id) return produce(productRow, draftProductRow => {
                    draftProductRow.warranty_term = warranty
                })
                else return productRow
            })
            set(produce(draftState => {
                draftState.purchaseOrderBuyerDetailsSliceProperties.productsTableRows = changedProductRows
            }))
            get().purchaseOrderBuyerDetailsSliceActions.setSummaryDetails(changedProductRows)
            let updatedProductRow: ProductTableRow | undefined = changedProductRows?.find((updatedProductRow: ProductTableRow) => product.id == updatedProductRow.id)
            get().purchaseOrderBuyerDetailsSliceActions.updateProductDetails(updatedProductRow)
        },
        addNewRemark: (product: ProductTableRow, remark: string) => {
            let productRows = get().purchaseOrderBuyerDetailsSliceProperties.productsTableRows
            set(produce(draftState => {
                draftState.purchaseOrderBuyerDetailsSliceProperties.productsTableRows = productRows.map((productRow: ProductTableRow) => {
                    if (productRow.id == product.id) return produce(productRow, draftProductRow => {
                        draftProductRow.remark = remark
                    })
                    else return productRow
                })
            }))
        },
        handleSelectForm: (values: { purchase_order_id: string }, handlerAfterFetch: (data: any) => void) => {
            get().purchaseOrderBuyerDetailsSliceActions.fetchDetails(values.purchase_order_id, handlerAfterFetch)
        },
        create: (values: { projectId: string, projectName: string, sellerId: string }, handlerAfterFetch: (data: any) => void) => {
            useCreateEmptyPO(values.projectId, values.projectName, values.sellerId).then(data => {
                const formDetails = data.data as ProcurementModelDetails
                const formProducts = getPurchaseOrderProductsArray(formDetails?.products)
                if (handlerAfterFetch) handlerAfterFetch({ ...formDetails, products: formProducts })
                // NotifySuccess(t('notifications.purchase_order_created'))
                NotifySuccess(t('notifications.create_form', { formName: formDetails.name }))
            })
        },
        removeProduct: (productId: number, onEmptyState: Function) => {
            const poId = get().purchaseOrderBuyerDetailsSliceProperties?.purchaseOrder?.model?.id
            useRemoveProduct(poId, productId).then(data => {
                get().purchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data)
                if (get().purchaseOrderBuyerDetailsSliceProperties.productsTableRows.length == 0) {
                    onEmptyState?.();
                }
            })
        },
        updateProductDetails: (product: ProductTableRow | undefined) => {
            let updatedProductRow: any = {}
            updatedProductRow = get().purchaseOrderBuyerDetailsSliceProperties.productsTableRows?.find((updatedProductRow: ProductTableRow) => product?.id == updatedProductRow.id)
            let priceInteger = updatedProductRow?.unitPrice ? parseInt(updatedProductRow?.unitPrice) : null

            const poId = get().purchaseOrderBuyerDetailsSliceProperties?.purchaseOrder?.model?.id
            useUpdateDetailsProduct(poId, updatedProductRow?.productId, updatedProductRow?.requestedQuantity, priceInteger, updatedProductRow?.warranty_term?.id, updatedProductRow?.warranty_term?.value, updatedProductRow?.remark, updatedProductRow?.additional_requirements)
        },
        setCheckIfTermsValid: (value: boolean) => {
            set(produce(draftState => {
                draftState.purchaseOrderBuyerDetailsSliceProperties.checkIfTermsValid = value
            }))
        },
        getPurchaseOrderFromRelatedData: (productId: string, sellerId?: string, onSuccess?: (individualForms: ProcurementFormModel[]) => void) => {
            set(produce(draftState => { draftState.purchaseOrderBuyerDetailsSliceProperties.loadingIndividualForms = true }))
            useGetIndvidual(productId, sellerId).then((data) => {
                set(produce(draftState => {
                    draftState.purchaseOrderBuyerDetailsSliceProperties.individualForms = data.data
                    draftState.purchaseOrderBuyerDetailsSliceProperties.loadingIndividualForms = false
                }))
                onSuccess?.(data.data)
            })
        },
        setCustomLoadingButton: (customButton: ProcurementButtons | null) => {
            set(produce(draftState => {
                draftState.purchaseOrderBuyerDetailsSliceProperties.customLoadingButton = customButton
                if (!customButton) { draftState.purchaseOrderBuyerDetailsSliceProperties.customLoadingButton = null }
            }))
        },
        deleteSinglePO: (poId: number, redirectToPOs: () => void) => {
            useDeleteSinglePo(poId).then(() => {
                NotifySuccessWithDeleteIcon(t('notifications.form_deleted', { formName: get().purchaseOrderBuyerDetailsSliceProperties.purchaseOrder?.model.name }));
                redirectToPOs()
                set(produce(draftState => {
                    draftState.purchaseOrderBuyerDetailsSliceProperties.purchaseOrder = null
                }))
            })
        },
        handleDuplicateSinglePO: (poId: number | undefined, project: GeneralObject, redirectToDuplicatedForm: (id: number) => void) => {
            useDuplicateSinglePo(poId, project).then((data) => {
                redirectToDuplicatedForm(data.data.id);
                NotifySuccess(t('notifications.form_duplicated', { formName: get().purchaseOrderBuyerDetailsSliceProperties.purchaseOrder?.model.name }));
            });
        },
        editExpiryDate: (validityDate: string, closePopup: () => void) => {
            const poId = get().purchaseOrderBuyerDetailsSliceProperties.purchaseOrder?.model?.id
            get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SAVE_EXPIRY_DATE)
            useEditExpiryDate(poId, LinkType.PO_SINGLE, validityDate).then((data) => {
                get().purchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data)
                closePopup()
                NotifySuccess(t('notifications.expiry_date_edited'));
            }).catch(() => get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        declineRequestExtension: (poId: number | undefined) => {
            get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.DECLINE_REQUEST_EXTENSION)
            useDeclineRequestExtension(poId, 'PurchaseOrder').then((data) => {
                get().purchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data)
                get().purchaseOrderBuyerDetailsSliceActions.closeNewCommentsPopup()
                NotifySuccessWithCancelIcon(t('notifications.request_extension_declined'));
            }).catch(() => get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        requestExtensionForExpiredQuote: (quoteId: number | undefined) => {
            get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.REQUEST_EXTENSION)
            useRequestExtension(quoteId, 'Quote').then((data) => {
                set(produce(draftState => { draftState.purchaseOrderBuyerDetailsSliceProperties.customLoadingButton = null }))
                // get().purchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data)
                let model = get().purchaseOrderBuyerDetailsSliceProperties.purchaseOrder?.model
                let updatedForm = { ...model, quote: { ...model?.quote, form_action_permissions: { ...model?.quote?.form_action_permissions, can_request_extension: false } } }
                get().purchaseOrderBuyerDetailsSliceActions.rebuildDetails(updatedForm)
                NotifySuccess(t('notifications.extension_requested'))
            }).catch(() => get().purchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
    }
})

export const getPurchaseOrderProductsArray = (products: Product[]) => {
    return products.map(product => { return { ...product, ...product.product, quantity: product.requested_quantity, price: product.unit_price } })
}