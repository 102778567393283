import { AuthenticateStatus, ProcurementButtons } from '@/config';
import { Company, ReadStatus } from '@/models';
import { useUserData } from '@/zustand';
import BadgeIcon from '@mui/icons-material/Badge';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React, { FC, useRef } from 'react';
import { PopupUtils } from 'src/logic/hooks/use-popup';
import { sm } from 'src/site-map';
import { EditValidityDatePopup } from 'src/ui/components/dumb';
import { SelectWithSearchAndCreate, StyledPopper } from 'src/ui/components/shared';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';

type FormHeaderCompanyDetailsProps = {
  companyType: 'Buyer' | 'Seller';
  companyDetails: Company | undefined;
  formModel: any;
  formType?: string;
  customLoadingButton?: ProcurementButtons | null;
  onEditExpiryDate?: (validityDate: string, handleClose: () => void) => void;
  editValidityPopper?: PopupUtils;
  showNumberOfCompetitors?: boolean;
  handleSaveSelectedBuyer?: (companyId: string, onClose: () => void) => void;
  onCreateNew?: () => void;
  selectionList?: any
};

const iconStyles: React.CSSProperties = {
  backgroundColor: '#EAEEF6',
  aspectRatio: '1',
  width: '40px',
  height: '40px',
  marginInlineEnd: '.75rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
};

export const FormHeaderCompanyDetails: FC<FormHeaderCompanyDetailsProps> = ({
  companyType,
  formType,
  companyDetails,
  formModel,
  customLoadingButton,
  onEditExpiryDate,
  editValidityPopper,
  showNumberOfCompetitors = false,
  handleSaveSelectedBuyer,
  selectionList,
  onCreateNew
}) => {
  const { t } = useTranslation();

  const anchorEL = useRef<HTMLElement>(null);
  const companyDetailsUrl =
    companyType === 'Seller'
      ? sm.portals.buyer.sellers.sellerId(companyDetails?.id?.toString()).url
      : sm.portals.seller.buyers.buyerId(companyDetails?.id?.toString()).url;
  const { userInfo } = useUserData();
  const isOwner = userInfo?.id == formModel?.owner?.id;
  const isGuest = useUserData().authenticationStatus !== AuthenticateStatus.AUTHENTICATED;
  return (
    <>

      <Grid container md={12}>
        <Grid item md={6}>
          <div style={{ width: '100%', display: 'flex', paddingBlock: '0.5rem', alignItems: 'center' }}>
            <div style={iconStyles}>
              <BadgeIcon color='primary' />
            </div>
            <div>
              <Typography variant='subtitle1' lineHeight={1.2} fontWeight={400} color='#8B8D98' sx={{ display: 'inline-flex', alignItems: 'center' }}>
                {companyType}
                {formModel?.form_action_permissions.can_edit_counter_party && companyDetails != null && <SelectWithSearchAndCreate
                  sx={{ ml: 'auto', textAlign: 'start' }}
                  selectionList={selectionList}
                  isTextualEdit={companyDetails != null}
                  selectionLabel={companyType}
                  searchLabel={companyType === 'Buyer' ? 'search_buyers' : 'search'}
                  onSaveAction={(companyId: string, onClose: () => void) => handleSaveSelectedBuyer && handleSaveSelectedBuyer(companyId, onClose)}
                  onCreateNew={onCreateNew}
                  isButtonLoading={customLoadingButton == ProcurementButtons.SAVE_NEW_COMPANY}
                  defaultSelectedCompanyId={companyDetails?.id}
                  dataTestId={companyType === 'Buyer' ? 'select-buyer-btn' : 'select-seller-btn'}
                />}
              </Typography>
              <Typography variant='body2' fontWeight={400} color='#3A3E50'>
                {!companyDetails ?
                  formModel?.form_action_permissions.can_edit_counter_party && <SelectWithSearchAndCreate
                    sx={{ ml: '20px', textAlign: 'start' }}
                    selectionList={selectionList}
                    isTextualEdit={false}
                    selectionLabel={companyType}
                    searchLabel={companyType === 'Buyer' ? 'search_buyers' : 'search'}
                    onSaveAction={(companyId: string, onClose: () => void) => handleSaveSelectedBuyer && handleSaveSelectedBuyer(companyId, onClose)}
                    onCreateNew={onCreateNew}
                    isButtonLoading={customLoadingButton == ProcurementButtons.SAVE_NEW_COMPANY}
                    defaultSelectedCompanyId={companyDetails?.id}
                    dataTestId={companyType === 'Buyer' ? 'select-buyer-btn' : 'select-seller-btn'}
                  />
                  : (
                    <>
                      {isGuest ? (
                        <span style={{ marginInlineEnd: '1.5em', fontWeight: 700, fontSize: '1.175em' }}>
                          {companyDetails?.name}
                          {companyDetails?.is_private && <PrivacyButton disableText />}
                        </span>
                      ) :
                        (<Link href={companyDetailsUrl}>
                          <span
                            style={{ marginInlineEnd: '1.5em', fontWeight: 700, fontSize: '1.175em', cursor: 'pointer' }}
                            className='underline-on-hover'
                          >
                            {companyDetails?.name}
                            {companyDetails?.is_private && <PrivacyButton disableText iconHeight='20px' />}
                          </span>
                        </Link>)}
                    </>
                  )}
                {companyDetails?.name && companyDetails?.work_number && (
                  <span style={{ marginInlineEnd: '1.5em', fontSize: '14px' }}>{companyDetails?.work_number}</span>
                )}
                {companyDetails?.company_address ? (<span style={{ marginInlineEnd: '1.5em', fontSize: '14px' }}>{companyDetails?.company_address}</span>)
                  : (companyDetails?.country && companyDetails?.city) && (
                    <span style={{ marginInlineEnd: '1.5em', fontSize: '14px' }}>{companyDetails?.country?.name + ",  " + companyDetails?.city?.name}</span>
                  )}
              </Typography>
            </div>
          </div>
        </Grid>

        <Grid item container md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          {formModel?.read_status && (
            <Grid item container md={2.5} alignItems={'center'} justifyContent={'center'} sx={{ borderRadius: '8px' }}>
              <Box
                width='40px'
                height='40px'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: formModel?.read_status == ReadStatus.READ ? 'rgba(218, 244, 235, 1)' : 'primary.light',
                  borderRadius: '4px',
                }}
              >
                <DoneAllIcon
                  sx={{ color: formModel?.read_status == ReadStatus.READ ? 'success.main' : 'primary.main' }}
                />
              </Box>
              <Grid item pl={1}>
                <Typography variant='subtitle2' color={'text.third'} fontWeight={400}>
                  {t('table_cells.status')}
                </Typography>
                <Typography variant='subtitle2' fontWeight={700} color='text.disabled'>
                  {formModel?.read_status == ReadStatus.READ ? 'Read' : 'Unread'}
                </Typography>
              </Grid>
            </Grid>
          )}

          {showNumberOfCompetitors && (
            <Grid item md={2} alignItems={'end'} justifyContent={'center'} sx={{ my: 2 }}>
              <div
                style={{
                  color: '#8B8D98',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={iconStyles}>
                  <InfoOutlinedIcon color='primary' />
                </div>
                <div>
                  <Typography variant='subtitle2'>{t('table_cells.competitors')}</Typography>
                  <Typography
                    fontWeight={700}
                    variant='subtitle2'
                    color='text.disabled'
                    sx={{ lineHeight: 1.1, whiteSpace: 'nowrap' }}
                  >
                    {formModel?.number_of_competitors} Seller{formModel?.number_of_competitors > 1 && 's'}
                  </Typography>
                </div>
              </div>
            </Grid>
          )}
          {(formModel?.validity_date || formModel?.is_expired) && (
            <Grid
              item
              container
              md={3.5}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                my: 1,
                bgcolor: formModel?.is_expired ? 'rgba(255, 77, 77, 0.2)' : 'white',
                borderRadius: '4px',
                py: 1,
              }}
            >
              <Box
                width='40px'
                height='40px'
                sx={{
                  ml: -0.5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: formModel?.is_expired ? 'none' : 'primary.light',
                  borderRadius: '4px',
                }}
              >
                <CalendarMonthIcon sx={{ color: formModel?.is_expired ? 'info.dark' : 'primary.main' }} />
              </Box>
              <Grid item pl={0.3}>
                <Typography variant='subtitle2' color={formModel?.is_expired ? 'info.dark' : 'text.third'}>
                  {formType ? formType + ' ' : null}
                  {formModel?.is_expired ? t('texts.expired_on') : t('texts.valid_until')}
                </Typography>
                <Grid item container md={12} alignItems={'center'} gap={0.5}>
                  <Typography variant='subtitle2' fontWeight={700} color='text.disabled'>
                    {formModel?.validity_date?.split('-').join('/')}
                  </Typography>
                  {formModel?.form_action_permissions?.can_update_validity_date && isOwner && (
                    <Typography
                      variant='subtitle2'
                      fontWeight={600}
                      sx={{ cursor: 'pointer' }}
                      ref={anchorEL}
                      onClick={editValidityPopper.handleOpen}
                    >
                      <BorderColorOutlinedIcon sx={{ ml: 0.5, fontSize: '15px', color: 'primary.main' }} />
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {editValidityPopper !== undefined && (
        <EditValidityDatePopper
          isFormExpired={formModel?.is_expired}
          validityDate={formModel.validity_date}
          open={editValidityPopper.isOpen}
          anchorEl={anchorEL.current}
          customLoadingButton={customLoadingButton}
          onEditExpiryDate={onEditExpiryDate}
          handleClosePopper={editValidityPopper.handleClose}
        />
      )}
    </>
  );
};

type EditValidityDatePopperProps = {
  validityDate: string;
  isFormExpired: boolean;
  open: boolean;
  anchorEl: null | HTMLElement;
  customLoadingButton: ProcurementButtons | null | undefined;
  onEditExpiryDate: ((validityDate: string, handleClose: () => void) => void) | undefined;
  handleClosePopper: () => void;
};

export const EditValidityDatePopper: FC<EditValidityDatePopperProps> = ({
  validityDate,
  isFormExpired,
  open,
  anchorEl,
  customLoadingButton,
  onEditExpiryDate,
  handleClosePopper,
}) => {
  const handleEdit = (validityDate: string) => {
    onEditExpiryDate && onEditExpiryDate(validityDate, handleClosePopper);
  };

  return (
    <StyledPopper anchorEl={anchorEl} open={open} handleClose={handleClosePopper} zIndex={4}>
      <Grid container md={12}>
        <EditValidityDatePopup
          isFormExpired={isFormExpired}
          dateForm={validityDate}
          onEditExpiryDate={(data: string) => handleEdit(data)}
          customLoadingButton={customLoadingButton}
        />
      </Grid>
    </StyledPopper>
  );
}; 
