import { ProcurementPopupStatus } from '@/config';
import { GeneralObject, ProcurementFormModel, ProcurementModelDetails, Product, ProductTableRow } from '@/models';
import {
  buildProcurementFormDetails,
  useChangeProject,
  useGetAllTerms,
  useGetBuyerPurchaseOrderDetails,
  useUpdatePurchaseOrderFromScratch,
} from '@/services';
import produce from 'immer';
import { StateCreator } from 'zustand';
import { getVATTotalValues } from './createProcurementSlice';
import { GeneralTermsPayload, TermPayload } from 'src/logic/models/procurement/general-terms';
import { GeneralTermsSlice } from '../shared/createGeneralTermsSlice';

type ResponseHandlers = {
  onSuccess?: (data: any) => void;
  onError?: () => void;
};

export interface EditPurchaseOrderFromScratch {
  editPurchaseOrderFromScratchSliceProperties: {
    terms: any;
    purchaseOrder: ProcurementFormModel | null;
    products: Product[];
    loadingDetails: boolean;
    summaryDetails: { SummarySubtotal: number; SummaryVat: number; SummaryTotal: number };
    isTermsValid: boolean;
    payment_term: TermPayload | null;
    warranty_term: TermPayload | null;
    delivery_term: TermPayload | null;
    isChangesApplied: boolean;
  };
  editPurchaseOrderFromScratchSliceActions: {
    fetchDetails: (POId: number, handlerAfterFetch?: (data: any) => void) => void;
    setIsLoading: (isLoading: boolean) => void;
    setDetails: (purchaseOrderResponse: ProcurementModelDetails) => void;
    changeProject: (project: GeneralObject) => void;
    rebuildDetails: (purchaseOrder: ProcurementModelDetails, successPopupStatus?: ProcurementPopupStatus) => void;
    setSummaryDetails: (products: ProductTableRow[]) => void;
    fetchTerms: () => void;
    selectGeneralTerms: (terms: GeneralTermsPayload) => void;
    setProductsTableRows: (purchaseOrder: ProcurementFormModel | null) => void;

    handleSelectWarrantyTermForMainProduct: (main_product_id: number, warranty_term: TermPayload) => void;
    changeQuantity: (main_product_id: number, newQuantity: number) => void;
    changePrice: (main_product_id: number, newPrice: number) => void;
    changeAdditionalRequirements: (main_product_id: number, additional_requirements: string) => void;
    removeProduct: (main_product_id: number) => void;
    changeRemark: (main_product_id: number, newRemark: string) => void;
    saveChanges: (responseHandlers: ResponseHandlers) => void;
    updateProductsFromEnvironment: (products: Product[]) => void;
    resetChangesIsApplied: () => void;
  };
}

export const createEditPurchaseOrderFromScratchSlice: StateCreator<EditPurchaseOrderFromScratch> = (set, get, api) => ({
  editPurchaseOrderFromScratchSliceProperties: {
    purchaseOrder: null,
    loadingDetails: false,
    products: [],
    payment_term: { id: null, name: '' },
    warranty_term: { id: null, name: '' },
    delivery_term: { id: null, name: '' },
    terms: null,
    isTermsValid: false,
    summaryDetails: { SummarySubtotal: 0, SummaryVat: 0, SummaryTotal: 0 },
    isChangesApplied: false,
  },
  editPurchaseOrderFromScratchSliceActions: {
    resetChangesIsApplied: () => {
      set(
        produce(draftState => {
          draftState.editPurchaseOrderFromScratchSliceProperties.isChangesApplied = false;
        })
      );
    },
    fetchDetails: (POId: number, handlerAfterFetch?: (data: any) => void) => {
      const po = get().editPurchaseOrderFromScratchSliceProperties.purchaseOrder;
      const samePO = po !== null && po.model?.id == POId;
      if (samePO && get().editPurchaseOrderFromScratchSliceProperties.isChangesApplied) {
        return;
      }

      get().editPurchaseOrderFromScratchSliceActions.setIsLoading(true);
      useGetBuyerPurchaseOrderDetails(POId).then(data => {
        get().editPurchaseOrderFromScratchSliceActions.setDetails(data.data);
        get().editPurchaseOrderFromScratchSliceActions.setIsLoading(false);
        get().editPurchaseOrderFromScratchSliceActions.fetchTerms();

        const formDetails = data.data as ProcurementModelDetails;
        const formProducts = getPurchaseOrderProductsArray(formDetails?.products);
        if (handlerAfterFetch) handlerAfterFetch({ ...formDetails, products: formProducts });
      });
    },
    fetchTerms: () => {
      const generalTermsSlice = get() as GeneralTermsSlice;

      const terms = get().editPurchaseOrderFromScratchSliceProperties.terms;
      const terms_bundle = get().editPurchaseOrderFromScratchSliceProperties.purchaseOrder?.model.terms_bundle;

      if (terms) {
        generalTermsSlice.generalTerms.actions.initializeGeneralTerms(terms, terms_bundle);
        return;
      } 

      generalTermsSlice.generalTerms.actions.setIsLoading(true);
      useGetAllTerms().then(data => {
          const allTerms = data.data
          set(produce(draftState => {
              draftState.editPurchaseOrderFromScratchSliceProperties.terms = allTerms;
          }))
          generalTermsSlice.generalTerms.actions.initializeGeneralTerms(allTerms, terms_bundle);
          generalTermsSlice.generalTerms.actions.setIsLoading(false);
      })
    },
    setIsLoading: (isLoading: boolean) =>
      set(
        produce(draftState => {
          draftState.editPurchaseOrderFromScratchSliceProperties.loadingDetails = isLoading;
        })
      ),
    setDetails: (purchaseOrderResponse: ProcurementModelDetails) => {
      set(
        produce(draftState => {
          draftState.editPurchaseOrderFromScratchSliceProperties.purchaseOrder = buildProcurementFormDetails(
            purchaseOrderResponse,
            'canApprovePurchaseOrder',
            false,
            true
          );
          draftState.editPurchaseOrderFromScratchSliceProperties.isTermsValid =
            purchaseOrderResponse.terms_bundle?.payment_term &&
            purchaseOrderResponse.terms_bundle?.warranty_term &&
            purchaseOrderResponse.terms_bundle?.delivery_term
              ? true
              : false;
          draftState.editPurchaseOrderFromScratchSliceProperties.payment_term = purchaseOrderResponse.terms_bundle
            ?.payment_term ?? { id: null, name: '' };
          draftState.editPurchaseOrderFromScratchSliceProperties.warranty_term = purchaseOrderResponse.terms_bundle
            ?.warranty_term ?? { id: null, name: '' };
          draftState.editPurchaseOrderFromScratchSliceProperties.delivery_term = purchaseOrderResponse.terms_bundle
            ?.delivery_term ?? { id: null, name: '' };
        })
      );
      get().editPurchaseOrderFromScratchSliceActions.setProductsTableRows(
        get().editPurchaseOrderFromScratchSliceProperties.purchaseOrder
      );
      get().setTimeline(purchaseOrderResponse.timeline);
    },
    changeProject: (project: GeneralObject) => {
      const modelAfterUpdateProject = useChangeProject(
        project,
        get().editPurchaseOrderFromScratchSliceProperties.purchaseOrder?.model
      );
      get().editPurchaseOrderFromScratchSliceActions.setDetails(modelAfterUpdateProject);
    },
    setProductsTableRows: (purchaseOrder: ProcurementFormModel | null) => {
      // const generalWarrantyTerm = get().editPurchaseOrderFromScratchSliceProperties?.warranty_term
      const ProductsArray = purchaseOrder?.model.products.map(poProduct => ({
        ...poProduct,
        ...poProduct.product,
        quantity: poProduct.requested_quantity,
        price: poProduct.unit_price,
      }));
      set(
        produce(draftState => {
          draftState.editPurchaseOrderFromScratchSliceProperties.products = ProductsArray;
        })
      );
      get().editPurchaseOrderFromScratchSliceActions.setSummaryDetails(ProductsArray);
    },
    setSummaryDetails: (products: ProductTableRow[]) => {
      const summary = getVATTotalValues(products, 'quantity', 'price');
      set(
        produce(draftState => {
          draftState.editPurchaseOrderFromScratchSliceProperties.summaryDetails.SummarySubtotal = summary.subtotal;
          draftState.editPurchaseOrderFromScratchSliceProperties.summaryDetails.SummaryVat = summary.vat;
          draftState.editPurchaseOrderFromScratchSliceProperties.summaryDetails.SummaryTotal = summary.total;
        })
      );
    },
    rebuildDetails: (purchaseOrder: ProcurementModelDetails) => {
      const actions = get().editPurchaseOrderFromScratchSliceActions;
      actions.setDetails(purchaseOrder);
    },
    selectGeneralTerms: (terms: GeneralTermsPayload) => {
      const { payment_term, warranty_term, delivery_term } = terms;
      const isTermsValid = (payment_term?.id && warranty_term?.id && delivery_term?.id) ? true : false

      set(
        produce(draftState => {
          draftState.editPurchaseOrderFromScratchSliceProperties.payment_term = payment_term ?? { id: null, name: '' };
          draftState.editPurchaseOrderFromScratchSliceProperties.warranty_term = warranty_term ?? {
            id: null,
            name: '',
          };
          draftState.editPurchaseOrderFromScratchSliceProperties.delivery_term = delivery_term ?? {
            id: null,
            name: '',
          };
          draftState.editPurchaseOrderFromScratchSliceProperties.isTermsValid = isTermsValid;
          draftState.editPurchaseOrderFromScratchSliceProperties.checkIfTermsValid = false;
        })
      );
    },
    handleSelectWarrantyTermForMainProduct: (main_product_id: number, warranty_term: TermPayload) => {
      const products = get().editPurchaseOrderFromScratchSliceProperties.products;

      const updatedProducts = products?.map(prod => {
        if (prod.id === main_product_id) {
          return {
            ...prod,
            warranty_term,
          };
        }
        return prod;
      });
      set(
        produce(draftState => {
          draftState.editPurchaseOrderFromScratchSliceProperties.products = updatedProducts;
        })
      );
    },
    changeQuantity: (main_product_id: number, newQuantity: number) => {
      const products = get().editPurchaseOrderFromScratchSliceProperties.products;
      const updatedProducts = products?.map(prod => {
        if (prod.id === main_product_id) {
          return {
            ...prod,
            quantity: newQuantity,
          };
        }
        return prod;
      });
      set(
        produce(draftState => {
          draftState.editPurchaseOrderFromScratchSliceProperties.products = updatedProducts;
        })
      );
      get().editPurchaseOrderFromScratchSliceActions.setSummaryDetails(updatedProducts);
    },
    changePrice: (main_product_id: number, newPrice: number) => {
      const products = get().editPurchaseOrderFromScratchSliceProperties.products;
      const updatedProducts = products?.map(prod => {
        if (prod.id === main_product_id) {
          return {
            ...prod,
            price: newPrice,
          };
        }
        return prod;
      });
      set(
        produce(draftState => {
          draftState.editPurchaseOrderFromScratchSliceProperties.products = updatedProducts;
        })
      );
      get().editPurchaseOrderFromScratchSliceActions.setSummaryDetails(updatedProducts);
    },
    changeAdditionalRequirements: (main_product_id: number, additional_requirements: string) => {
      const products = get().editPurchaseOrderFromScratchSliceProperties.products;
      const updatedProducts = products?.map(prod => {
        if (prod.id === main_product_id) {
          return {
            ...prod,
            additional_requirements: additional_requirements,
          };
        }
        return prod;
      });
      set(
        produce(draftState => {
          draftState.editPurchaseOrderFromScratchSliceProperties.products = updatedProducts;
        })
      );
      get().editPurchaseOrderFromScratchSliceActions.setSummaryDetails(updatedProducts);
    },
    removeProduct: (main_product_id: number) => {
      const products = get().editPurchaseOrderFromScratchSliceProperties.products;
      const updatedProducts = products?.filter(prod => prod.id !== main_product_id);
      set(
        produce(draftState => {
          draftState.editPurchaseOrderFromScratchSliceProperties.products = updatedProducts;
        })
      );
      get().editPurchaseOrderFromScratchSliceActions.setSummaryDetails(updatedProducts);
    },
    changeRemark: (main_product_id: number, newRemark: string) => {
      const products = get().editPurchaseOrderFromScratchSliceProperties.products;
      const updatedProducts = products?.map(prod => {
        if (prod.id === main_product_id) {
          return {
            ...prod,
            remarks: newRemark,
          };
        }
        return prod;
      });
      set(
        produce(draftState => {
          draftState.editPurchaseOrderFromScratchSliceProperties.products = updatedProducts;
        })
      );
      get().editPurchaseOrderFromScratchSliceActions.setSummaryDetails(updatedProducts);
    },
    saveChanges: ({ onSuccess, onError }: ResponseHandlers) => {
      const poId = get().editPurchaseOrderFromScratchSliceProperties.purchaseOrder?.model?.id;

      const productsToSave = get().editPurchaseOrderFromScratchSliceProperties.products.map(product => ({
        id: product.id,
        quantity: product.quantity,
        price: product.price,
        warranty_term_id: product.warranty_term?.id ?? null,
        warranty_term_custom_value: product.warranty_term?.value ?? null,
        remarks: product.remarks ?? null,
        additional_requirements: product.additional_requirements ?? null,
      }));

      const payment_term = get().editPurchaseOrderFromScratchSliceProperties.payment_term;
      const warranty_term = get().editPurchaseOrderFromScratchSliceProperties.warranty_term;
      const delivery_term = get().editPurchaseOrderFromScratchSliceProperties.delivery_term;

      useUpdatePurchaseOrderFromScratch(poId, {
        payment_term,
        warranty_term,
        delivery_term,
        products: productsToSave,
      })
        .then(data => {
          onSuccess?.(data.data);
          get().editPurchaseOrderFromScratchSliceActions.resetChangesIsApplied();
        })
        .catch(() => {
          onError?.();
        });
    },
    updateProductsFromEnvironment: (products: Product[]) => {
      set(
        produce(draftState => {
          draftState.editPurchaseOrderFromScratchSliceProperties.products = products;
          draftState.editPurchaseOrderFromScratchSliceProperties.isChangesApplied = true;
        })
      );
      get().editPurchaseOrderFromScratchSliceActions.setSummaryDetails(products);
    },
  },
});

export const getPurchaseOrderProductsArray = (products: Product[]) => {
  return products.map(product => {
    return { ...product, ...product.product, quantity: product.requested_quantity, price: product.unit_price };
  });
};
