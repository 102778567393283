import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PriceCatalogueIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_38252_134717)">
            <path d="M11.0275 0.875001H7.6661C7.5616 0.874925 7.46133 0.916214 7.38719 0.989845L0.667189 7.7082C0.520083 7.85603 0.4375 8.0561 0.4375 8.26465C0.4375 8.4732 0.520083 8.67327 0.667189 8.82109L3.86641 12.0203C4.01428 12.1674 4.21439 12.25 4.42299 12.25C4.63159 12.25 4.8317 12.1674 4.97957 12.0203L11.6977 5.30469C11.7713 5.23055 11.8126 5.13027 11.8125 5.02578V1.6625C11.813 1.55915 11.7931 1.45672 11.7538 1.36111C11.7146 1.2655 11.6568 1.17859 11.5838 1.1054C11.5109 1.03221 11.4242 0.974166 11.3287 0.934625C11.2332 0.895083 11.1308 0.87482 11.0275 0.875001Z" stroke="#8B8D98" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.625 3.9375C9.45194 3.9375 9.28277 3.88618 9.13888 3.79004C8.99498 3.69389 8.88283 3.55723 8.81661 3.39735C8.75038 3.23746 8.73305 3.06153 8.76681 2.8918C8.80058 2.72206 8.88391 2.56615 9.00628 2.44378C9.12865 2.32141 9.28456 2.23808 9.4543 2.20431C9.62403 2.17055 9.79996 2.18788 9.95985 2.25411C10.1197 2.32033 10.2564 2.43248 10.3525 2.57638C10.4487 2.72027 10.5 2.88944 10.5 3.0625C10.5 3.29456 10.4078 3.51712 10.2437 3.68122C10.0796 3.84531 9.85707 3.9375 9.625 3.9375Z" fill="#8B8D98" />
            <path d="M6.28906 13.125L13.4531 5.96094C13.4887 5.92501 13.5168 5.88229 13.5356 5.83532C13.5544 5.78835 13.5635 5.73808 13.5625 5.6875V2.1875" stroke="#8B8D98" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_38252_134717">
                <rect width="14" height="14" fill="white" />
            </clipPath>
        </defs>
    </SvgIcon>
);

export default PriceCatalogueIcon;
