import { Loading } from '@/components';
import { Box, Grid, Skeleton } from '@mui/material';
import { SkeletonFormType } from '../QueryStatusComponent';

export const SkeletonForm = ({ type }: { type: SkeletonFormType }) => {
  return (
    <>
      {type == SkeletonFormType.TABLE ? (
        <Grid container md={12} sx={{ bgcolor: 'background.primary.main' }}>
          <Box sx={{ width: '100%', backgroundColor: 'white', borderRadius: '8px' }}>
            <Grid container md={12} px={2}>
              <Grid item container md={12} alignItems='center' justifyContent='space-between' py={1}>
                <Skeleton variant='text' width='20%' height={40} />
                <Skeleton variant='rectangular' width={'4%'} />
              </Grid>
            </Grid>
          </Box>
          <Skeleton variant='rectangular' width='100%' height={100} sx={{ my: 3, borderRadius: '8px' }} />
          <Skeleton variant='rectangular' width='100%' height={20} sx={{ mt: 3, mb: 0.5, borderRadius: '5px' }} />
          <SkeletonBoxes count={10} />
        </Grid>
      ) : (
        <Loading fullHeight={true} />
      )}
    </>
  );
};

const SkeletonBoxes: React.FC<{ count: number }> = ({ count }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <Box key={index} sx={{ width: '100%', backgroundColor: 'white', p: 1, borderRadius: '8px', my: 1 }}>
          <Grid container md={12} px={2}>
            <Skeleton variant='text' width='100%' height={40} />
          </Grid>
        </Box>
      ))}
    </>
  );
};
