import { apiRoutes } from "@/config"
import { request } from "@/helpers"
import { GeneralObject, ProcurementFormModel, ProcurementModelDetails, Product } from "@/models";
import { buildFormProductsTable } from "..";
import { GeneralTermsPayload } from "src/logic/models/procurement/general-terms";

export const buildPurchaseOrderProductsTable = (purchaseOrder: ProcurementFormModel | null, warrantyTerm?: GeneralObject | null) => {
    return buildFormProductsTable(purchaseOrder?.model?.products, warrantyTerm)
}

export const useUpdatePurchaseOrderFromScratch = (
  poId: number,
  { payment_term, warranty_term, delivery_term, products }
) => {
  return request({
    url: `/buyer/purchase_order/${poId}/purchase-order-from-scratch`,
    method: 'PUT',
    data: {
      payment_term_id: payment_term.id,
      payment_term_custom_value: payment_term.value,
      warranty_term_id: warranty_term.id,
      warranty_term_custom_value: warranty_term.value,
      delivery_term_id: delivery_term.id,
      delivery_term_custom_value: delivery_term.value,
      products,
    },
  });
};

export const useSavePurchaseOrderDetails = (purchaseOrder: ProcurementModelDetails, products: Product[]) => {
    const newProductsArray = products.map(({ id, price, quantity }) => ({ id, price, quantity }));

    return request({ url: apiRoutes.buyerPurchaseOrder + "/" + purchaseOrder.id + apiRoutes.updateRequisitionProducts, method: "POST", data: { products: newProductsArray } })
}
export const useGetBuyerPurchaseOrderDetails = (POId: number) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.show + POId, method: "GET" })
}
export const useFetchRejectPurchaseOrderReasons = () => {
    return request({ url: apiRoutes.buyerPurchaseOrder + '/' + apiRoutes.rejectReasons, method: "GET" })
}
export const useSendPurchaseOrderForApproval = (POId: number, comment?: string) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.sendToApprovers + POId, method: "POST", data: { comment: comment } })
}

export const useApprovePurchaseOrder = (POId: number, comment?: string) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.approve + '/' + POId, method: "POST", data: { comment: comment } })
}

export const useRejectPurchaseOrder = (POId: number, reasonId: string, comment?: string) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.reject + '/' + POId, method: "POST", data: { reason_id: reasonId, comment: comment } })
}

export const useIndexBuyerPurchaseOrders = () => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.index, method: "GET" })
}

export const useIndexBuyerPurchaseOrdersRelatedData = () => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.allData, method: "GET" })
}

export const useCreateEmptyPO = (projectId: string, projectName: string, sellerId: string) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.createIndividual, method: "POST", data: { project_id: projectId, project_name: projectName, seller_id: sellerId } })
}

export const useCancelPurchaseOrder = (POId: number, comment: string) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.cancelApproval + '/' + POId, method: "POST", data: { comment: comment } })
}

export const useSendSinglePoToSeller = (POId: number | undefined, date: string, optionalComment: string | null) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + '/' + POId + apiRoutes.sendToSeller, method: "POST", data: { validity_date: date, comment: optionalComment ?? null } })
}

export const useRecallSinglePO = (POId: number | undefined, comment: string, reasonId: string | undefined) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + '/' + POId + apiRoutes.recall, method: "POST", data: { reason_id: reasonId, comment: comment } })
}

export const useSelectTerms = (POId: number | undefined, general_terms: GeneralTermsPayload) => {
  return request({
    url: apiRoutes.buyerPurchaseOrder + '/' + POId + apiRoutes.selectTerms,
    method: 'POST',
    data: {
      payment_term_id: general_terms.payment_term.id,
      payment_term_custom_value: general_terms.payment_term.value,
      warranty_term_id: general_terms.warranty_term.id,
      warranty_term_custom_value: general_terms.warranty_term.value,
      delivery_term_id: general_terms.delivery_term.id,
      delivery_term_custom_value: general_terms.delivery_term.value,
    },
  });
};

export const useGetAllTerms = () => {
    return request({ url: apiRoutes.allTerms, method: "GET" })
}

export const useSelectPurchaseOrder = (values: { purchase_order_id: string, product_id: string, quantity: string, price: string, seller_id: string, projectId: string, projectName: string }) => {
    Object.keys(values).forEach((item) => {
        if (values[item] === 0 || values[item] === '') {
            values[item] = null
        }
    })
    const mewObj = { purchase_order_id: values.purchase_order_id, product_id: values.product_id, quantity: values.quantity, price: values.price, seller_id: values.seller_id, project_id: values.projectId, project_name: values.projectName }
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.addProductToRequisition, method: "POST", data: { ...mewObj } })
}
export const useRemoveProduct = (POId: number | undefined, productId: number) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + '/' + POId + apiRoutes.removeProduct, method: "POST", data: { product_id: productId } })
}

export const useUpdateDetailsProduct = (POId: number | undefined, productId: number | undefined, quantity: number | undefined, price: number | undefined, warrantyTermId: number | undefined, warrantyCustomValue: number | undefined, remarks: string | undefined, additional_requirements: string | undefined | null) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + '/' + POId + apiRoutes.updateDetailsProduct, method: "POST", data: { product_id: productId, quantity: quantity, price: price, warranty_term_id: warrantyTermId, warranty_term_custom_value: warrantyCustomValue, remarks: remarks, additional_requirements } })
}

export const useGetIndvidual = (productId: string, sellerId?: string) => {
    // let formData = new FormData;
    // formData.append('product_id', `${productId}`);
    // formData.append('seller_id', `${sellerId ?? null}`);
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.individualPurchaseOrders, method: "GET", params: { product_id: productId, seller_id: sellerId ?? null } })
}

export const useDuplicateSinglePo = async (POId: number | undefined, project: any) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + '/' + POId + apiRoutes.duplicate, method: "POST", data: { project_id: project.projectId, new_project_name: project.projectName ?? null } })
}

export const useDeleteSinglePo = (POId: number) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + '/' + POId, method: "DELETE" })
}