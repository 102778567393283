import { AuthenticateStatus, ProductManagerEnv } from '@/config';
import { useProcurementStore, useUserData } from '@/zustand';
import { Construction, Description, ShoppingCart } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { sm } from 'src/site-map';
import { TotalSection } from 'src/ui/components/dumb/shared';
import { ProductRow } from '../../general-components/form-summary-dialog/product-row';
import Link from 'next/link';
import CloseIcon from '@mui/icons-material/Close';
import { ProductFormManagerPopper } from '../toggle-forms-popper';
// import { formatNumber } from 'src/logic/utils/numbers';
// import { CircularProgress } from '@mui/material';
// import ErrorIcon from '@mui/icons-material/Error';
import { useRouter } from 'next/router';

import SaveCancelEditRfqActions from '../../edit-rfq/SaveCancelEditRfqActions';
import SaveCancelEditQuoteActions from '../../edit-quote-from-scratch/SaveCancelEditQuoteActions';
import SaveCancelEditPoActions from '../../edit-po-from-scratch/SaveCancelEditPoActions';
import { getPathWithoutQueryParams } from 'src/logic/utils/url';
import ConstructionIcon from '@mui/icons-material/Construction';
import useNumberOfEnviornmentItems from './use-number-of-items';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';

const useFormDetailsUrlFromEnv = (env: ProductManagerEnv, formId: number): string => {
  const isGuest = useUserData().authenticationStatus === AuthenticateStatus.NOT_AUTHENTICATED;

  return React.useMemo(() => {
    const formIdAsString = `${formId!}`;
    if (env === ProductManagerEnv.purchase_order) {
      return sm.portals.buyer.procurement.purchaseOrders.purchaseOrderId(formIdAsString).url;
    }
    if (env === ProductManagerEnv.requisition) {
      if (isGuest) {
        return sm.requisitions.requisition(formIdAsString).url;
      }
      return sm.portals.buyer.procurement.requisitions.requisition(formIdAsString).url;
    }
    if (env === ProductManagerEnv.quote) {
      return sm.portals.seller.procurement.quotes.quoteId(formIdAsString).url;
    }
    if (env === ProductManagerEnv.catalog) {
      return sm.portals.seller.products.sellerProductList(formIdAsString).url;
    }

    return '';
  }, [formId, env, isGuest]);
};

type props = {
  canEditForm: boolean;
  redirectToProductPage: () => void;
};
export const SummaryContent: React.FC<props> = ({ canEditForm, redirectToProductPage }) => {
  const isGuest = useUserData().authenticationStatus === AuthenticateStatus.NOT_AUTHENTICATED;

  const { data, actions } = useProcurementStore(state => state.productFormManagerProperties);
  const env = data.environment?.type;
  const detailsURL = useFormDetailsUrlFromEnv(env!, data.formDetails?.id!);

  const router = useRouter();
  const closeEnvironment = () => {
    actions.reset();
    // router.push(detailsURL);
  };

  // const { savingStatus } = data;

  const { totalNumber, productsNumber, servicesNumber } = useNumberOfEnviornmentItems();

  const isPurchaseOrderEnv = env === ProductManagerEnv.purchase_order;
  const isRequisitionEnv = env === ProductManagerEnv.requisition;
  const isCatalogEnv = env === ProductManagerEnv.catalog;
  const isQuoteEnv = env === ProductManagerEnv.quote;
  const isEditBundleRfqEnv = env === ProductManagerEnv.edit_bundle_rfq;
  const isEditQuoteFromScratchEnv = env === ProductManagerEnv.edit_quote_from_scratch;
  const isEditPoFromScratchEnv = env === ProductManagerEnv.edit_po_from_scratch;

  const showCloseButton = !isEditBundleRfqEnv && !isEditQuoteFromScratchEnv && !isEditPoFromScratchEnv;
  const showOrderSummary = isPurchaseOrderEnv || isQuoteEnv || isEditQuoteFromScratchEnv || isEditPoFromScratchEnv;
  const disableToggle = isGuest || isEditBundleRfqEnv || isEditQuoteFromScratchEnv || isEditPoFromScratchEnv;
  const showGoToDetailsLink = !isEditBundleRfqEnv && !isEditQuoteFromScratchEnv && !isEditPoFromScratchEnv;

  // const isAnyProductSaving = data.loadingProductsIds.length > 0;

  const showViewCategoriesButton = React.useMemo(() => {
    const currentPath = getPathWithoutQueryParams(router.asPath);
    return currentPath !== sm.catalogue.index.url && totalNumber === 0 && !isPurchaseOrderEnv && !isEditPoFromScratchEnv;
  }, [router, totalNumber]);

  return (
    <>
      <Box
        // container
        p={2}
        height='100%'
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '.5rem',
        }}
      >
        {showCloseButton && (
          <CloseIcon
            onClick={closeEnvironment}
            style={{
              position: 'absolute',
              top: '.5rem',
              right: '.5rem',
              cursor: 'pointer',
            }}
            fontSize='small'
          />
        )}
        <div>
          {isEditBundleRfqEnv && <SaveCancelEditRfqActions />}
          {isEditQuoteFromScratchEnv && <SaveCancelEditQuoteActions />}
          {isEditPoFromScratchEnv && <SaveCancelEditPoActions />}

          <Grid container md={12} mb={1}>
            <Grid item md={2}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'primary.light',
                  width: '40px',
                  height: '40px',
                  borderRadius: '4px',
                }}
              >
                {isPurchaseOrderEnv ? <ShoppingCart /> : isCatalogEnv ? <Construction /> : <Description />}
              </Box>
            </Grid>
            <Grid item md={10}>
              <Typography color='#3A3E50' fontWeight={400} fontSize={14} lineHeight={1} mb={0.25}>
                Adding items to
              </Typography>
              <ProductFormManagerPopper disableToggle={disableToggle} />
            </Grid>
          </Grid>
        </div>

        {totalNumber == 0 && (
          <Grid height='100%' container alignItems='center'>
            <Grid item md={12} textAlign='center'>
              <ConstructionIcon color='primary' />
              <Typography color={'#8B8D98'} fontWeight={400} fontSize={14}>
                {totalNumber} Products & Services
              </Typography>
              {isPurchaseOrderEnv && data.formDetails?.seller_details && (
                <Typography color={'#8B8D98'} fontWeight={400} fontSize={14} lineHeight={1.25}>
                  <span
                    className='cursor-pointer underline-on-hover'
                    onClick={() =>
                      router.push(sm.catalogue.sellers.sellerId(`${data?.formDetails?.seller_details.id}`).url)
                    }
                  >
                    {data.formDetails?.seller_details.name}
                  </span>{' '}
                  {data.formDetails?.seller_details?.is_private && <PrivacyButton disableText />}
                </Typography>
              )}
              {isQuoteEnv && data.formDetails?.buyer_details && (
                <Typography color={'#3A3E50'} fontWeight={400} fontSize={14} lineHeight={1.25}>
                  {data.formDetails?.buyer_details.name}{' '}
                  {data.formDetails?.buyer_details?.is_private && <PrivacyButton disableText />}
                </Typography>
              )}

              <br />
              <Typography variant='body2' color='#3A3E50' pb={4}>
                {t('texts.EMPTY_' + env)}
              </Typography>
              <div
                style={{
                  display: 'flex',
                  gap: '.5rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                }}
              >
                {showViewCategoriesButton && (
                  <Button onClick={redirectToProductPage} type='button' color='primary' variant='contained'>
                    View Catalogue
                  </Button>
                )}
                {showGoToDetailsLink && (
                  <Link href={detailsURL}>
                    <Button type='button' color='primary' variant='text'>
                      Go to {t('texts.' + env)}
                    </Button>
                  </Link>
                )}
              </div>
            </Grid>
          </Grid>
        )}
        {totalNumber != 0 && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                {isPurchaseOrderEnv && data.formDetails?.seller_details && (
                  <Typography color={'#3A3E50'} fontWeight={400} fontSize={14} lineHeight={1.25}>
                    <span
                      className='cursor-pointer underline-on-hover'
                      onClick={() =>
                        router.push(sm.catalogue.sellers.sellerId(`${data?.formDetails?.seller_details.id}`).url)
                      }
                    >
                      {data.formDetails?.seller_details.name}
                    </span>{' '}
                    {data.formDetails?.seller_details?.is_private && <PrivacyButton disableText />}
                  </Typography>
                )}
                {isQuoteEnv && data.formDetails?.buyer_details && (
                  <Typography color={'#3A3E50'} fontWeight={400} fontSize={14} lineHeight={1.25}>
                    {data.formDetails?.buyer_details.name}{' '}
                    {data.formDetails?.buyer_details?.is_private && <PrivacyButton disableText />}
                  </Typography>
                )}
                <Typography color={'#8B8D98'} fontWeight={400} fontSize={14}>
                  {productsNumber} Product{productsNumber !== 1 && 's'}, {servicesNumber} Service
                  {servicesNumber !== 1 && 's'}
                </Typography>
              </div>
              <div style={{ paddingInlineEnd: '1rem' }}>
                {/* {savingStatus === 'loading' && <CircularProgress size={16} />} */}
                {/* {isAnyProductSaving && <CircularProgress size={16} />}
                {savingStatus === 'error' && (
                  <ErrorIcon fontSize='small' sx={{ color: 'red' }} />
                )} */}
                {/* {savingStatus === 'success' && 'Y'} */}
              </div>
            </div>
            <Box className='styled-scrollbar' style={{ flexGrow: 1, overflowY: 'auto' }} width='100%' paddingRight={2}>
              {data.products.map(product => (
                <Grid container px={0.5} py={1}>
                  <ProductRow
                    key={product.id}
                    product={product}
                    formDetails={data.formDetails}
                    canEditForm={canEditForm}
                  />
                </Grid>
              ))}
            </Box>
            {showGoToDetailsLink && (
              <Link href={detailsURL}>
                <Button type='button' color='primary' variant='contained'>
                  Go to {t('texts.' + env)}
                </Button>
              </Link>
            )}
          </>
        )}
        {showOrderSummary && (
          <div>
            <TotalSection
              grayBackground
              fullWidth
              title={t('texts.order_summary')}
              position={'end'}
              subtotalValue={data.summaryDetails?.SummarySubtotal}
              vatValue={data.summaryDetails?.SummaryVat}
              totalValue={data.summaryDetails?.SummaryTotal}
            />
          </div>
        )}
      </Box>
    </>
  );
};
