import React from 'react';

export const ShapesSVG = ({ color = '#3A3E50' }) => (

    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <g clip-path="url(#clip0_38994_260263)">
            <path d="M7.25484 5.31863C8.00845 4.89408 8.88088 4.72948 9.73737 4.85028C10.5939 4.97107 11.3867 5.37053 11.9935 5.98694C12.6003 6.60335 12.9873 7.40242 13.0946 8.2607C13.2019 9.11899 13.0236 9.98873 12.5872 10.7356C12.1509 11.4824 11.4808 12.0648 10.6804 12.3927C9.87999 12.7207 8.99389 12.7759 8.15895 12.55C7.32402 12.324 6.58672 11.8295 6.06094 11.1426C5.53517 10.4558 5.25018 9.61497 5.25 8.75M9.1875 8.75H0.875L5.03125 1.3125L9.1875 8.75Z" stroke={color} stroke-linejoin="round" />
            <path d="M7.25484 5.31863C8.00845 4.89408 8.88088 4.72948 9.73737 4.85028C10.5939 4.97107 11.3867 5.37053 11.9935 5.98694C12.6003 6.60335 12.9873 7.40242 13.0946 8.2607C13.2019 9.11899 13.0236 9.98873 12.5872 10.7356C12.1509 11.4824 11.4808 12.0648 10.6804 12.3927C9.87999 12.7207 8.99389 12.7759 8.15895 12.55C7.32402 12.324 6.58672 11.8295 6.06094 11.1426C5.53517 10.4558 5.25018 9.61497 5.25 8.75M9.1875 8.75H0.875L5.03125 1.3125L9.1875 8.75Z" stroke={color} stroke-opacity="0.2" stroke-linejoin="round" />
            <path d="M7.25484 5.31863C8.00845 4.89408 8.88088 4.72948 9.73737 4.85028C10.5939 4.97107 11.3867 5.37053 11.9935 5.98694C12.6003 6.60335 12.9873 7.40242 13.0946 8.2607C13.2019 9.11899 13.0236 9.98873 12.5872 10.7356C12.1509 11.4824 11.4808 12.0648 10.6804 12.3927C9.87999 12.7207 8.99389 12.7759 8.15895 12.55C7.32402 12.324 6.58672 11.8295 6.06094 11.1426C5.53517 10.4558 5.25018 9.61497 5.25 8.75M9.1875 8.75H0.875L5.03125 1.3125L9.1875 8.75Z" stroke={color} stroke-opacity="0.2" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_38994_260263">
                <rect width="14" height="14" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
