import React from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import {
  GeneralTermItem,
  RawGeneralTermItem,
  SingleSelectTermItem,
  SliderTermItem,
  TermPayload,
  TextFieldTermItem,
} from 'src/logic/models/procurement/general-terms';
import { GeneralTermsSlice } from 'src/logic/zustand/procurement/shared/createGeneralTermsSlice';
import SelectTerms from '../editable/SelectTerms';

type IProps = {
  generalTermsSlice: GeneralTermsSlice;
  value: TermPayload | undefined;
  handleChange: (warrantyTerm: TermPayload) => void;
  items: RawGeneralTermItem[];
  label?: string | React.ReactNode;
};

const SelectCustomWarranty = ({ value, items, handleChange, generalTermsSlice, label = 'Warranty' }: IProps) => {
  const popup = usePopup();
  const {
    transformRawTerms,
    getItemsToRender,
    getItemsBasedOnSelection,
    changeSelectedOption,
    changeSliderValue,
    changeTextFieldValue,
    getSelectedNode,
    transformToTermPayload,
    isTermsValid,
    getTermsLabel,
  } = generalTermsSlice.generalTerms.helpers;

  const isOpen = popup.isOpen;

  const [draftTerms, setDraftTerms] = React.useState<GeneralTermItem[]>([]);
  const onOpen = () => {
    popup.handleOpen();

    const terms = transformRawTerms(items);
    if (value) {
      const rawValue = items.find(it => it.id === value.id);
      if (rawValue) {
        setDraftTerms(getItemsBasedOnSelection(terms, { ...rawValue, value: value.value }));
        return;
      }
    }
    setDraftTerms(terms);
  };

  const onClose = () => {
    popup.handleClose();
    setDraftTerms([]);
  };

  const onOptionChange = (item: SingleSelectTermItem, newValue: number | null) => {
    setDraftTerms(prevState => changeSelectedOption(prevState, item, newValue));
  };
  const onSliderValueChange = (item: SliderTermItem, newValue: number) => {
    setDraftTerms(prevState => changeSliderValue(prevState, item, newValue));
  };
  const onTextFieldValueChange = (item: TextFieldTermItem, newValue: string) => {
    setDraftTerms(prevState => changeTextFieldValue(prevState, item, newValue));
  };

  const canSave = React.useMemo(() => {
    if (!isOpen) return false;
    return isTermsValid(draftTerms);
  }, [isOpen, draftTerms]);

  const handleSave = () => {
    const selectedNode = getSelectedNode(draftTerms);
    if (selectedNode && canSave) {
      const selectedTerm = transformToTermPayload(selectedNode);
      handleChange({ ...selectedTerm, name: getTermsLabel(draftTerms) });
      return true;
    }
    return false;
  };

  const termsToRender = React.useMemo(() => {
    if (!isOpen) return []; // For optimization
    return getItemsToRender(draftTerms);
  }, [isOpen, draftTerms]);

  return (
    <SelectTerms
      label={label}
      selectedValueLabel={value?.name ?? ''}
      onOptionChange={onOptionChange}
      onSliderValueChange={onSliderValueChange}
      onTextFieldValueChange={onTextFieldValueChange}
      terms={termsToRender}
      onSave={handleSave}
      onPopupOpen={onOpen}
      onPopupClose={onClose}
      canSave={canSave}
      disabled={items.length === 0}
    />
  );
};

export default SelectCustomWarranty;
