import { Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import React from 'react';
import { SliderTermItem } from 'src/logic/models/procurement/general-terms';

type IProps = {
  item: SliderTermItem;
  onChange: (item: SliderTermItem, newValue: number) => void;
};

const SliderTermComponent = ({ item, onChange }: IProps) => {
  const { min, max, step } = item.meta;
  const marks = React.useMemo(() => {
    const marks = [];
    for (let i = min; i <= max; i += step) {
      marks.push({ value: i, label: `${i}` });
    }
    return marks;
  }, [min, max, step]);

  const handleChange = (_event: Event, newValue: number | number[]) => {
    onChange(item, newValue as number);
  };

  return (
    <div style={{ marginBlock: '1rem' }}>
      <Typography color='#3A3E50' fontSize={13} fontWeight={400} mb={1}>
        {item.name}
      </Typography>

      <div style={{ paddingInline: '0.5rem', width: 'min(90vw, 375px)' }}>
        <Slider
          defaultValue={typeof item.value === 'string' ? parseInt(item.value) : item.value}
          step={item.meta.step}
          min={item.meta.min}
          max={item.meta.max}
          onChange={handleChange}
          marks={marks}
          valueLabelDisplay='auto'
          sx={{
            '& .MuiSlider-mark': {
              color: 'transparent',
            },
            '& .MuiSlider-markLabel ': {
              fontSize: 11,
              color: '#8B8D98',
              fontWeight: 400,
            },
            '& .MuiSlider-markActive ': {
              backgroundColor: 'transparent',
            },
          }}
        />
      </div>
    </div>
  );
};

export default SliderTermComponent;
