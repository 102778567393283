import { useRouter } from 'next/router';
import { useMemo } from 'react';

const useFullWidth = () => {
    const router = useRouter();
    const currentPath = router.pathname;

    const fullWidthRoutes = [
        '/',
        '/account-management',
        '/portal/buyer/users',
        '/portal/seller/users',
        '/careers',
        '/become-a-seller',
        '/contact-us',
    ];

    const isFullWidth = useMemo(() => {
        return fullWidthRoutes.includes(currentPath);
    }, [currentPath]);

    return isFullWidth;
};

export default useFullWidth;
