import { Button, Typography } from '@mui/material';
import { SingleSelectTermItem } from 'src/logic/models/procurement/general-terms';

type IProps = {
  item: SingleSelectTermItem;
  onChange: (item: SingleSelectTermItem, newOption: number | null) => void;
};

const SingleSelectTermComponent = ({ item, onChange }: IProps) => {
  return (
    <div style={{ marginBlock: '1rem' }}>
      <Typography color='#3A3E50' fontSize={13} fontWeight={400} mb={1}>
        {item.identifier}
      </Typography>

      <div style={{ display: 'flex', gap: '.5rem', flexWrap: 'wrap' }}>
        {item.options.map(option => (
          <Button
            sx={{
              fontSize: 12,
              paddingX: 1,
              paddingY: 0.35,
              height: 'auto',
            }}
            type='button'
            variant={item.selected_option === option.id ? 'contained' : 'outlined'}
            onClick={() => {
              if (item.selected_option !== option.id) {
                onChange(item, option.id);
              }
            }}
          >
            {option.name}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default SingleSelectTermComponent;
