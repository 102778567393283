import { DescPopup } from "@/components";
import { ColumnFormat, ColumnType, TableColumnMapper, TableMapperType } from "@/models";
import { Typography } from "@mui/material";
import { t } from "i18next";
import usePopup from "src/logic/hooks/use-popup";
import ProductQuickView from "src/ui/components/smart/product/product-quick-view";

export const getProductsTableMapper = (type: TableMapperType | null) => {
    switch (type) {
        case TableMapperType.quote_seller_products:
            return QuoteProductsSellerTableMapper();
        default:
            return null;
    }
};

export const getProductsSlicePropertiesNames = (tableType: TableMapperType | null) => {
    let slicePropertiesName: { actions: string, properties: string } | undefined;

    if (tableType === TableMapperType.quote_seller_products) { slicePropertiesName = { actions: 'quoteDetailsSliceActions', properties: 'quoteDetailsSliceProperties' } }
    return slicePropertiesName;
};


export const QuoteProductsSellerTableMapper = () => {
    const segmentColumn = new TableColumnMapper('product.category_kind', 'segment', ColumnType.SEGMENT)

    const imageColumn = new TableColumnMapper('image_url', '', ColumnType.IMAGE)

    // const subtitleColumn = new TableColumnMapper('part_number', '', ColumnType.STRING, undefined, undefined, false, (data: any) => t('texts.article_no') + data.part_number)
    // const nameColumn = new TableColumnMapper('name', 'product_details', ColumnType.LONG, ColumnFormat.SUBTITLE, subtitleColumn, false, (data: any) => <ClickableProductWithDetails product={data} />)

    const productDetailsColumn = new TableColumnMapper('', 'name', ColumnType.PRODUCT_DETAILS);

    // const brandImageColumn = new TableColumnMapper('brand.image_url', 'brand', ColumnType.IMAGE)

    // const warrantySubtitleColumn = new TableColumnMapper('warranty_term.id', '', ColumnType.STRING, undefined, undefined, false, (data: any) => data.isCustomWarranty ? t('texts.custom') : '')
    const warrantyColumn = new TableColumnMapper('warranty_term', 'warranty_term', ColumnType.SELECT, ColumnFormat.SUBTITLE)

    const requestedQuantityColumn = new TableColumnMapper('requested_quantity', 'requested', ColumnType.NUMBER)

    const quantityColumn = new TableColumnMapper('quantity', 'available', ColumnType.COUNTER)

    const inputColumn = new TableColumnMapper('price', 'unit_price', ColumnType.INPUT)

    const remarkColumn = new TableColumnMapper('remarks', 'remarks', ColumnType.REMARK)

    const deleteColumn = new TableColumnMapper('price', '', ColumnType.DELETE)


    return [
        segmentColumn,
        imageColumn,
        productDetailsColumn,
        // brandImageColumn,
        warrantyColumn,
        requestedQuantityColumn,
        quantityColumn,
        inputColumn,
        remarkColumn,
        deleteColumn
    ];
}

function ClickableProductWithDetails ({ product }) {
    const detailsPopup = usePopup();

    return <>
        <DescPopup
            big
            open={detailsPopup.isOpen}
            handleClose={detailsPopup.handleClose}
            paperSx={{ borderRadius: 0 }}
        >
            <ProductQuickView product={product} />
        </DescPopup>
        <Typography variant='subtitle2' fontWeight={400} onClick={(event) => { event.stopPropagation(); detailsPopup.handleOpen(); }} sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}> {product.name}</Typography>
    </>
}