import { apiRoutes, procurementStatus, UserPortalPermission } from "@/config"
import { request } from "@/helpers"
import { Product } from "@/models"
import { useUserData } from "@/zustand"
import { GeneralTermsPayload } from "src/logic/models/procurement/general-terms"
import { Quote } from "src/logic/models/quote"

export const useGetQuoteDetails = (quoteId: number) => {
    return request({ url: apiRoutes.apiQuote + 'show/' + quoteId, method: "GET" })
}

export const useUpdateQuoteFromScratch = (
  quoteId: number,
  { general_terms, products }: { general_terms: GeneralTermsPayload; products: any }
) => {
  return request({
    url: `/seller/quote/${quoteId}/update-quote-from-scratch`,
    method: 'PUT',
    data: {
      payment_term_id: general_terms.payment_term.id,
      payment_term_custom_value: general_terms.payment_term.value,
      warranty_term_id: general_terms.warranty_term.id,
      warranty_term_custom_value: general_terms.warranty_term.value,
      delivery_term_id: general_terms.delivery_term.id,
      delivery_term_custom_value: general_terms.delivery_term.value,
      products,
    },
  });
};

export const useSendQuoteForApproval = (quoteId: number, optionalComment: string | null) => {
    return request({ url: apiRoutes.apiQuote + quoteId + "/sendToApprovers", method: "POST", data: { comment: optionalComment ?? null } })
}

export const useApproveQuote = (quoteId: number, optionalComment: string | null) => {
    return request({ url: apiRoutes.apiQuote + quoteId + apiRoutes.approve, method: "POST", data: { comment: optionalComment ?? null } })
}

export const useRejectQuote = (quoteId: number, optionalComment: string | null, reasonId: string | number) => {
    return request({ url: apiRoutes.apiQuote + quoteId + apiRoutes.reject, method: "POST", data: { comment: optionalComment ?? null, reason_id: reasonId } })
}

export const useCancelQuoteToApprovers = (quoteId: number) => {
    return request({ url: apiRoutes.apiQuote + quoteId + apiRoutes.cancelQuoteToApprovals, method: "POST" })
}

export const useDeleteQuote = (quoteId: number) => {
    return request({ url: apiRoutes.apiQuote + quoteId, method: "DELETE" })
}

export const useCancelQuoteToBuyer = (quoteId: number, reasonId: string, hideReason: boolean, optionalComment?: string) => {
    return request({ url: apiRoutes.apiQuote + quoteId + apiRoutes.cancel, method: "POST", data: { comment: optionalComment ?? null, reason_id: reasonId, is_external: !hideReason } })
}
export const useSendQuoteToBuyer = (quoteId: number, date: string, optionalComment: string | null) => {
    return request({ url: apiRoutes.apiQuote + quoteId + apiRoutes.sendToBuyer, method: "POST", data: { validity_date: date, comment: optionalComment ?? null } })
}
export const useFetchQuoteRejectReasons = () => {
    return request({ url: apiRoutes.apiQuote + apiRoutes.rejectReasons, method: 'GET' })
}
export const useFetchQuoteCancelReasons = () => {
    return request({ url: apiRoutes.apiQuote + apiRoutes.cancelReasons, method: 'GET' })
}

export const useSendReminderToApprovers = (quoteId: number) => {
    return request({ url: apiRoutes.apiQuote + quoteId + "/send-reminder-to-approvers", method: "POST" })
}

export function buildQuoteDetails(quote: Quote, userPortalPermission: UserPortalPermission | "", canApproveQuote: boolean) {
    const status = quote.status
    const isOwner = userPortalPermission == UserPortalPermission.owner
    const isApprover = userPortalPermission == UserPortalPermission.approver
    const hasProducts = quote.quote_products.length > 0
    const hasCreatePOPermission = useUserData.getState().canCreatePO
    const hasGenerateQuotePermission = useUserData.getState().canGenerateQuote
    return {

        // quoteModel: quote,
        // numberOfProducts: quote.quote_products.length,
        // isCreatedFromScratch: quote.created_from_scratch,
        // canEditAndRegenerate: !quote?.is_expired && !quote?.is_rfq_expired && isOwner && [procurementStatus.DRAFT, procurementStatus.APPROVED, procurementStatus.REJECTED, procurementStatus.RECALLED].includes(status) && hasProducts && !quote.created_from_scratch,//owner 
        // canSendForApprovals: !quote?.is_expired && !quote?.is_rfq_expired && isOwner && status == procurementStatus.DRAFT && hasProducts,//owner
        // canCancelApprovalsRequest: !quote?.is_expired && !quote?.is_rfq_expired && isOwner && status == procurementStatus.PENDING_APPROVAL,//owner
        // canSendQuoteToBuyer: !quote?.is_expired && !quote?.is_rfq_expired && isOwner && (status == procurementStatus.APPROVED || status == procurementStatus.RECALLED) && hasProducts,//owner //approved
        // canCancelQuoteToBuyer: isOwner && quote?.can_be_recalled,//owner //approved
        // canApproveQuote: !quote?.is_expired && !quote?.is_rfq_expired && isApprover && canApproveQuote && (status == procurementStatus.PENDING_MY_APPROVAL || status == procurementStatus.REJECTED_BY_ME),//approver
        // canRejectQuote: !quote?.is_expired && !quote?.is_rfq_expired && isApprover && canApproveQuote && (status == procurementStatus.PENDING_MY_APPROVAL || status == procurementStatus.APPROVED_BY_ME),//approver
        // canEditResponse: !quote?.is_expired && !quote?.is_rfq_expired && isApprover && canApproveQuote && (status == procurementStatus.REJECTED_BY_ME || status == procurementStatus.APPROVED_BY_ME),//approver
        // canGeneratePurchaseOrder: (hasCreatePOPermission || isOwner) && status == procurementStatus.QUOTE_RECEIVED,
        // canViewComparisonTable: status == procurementStatus.QUOTE_RECEIVED,
        // canAddProduct: quote.can_be_updated && isOwner && quote.created_from_scratch && ![procurementStatus.SENT_TO_BUYER, procurementStatus.PENDING_APPROVAL, procurementStatus.PO_RECEIVED].includes(status),
        // canDelete: isOwner && quote?.deletable,
        // canEditProducts: isOwner && quote.created_from_scratch && ![procurementStatus.SENT_TO_BUYER, procurementStatus.PENDING_APPROVAL, procurementStatus.PO_RECEIVED].includes(status),
        // canRequestExtension: hasGenerateQuotePermission && quote?.is_rfq_expired,
        // canDeclineRequestExtension: isOwner && quote?.is_expired == true && quote?.extension_request_status == procurementStatus.PENDING

        /* new solution */
        quoteModel: quote,
        numberOfProducts: quote.quote_products.length,
        isCreatedFromScratch: quote.created_from_scratch,
        // canEditAndRegenerate: quote?.form_action_permissions?.editable && isOwner && hasProducts && !quote.created_from_scratch,
        canEditAndRegenerate: isOwner && quote?.form_action_permissions?.editable && !quote?.form_action_permissions?.updatable_directly && hasProducts && !quote.created_from_scratch,
        canSendForApprovals: quote?.form_action_permissions?.can_send_for_approval && isOwner && hasProducts,
        canCancelApprovalsRequest: quote?.form_action_permissions?.can_cancel_approval_request && isOwner,
        canSendQuoteToBuyer: quote?.form_action_permissions?.can_send_to_counter_party && isOwner && hasProducts,
        canCancelQuoteToBuyer: quote?.form_action_permissions?.recallable && isOwner,
        canApproveQuote: quote?.form_action_permissions?.can_approve_approval_request && isApprover && canApproveQuote,
        canRejectQuote: quote?.form_action_permissions?.can_reject_approval_request && isApprover && canApproveQuote,
        canEditResponse: quote?.form_action_permissions?.can_edit_approval_decision && isApprover && canApproveQuote,
        canGeneratePurchaseOrder: (hasCreatePOPermission || isOwner) && status == procurementStatus.QUOTE_RECEIVED,
        canViewComparisonTable: status == procurementStatus.QUOTE_RECEIVED,
        canDelete: quote?.form_action_permissions?.deletable && isOwner,
        canAddProducts: isOwner && quote?.form_action_permissions?.updatable_directly,
        canEditProducts: isOwner && quote?.form_action_permissions?.updatable_directly,
        canEditQuoteFromScratch: isOwner && !quote?.form_action_permissions?.updatable_directly && quote?.form_action_permissions?.editable && quote.created_from_scratch,
        canRequestExtension: quote?.rfq?.form_action_permissions?.can_request_extension == true,
        canDeclineRequestExtension: quote?.form_action_permissions?.can_decline_request_extension
    };
}

export const useIndexBuyerQuotes = () => {
    return request({ url: apiRoutes.quote.buyer.index, method: "GET" })
}



export const useGetCreateQuoteData = () => {
    return request({ url: apiRoutes.apiQuote + apiRoutes.createFromScratch, method: "GET" })
}

export const useCreateQuote = (data: { buyer_company_id: string | null, project_id: string | null }) => {
    return request({ url: apiRoutes.apiQuote + apiRoutes.createFromScratch, method: "POST", data })
}


export const useSaveQuoteProducts = (quote: Quote, products: Product[]) => {
    const newProductsArray = products.map(({ id, price, quantity, warranty_term, remarks }) => ({ id, price, quantity, warranty_term_id: warranty_term?.id, remarks }));

    return request({ url: apiRoutes.apiQuote + quote.id + apiRoutes.updateRequisitionProducts, method: "PUT", data: { products: newProductsArray } })
}

export const useSelectTermsInQuote = (quoteId: number | undefined, general_terms: GeneralTermsPayload) => {
  return request({
    url: apiRoutes.apiQuote + quoteId + apiRoutes.selectTerms,
    method: 'PUT',
    data: {
      payment_term_id: general_terms.payment_term.id,
      payment_term_custom_value: general_terms.payment_term.value,
      warranty_term_id: general_terms.warranty_term.id,
      warranty_term_custom_value: general_terms.warranty_term.value,
      delivery_term_id: general_terms.delivery_term.id,
      delivery_term_custom_value: general_terms.delivery_term.value,
    },
  });
};

export const useDuplicateQuote = async (quoteId: number | undefined, project: any) => {
    return request({ url: apiRoutes.apiQuote + quoteId + apiRoutes.duplicate, method: "POST", data: { project_id: project.projectId, new_project_name: project.projectName ?? null } })
}

export const getAddProductToQuoteData = async (product_id: number) => {
    return request({ url: `/seller/quote/add-product-data`, method: 'GET', params: { product_id } })
}


export const useSaveSelectedBuyer = async (companyId: string, quoteId: number | undefined) => {
    return request({ url: apiRoutes.save_buyer, method: "POST", data: { quote_id: quoteId, company_id: companyId } })
}

export const useLinkSelectedBuyerWithQuote = (data: any, options = {}, quoteId: number | undefined) => {
    return request({
        url: apiRoutes.apiQuote + 'link-buyer-company',
        method: 'POST',
        data: { ...data, quote_id: quoteId },
        ...options,
    });
};