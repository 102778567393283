import { DraftRequisitionButtons, EditProject, RequisitionMainDetails, RequisitionProductCard, StyledTabs, TextViewWithValue } from "@/components";
import { AuthenticateStatus, productManagerEnv } from "@/config";
import { GeneralObject, Product, ProductCategoryKind, TabModel } from "@/models";
import { useProcurementStore, useUserData } from "@/zustand";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from "react";
import useSetHeaderData from "src/logic/hooks/use-header";
import useProductsAndServicesCount from "src/logic/hooks/smart/products-and-services/use-products-and-services-count";
import { sm } from "src/site-map";
import { RequisitionProductAlternativesTable, RequisitionServiceAlternativesTable } from "src/ui/components/dumb/requisition/requisition-alternatives-table";
import SellersIndicatorBox from "src/ui/components/dumb/requisition/requisition-product-card/sellers-indicator-box";
import PrimaryBadge from "src/ui/components/dumb/shared/primary-badge";

type RequisitionDetailsMainPageProps = {
    breadCrumbs: string
    hideProject?: boolean
};

export const RequisitionDetailsMainPage: React.FC<RequisitionDetailsMainPageProps> = ({ breadCrumbs, hideProject = false }) => {
    const { t } = useTranslation();
    const { push } = useRouter()
    const currentRequisition = useProcurementStore(state => state.currentRequisition)
    const changeProject = useProcurementStore(state => state.changeProject)
    const canEditRequisition = useProcurementStore(state => state.canEditRequisition)
    const numberOfCurrentMainProducts = useProcurementStore(state => state.numberOfCurrentMainProducts)
    const selectedMainProductId = useProcurementStore(state => state.requisitionDetailsSliceState.selectedMainProductId)
    const setSelectedProduct = useProcurementStore(state => state.requisitionsDetailsSliceActions.setSelectedProduct)
    const selectedMainProduct = useProcurementStore(state => state.requisitionDetailsSliceState.selectedMainProduct)
    const selectedAlternativeProducts = useProcurementStore(state => state.requisitionDetailsSliceState.selectedAlternativeProducts)
    // const numberSelectedProductOfAlternativeProducts = useProcurementStore(state => state.requisitionDetailsSliceState.numberSelectedProductOfAlternativeProducts)
    // const timeline = useProcurementStore(state => state.requisitionDetailsSliceState.timeline)
    const requisitionTabs = useProcurementStore(state => state.requisitionTabs)
    useSetHeaderData(currentRequisition?.name, null)
    const { getProductsArrayFromRequisition } = useProcurementStore(state => state.requisitionSliceActions)
    const { setDetails } = useProcurementStore(state => state.productFormManagerProperties.actions)

    const setEnvironmentForAddingProducts = () => {
        setDetails({ ...currentRequisition, products: getProductsArrayFromRequisition(currentRequisition) }, productManagerEnv.requisition)
    }

    const viewAlternatives = () => {
        push(sm.catalogue.products.alternatives(selectedMainProduct?.id).url)
        setEnvironmentForAddingProducts()
    }
    const redirectToProductPage = () => {
        push(sm.catalogue.index.url)
        setEnvironmentForAddingProducts()
    }

    const userInfo = useUserData().userInfo
    const isOwner = userInfo?.id == currentRequisition?.owner_id
    const isGuest = useUserData().authenticationStatus !== AuthenticateStatus.AUTHENTICATED;
    const canAddProducts = (isOwner && canEditRequisition) || isGuest

    const rawProducts = React.useMemo(() => currentRequisition?.products?.map(prod => prod.main_product) ?? [], [currentRequisition]);
    const { numberOfProducts, numberOfServices } = useProductsAndServicesCount(rawProducts);

    return (
        <>
            {currentRequisition &&
                <>
                    <RequisitionMainDetails currentRequisition={currentRequisition} breadCrumbs={breadCrumbs} >
                        <Grid item md={12}> <Divider sx={{ opacity: 0.7, my: 1 }} /> </Grid>
                        <Grid container md={9} alignItems="center" color='text.third' gap={5} >
                            {t('texts.created_on')} {currentRequisition.created_at} {t('texts.by')}  {currentRequisition?.owner_name}
                            <Typography fontWeight={400} fontSize={13} color="#8B8D98">Products: {numberOfProducts}</Typography>
                            <Typography fontWeight={400} fontSize={13} color="#8B8D98">Services: {numberOfServices}</Typography>
                            {!hideProject && <><TextViewWithValue title={t('texts.internal_project')} value={currentRequisition?.project.name} color='text.third' redirectLink={!isGuest ? sm.portals.buyer.projects.projectId(currentRequisition?.project.id.toString()).url : undefined} />
                                {!isGuest && useUserData.getState().userInfo?.id === currentRequisition.owner_id && <EditProject formModel={{ ...currentRequisition, requisition: { id: currentRequisition.id } }} updateProject={(project: GeneralObject) => changeProject(project)} projectId={currentRequisition?.project?.id} />}</>}
                        </Grid>

                    </RequisitionMainDetails>


                    <Grid container justifyContent={'space-between'} my={1}>
                        <Grid item>
                            <Typography fontWeight={700} color='text.primary' variant="overline">Products / Services <PrimaryBadge>{numberOfCurrentMainProducts}</PrimaryBadge></Typography>
                        </Grid>
                        <Grid item>
                            {canAddProducts && <Button variant='contained' onClick={redirectToProductPage}>Add Products / Services</Button>}
                        </Grid>
                    </Grid>


                    <Grid item md={12} mt={1}>
                        {requisitionTabs?.length != 0 && <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "grid" }}>
                            <TabContent tabs={requisitionTabs} selectedTab={selectedMainProductId} onChange={(id) => setSelectedProduct(id)} />
                        </Box>}
                    </Grid>

                    {selectedMainProduct && <><Grid item md={12} mt={1}>
                        <RequisitionProductCard canEditRequisition={canEditRequisition} withDetailsPopup productDetails={selectedMainProduct} ControlButtons={canEditRequisition ? DraftRequisitionButtons : RequisitionProductQuantityAndSellers} />
                    </Grid>
                        <Grid item container md={12} mt={0.5}>
                            <Grid item md={8} container>
                                <Typography variant="overline" fontWeight={700} mt={0.35}>Alternatives</Typography>
                                <PrimaryBadge>{selectedAlternativeProducts?.length}</PrimaryBadge>
                            </Grid>
                            <Grid item md={4} container justifyContent='flex-end'>
                                {canAddProducts && <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={viewAlternatives}
                                > {t("buttons.add_alternatives")}
                                </Button>}
                            </Grid>

                        </Grid>
                        {selectedMainProduct && selectedAlternativeProducts && selectedAlternativeProducts.length > 0 && (
                            <>
                                {selectedMainProduct.category_kind === ProductCategoryKind.PRODUCT && <RequisitionProductAlternativesTable alternativeProducts={selectedAlternativeProducts} quantity={selectedMainProduct.quantity} canDelete={canEditRequisition} />}
                                {selectedMainProduct.category_kind === ProductCategoryKind.SERVICE && <RequisitionServiceAlternativesTable alternativeProducts={selectedAlternativeProducts} quantity={selectedMainProduct.quantity} additional_requirements={selectedMainProduct.additional_requirements} canDelete={canEditRequisition} />}
                            </>
                        )}
                        {/* {selectedAlternativeProducts && <>
                            {selectedAlternativeProducts?.map((alternativeProduct, index) => (
                                <Grid item md={12} key={`${alternativeProduct.id}-${index}-${numberOfCurrentMainProducts}-${selectedMainProduct?.id}`}>
                                    <RequisitionProductCard withDetailsPopup productDetails={{...alternativeProduct, quantity: selectedMainProduct?.quantity}} ControlButtons={canEditRequisition ? DraftRequisitionButtons : RequisitionProductQuantityAndSellers} />
                                </Grid>
                            ))}
                        </>} */}
                        {selectedAlternativeProducts?.length === 0 && <>
                            <Typography
                                fontWeight={400}
                                color='#8B8D98'
                                variant='body1'
                            >
                                No Alternatives added for this product
                            </Typography>
                        </>}
                    </>
                    }
                </>
            }

        </>
    )
}

const boxSxProps = { width: '100%', backgroundColor: '#EAEEF6', borderRadius: '4px', padding: '8px 12px' }
const typographyProps = { color: '#13628C', fontSize: 13, fontWeight: 400 }
export const RequisitionProductQuantityAndSellers = ({ product }: { product: Product }) => {
    const { t } = useTranslation();
    return (
        <Grid md={12} container>
            <Box sx={boxSxProps}>
                <Typography {...typographyProps}>
                    {t('texts.quantity') + ": "}{product.quantity}
                </Typography>
            </Box>
            <SellersIndicatorBox sellers={product.sellers} />
        </Grid>
    )
}

type TabContentProps = {
    tabs: TabModel[] | null
    selectedTab: number
    onChange: (id: number) => void
}
const TabContent: React.FC<TabContentProps> = ({ tabs, selectedTab, onChange }) => {
    // const [value, setValue] = React.useState(selectedTab);
    // const updateTab = (event, newValue) => { setValue(newValue) };
    const { t } = useTranslation();
    return (
        <StyledTabs tabs={tabs} value={selectedTab} secondText={" " + t('buttons.alternatives')}
            updateTab={(_, newValue) => onChange(newValue)} onChange={() => { }} />
    )
}