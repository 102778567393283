import { NotificationMenu, UserNavMenu } from '@/components';
import { AuthenticateStatus } from '@/config';
import { AICircleSVG } from '@/icons';
import { ResponsiveContainer } from '@/styles';
import { useDrawerStore, useProcurementStore, useUserData } from '@/zustand';
import { Search } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { sm } from 'src/site-map';
import PrimaryBadge from '../../dumb/shared/primary-badge';
import AddNewPrivateItemLink from '../../smart/catalogue/shared/Add-private-item-link';
import useIsFullWidthHeader from './utils';

type HeaderProps = {
  authenticatedStatus?: number;
  showAddNewPrivateItemLink?: boolean
};

export const Header: React.VFC<HeaderProps> = ({ authenticatedStatus, showAddNewPrivateItemLink }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const userInfo = useUserData(state => state.userInfo);
  const { isBuyer } = useUserData();
  const { isSeller } = useUserData();
  const currentPath = router.pathname;
  const { headerTitle, headerTotal } = useProcurementStore(state => state.productFormManagerProperties.data);

  const { isDrawerOpen, isDrawerSubMenuOpen } = useDrawerStore();
  const { environment } = useProcurementStore(state => state.productFormManagerProperties.data);
  const isCatalogueRoute = currentPath === '/catalogue';
  const isFullWidth = useIsFullWidthHeader();
  return (
    <Grid container sx={{ width: environment ? 'calc(100% - 350px)' : '100%', justifyContent: 'center', bgcolor: 'background.main.parimary', paddingTop: 3, transition: 'width 0.3s ease' }} component={ResponsiveContainer}>
      <Grid
        container
        sx={{
          maxWidth: isFullWidth ? '100%' : '1400px',
          marginInline: '0 0',
          alignItems: 'center',
          bgcolor: 'background.main.parimary',
          ml: isDrawerOpen || isDrawerSubMenuOpen ? 35 : 10,
          transition: 'margin-left 0.3s ease',
          mb: isFullWidth ? 2 : 0,
        }}
      >
        <Grid container md={12} justifyContent={'space-between'} alignItems={'center'}>
          <Grid item >
            <Typography variant="h5" fontWeight={700} color="text.disabled">
              {headerTitle}
              {(headerTotal !== undefined && headerTotal !== null) ?
                <div style={{ transform: 'translateY(-3px)', display: 'inline-block' }}>
                  <PrimaryBadge>{headerTotal}</PrimaryBadge>
                </div> : null
              }
            </Typography>
          </Grid>
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            {isBuyer() && showAddNewPrivateItemLink && <HeaderText />}
            <Box
              display="flex" gap={1} alignItems="center"
              sx={{
                flexDirection: 'row', borderRadius: 8, mt: 0.2, mb: 0.5, px: 0.5, pl: 1, bgcolor: 'white', flexWrap: 'nowrap',
                boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
              }}
            >
              {!isCatalogueRoute && isSeller() && <SearchButton />}
              <AICircleSVG />
              {authenticatedStatus === AuthenticateStatus.AUTHENTICATED ? (
                <AuthenticatedMenu userInfo={userInfo} />
              ) : (
                <UnauthenticatedMenu router={router} t={t} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SearchButton: React.FC = () => {

  return (
    <Box width="7.4375em" height="3.125em" sx={{ display: 'flex', borderRadius: 4, overflow: 'hidden', alignItems: 'center', mr: 0.5 }}>
      <Link href={sm.catalogue.index.url} passHref>
        <Button
          startIcon={<Search sx={{ color: '#0074FF', fontSize: '10px', fontWeight: 500 }} />}
          sx={{
            bgcolor: 'hsla(222, 83%, 95%, 1)', borderRadius: 4, px: 2, color: '#718096', fontSize: '15px',
            '&:hover': { bgcolor: 'hsla(222, 83%, 95%, 1)' },
          }}
        >
          <Typography sx={{ color: '#0074FF', fontSize: '12px', fontWeight: 500 }}>Directory</Typography>
        </Button>
      </Link>
    </Box>
  )
};

type AuthenticatedMenuProps = {
  userInfo: {
    profile_picture_url?: string;
  };
};

const AuthenticatedMenu: React.FC<AuthenticatedMenuProps> = ({ userInfo }) => (
  <>
    <Box height="100%" display="flex" alignItems="center">
      <NotificationMenu />
    </Box>
    <Box pr={1}>
      <UserNavMenu imgURL={userInfo?.profile_picture_url} userData={userInfo} />
    </Box>
  </>
);

type UnauthenticatedMenuProps = {
  router: ReturnType<typeof useRouter>;
  t: (key: string) => string;
};

const UnauthenticatedMenu: React.FC<UnauthenticatedMenuProps> = ({ router, t }) => (
  <>
    <Button
      onClick={() => router.push(sm.register.index.url)}
      variant="contained"
      color="secondary"
      sx={{ flexGrow: 1, my: 1 }}
    >
      {t('buttons.register')}
    </Button>
    <Button
      onClick={() => router.push(sm.login.url)}
      variant="contained"
      sx={{ flexGrow: 1, mr: 1, px: 3, my: 1 }}
    >
      {t('buttons.login')}
    </Button>
  </>
);

const HeaderText: React.FC = () => (
  <AddNewPrivateItemLink />
  // View General Catalogue
  // View My Catalogue
  // Manage Private Listings
);
