import { procurementStatus } from "@/config";
import {
    ColumnFormat, ColumnSort,
    ColumnType,
    GeneralObject,
    TableColumnMapper, TableMapper,
    TableRow,
    TableRowAction,
    TableType,
    TabMapper
} from "@/models";
import { NotifySuccess, useCreateQuote, useGetCreateQuoteData, useGetSellerQuotes, useSendReminderToApprovers } from "@/services";
import { t } from "i18next";
import produce from "immer";
import { toast } from 'react-toastify';
import { useUserData } from 'src/logic/zustand/user';
import { StateCreator } from "zustand";
import { buildTableGroupModel } from "../../../../services/tables";

export interface SellerQuotesTableSlice {
    sellerQuotesTableSliceProperties: {
        data: {
            createData: { companies: GeneralObject[], projects: GeneralObject[] } | null
        },
        actions: {
            fetchCreateData: () => void
            create: (values: { projectId: string | null, buyerId: string | null }, redirectToDetails: (id: string) => void, onError?: () => void) => void
            load: () => void;
            onActionClicked: (row: TableRow, action: TableRowAction, detailsFunctionURL: () => void) => void;
            createNewCompany: (newCompany: GeneralObject) => void
        }
    },
}


export const createSellerQuotesTableSlice: StateCreator<SellerQuotesTableSlice> = (set, get, api) => ({
    sellerQuotesTableSliceProperties: {
        data: {
            createData: null,
        },
        actions: {
            fetchCreateData: () => {
                useGetCreateQuoteData().then(data => {
                    set(produce(draftState => { draftState.sellerQuotesTableSliceProperties.data.createData = data.data }))
                })
            },
            create: (values: { projectId: string | null, buyerId: string | null }, redirectToDetails: (id: string, data?: any) => void, onError?: () => void) => {
                useCreateQuote({ buyer_company_id: values?.buyerId, project_id: values?.projectId }).then(data => {
                    NotifySuccess(t('notifications.quote_created'))
                    redirectToDetails(data.data.id, data.data)
                }).catch(() => {
                    onError?.();
                })
            },
            onActionClicked: (row: TableRow, action: TableRowAction) => {
                if (action == TableRowAction.SEND_REMINDER) useSendReminderToApprovers(row.id).then(() => NotifySuccess('Reminder Was Sent Successfully'))
            },
            load: () => {
                set(produce(draftState => { draftState.loading = true }))
                useGetSellerQuotes().then(data => {
                    const userId = useUserData.getState().userInfo?.id
                    const hasQuoteApproverRole = useUserData.getState().canApproveQuote
                    const tableGroup = buildTableGroupModel(data.data, tableMapper(), quotesTableMapper(), userId, hasQuoteApproverRole)

                    tableGroup.tables.map(table => {
                        return table.tabs.map(tab => {
                            if (tab.hasActions) {
                                return tab.rows.map(row => {
                                    if (row.ownerId != userId) {
                                        return row.setActions([TableRowAction.VIEW])
                                    }
                                    if ((row.status == procurementStatus.PENDING_APPROVAL) && row.ownerId == userId) {
                                        return row.setActions([TableRowAction.SEND_REMINDER])
                                    }
                                    if (row.status == procurementStatus.REJECTED && row.ownerId == userId) {
                                        return row.setActions([TableRowAction.EDIT])
                                    }
                                    if (row.status == procurementStatus.APPROVED && row.ownerId == userId) {
                                        return row.setActions([TableRowAction.SEND_TO_BUYER])
                                    }
                                    if (row.status == procurementStatus.QUOTE_GENERATED && row.ownerId == userId) {
                                        return row.setActions([TableRowAction.SEND_FOR_APPROVAL])
                                    }
                                    return row
                                })
                            }
                            return tab
                        })
                    })

                    set(produce(draftState => {
                        draftState.sellerQuotesTableSliceProperties.data.loading = false
                        draftState.sellerQuotesTableSliceProperties.data.tableGroup = tableGroup
                    }))
                })
            },
            createNewCompany: (newCompany: GeneralObject) => {
                set(produce(draftState => {
                    const currentCreateData = get().sellerQuotesTableSliceProperties.data.createData;

                    draftState.sellerQuotesTableSliceProperties.data.createData = {
                        ...currentCreateData,
                        companies: [...(currentCreateData?.companies || []), newCompany].sort((a, b) => a.name.localeCompare(b.name)),
                    };
                }));
            }
        }
    }
})

export const tableMapper = () => {
    return [
        new TableMapper(TableType.OTHER, 'Other Quotes', [
            new TabMapper('all', [procurementStatus.ALL]),
            new TabMapper('QUOTE_GENERATED', [procurementStatus.QUOTE_GENERATED]),
            new TabMapper('SENT_FOR_APPROVAL', [procurementStatus.SENT_FOR_APPROVAL]),
            new TabMapper('SENT_TO_BUYER', [procurementStatus.SENT_TO_BUYER]),
            new TabMapper('CANCELED', [procurementStatus.CANCELED])
        ]),
        new TableMapper(TableType.MINE, 'My Quotes', [
            new TabMapper('all', [procurementStatus.ALL], true),
            new TabMapper('QUOTE_GENERATED', [procurementStatus.QUOTE_GENERATED], true),
            new TabMapper('SENT_FOR_APPROVAL', [procurementStatus.PENDING_APPROVAL, procurementStatus.APPROVED, procurementStatus.REJECTED], true),
            new TabMapper('SENT_TO_BUYER', [procurementStatus.SENT_TO_BUYER], true),
            new TabMapper('CANCELED', [procurementStatus.CANCELED])
        ]),
        new TableMapper(TableType.MY_APPROVAL, 'My Approvals', [
            new TabMapper('all', [procurementStatus.ALL]),
            new TabMapper('PENDING', [procurementStatus.PENDING_MY_APPROVAL]),
            new TabMapper('APPROVED', [procurementStatus.APPROVED_BY_ME]),
            new TabMapper('REJECTED', [procurementStatus.REJECTED_BY_ME]),
        ])
    ];
}

export const quotesTableMapper = () => {
    const dateColumn = new TableColumnMapper('created_at', 'date', ColumnType.DATE)
    dateColumn.columnSort = new ColumnSort(true, true)

    return [
        new TableColumnMapper('id', 'id', ColumnType.NUMBER, null, null, true),
        new TableColumnMapper('name', 'quote', ColumnType.STRING, ColumnFormat.URL,
            new TableColumnMapper('project.name', 'project', ColumnType.STRING)
        ),
        new TableColumnMapper('company_details.name', 'buyer', ColumnType.STRING),
        new TableColumnMapper('owner_name', 'owner', ColumnType.STRING),
        new TableColumnMapper('rfq_name', 'rfq', ColumnType.STRING),
        dateColumn,
        new TableColumnMapper('number_of_products', 'products', ColumnType.NUMBER),
        new TableColumnMapper('number_of_competitors', 'competitors', ColumnType.NUMBER),
        new TableColumnMapper('total_value', 'value', ColumnType.STRING),
        new TableColumnMapper('read_status', 'read_all', ColumnType.READ_TICK),
        new TableColumnMapper('status', 'status', ColumnType.STRING, ColumnFormat.CHIP, null, true),
    ];
}