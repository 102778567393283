import { ProductManagerEnv } from '@/config';
import { useProcurementStore } from '@/zustand';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { StyledPopper } from 'src/ui/components/shared';
import { PurchaseOrderPopperContent } from '../../purchase-order';
import { RequisitionsPopperContent } from '../../requisition';
import { QuotesPopperContent } from '../../quote/quote-list';
import { CatalogPopperContent } from '../../catalog/catalog-list';

export const ProductFormManagerPopper: FC<{ disableToggle?: boolean }> = ({
  disableToggle,
}) => {
  const { data } = useProcurementStore(
    state => state.productFormManagerProperties
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpenFormsMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const handleCloseFormsMenu = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Typography
          textAlign='start'
          variant='h6'
          fontWeight='700'
          color='text.primary'
          sx={{
            width: 'auto',
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {data.formDetails?.name}
        </Typography>
        {!disableToggle && (
          <IconButton
            disabled={disableToggle}
            onClick={handleOpenFormsMenu}
            size='small'
            sx={{ ml: 0.5, mt: -0.5 }}
          >
            <KeyboardArrowDown fontSize='small' sx={{ color: '#3A3E50' }} />
          </IconButton>
        )}
      </div>

      <StyledPopper
        open={open}
        anchorEl={anchorEl}
        handleClose={handleCloseFormsMenu}
        place={'bottom-start'}
        zIndex={10}
      >
        <Grid container width='323px' mt={3} p={2}>
          {data.environment?.type === ProductManagerEnv.purchase_order && (
            <PurchaseOrderPopperContent
              handleCloseFormPopper={handleCloseFormsMenu}
            />
          )}
          {data.environment?.type === ProductManagerEnv.requisition && (
            <RequisitionsPopperContent
              handleCloseFormPopper={handleCloseFormsMenu}
            />
          )}
          {data.environment?.type === ProductManagerEnv.quote && (
            <QuotesPopperContent handleCloseFormPopper={handleCloseFormsMenu} />
          )}
          {data.environment?.type === ProductManagerEnv.catalog && (
            <CatalogPopperContent
              handleCloseFormPopper={handleCloseFormsMenu}
            />
          )}
        </Grid>
      </StyledPopper>
    </>
  );
};
