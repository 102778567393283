import produce from 'immer';
import { EnvironmentFilters } from 'src/logic/models/catalogue/AdvancedFilters';
import { BrandDetails, BrandsIndex } from 'src/logic/models/catalogue/CatalogueIndex';
import { getBrandDetails, getBrandsIndex, getSortedBrands } from 'src/logic/services/catalogue/brands';
import QueryHandler, { QueryHandlerModel } from 'src/logic/services/query-handlers/QueryHandler';
import create from 'zustand';

type ResponseHandlers<T = any> = {
  onSuccess?: (data: T) => void;
  onError?: () => void;
};

export interface BrandsIndexSlice {
  brandsProperties: {
    brands: null | BrandsIndex;
    brandDetails: null | BrandDetails;
    fetchQueryStatus: QueryHandlerModel;
    fetchQueryFilterStatus: QueryHandlerModel;
  };

  brandsActions: {
    loadData: () => void;
    onSortBrands: (id: string) => void;
    loadBrandDetails: (
      id: number,
      envFilters: EnvironmentFilters,
      responseHandlers?: ResponseHandlers<BrandDetails>
    ) => void;
  };
}

export const useBrandsIndexSlice = create<BrandsIndexSlice>((set, get) => ({
  brandsProperties: {
    brands: null,
    brandDetails: null,
    fetchQueryStatus: QueryHandler.getInitialStatus(),
    fetchQueryFilterStatus: QueryHandler.getInitialStatus(),
  },

  brandsActions: {
    loadData: async () => {
      set(
        produce<BrandsIndexSlice>(draftState => {
          draftState.brandsProperties.fetchQueryStatus = QueryHandler.getStartStatus();
        })
      );

      getBrandsIndex()
        .then(data => {
          set(
            produce<BrandsIndexSlice>(draftState => {
              draftState.brandsProperties.brands = data.data;
              draftState.brandsProperties.fetchQueryStatus = QueryHandler.getSuccessStatus();
            })
          );
        })
        .catch(err => {
          set(
            produce<BrandsIndexSlice>(draftState => {
              draftState.brandsProperties.fetchQueryStatus = QueryHandler.getErrorStatus(err);
            })
          );
        });
    },
    onSortBrands: (id: string) => {
      set(
        produce<BrandsIndexSlice>(draftState => {
          draftState.brandsProperties.fetchQueryFilterStatus = QueryHandler.getStartStatus();
        })
      );

      getSortedBrands(id)
        .then(data => {
          set(
            produce<BrandsIndexSlice>(draftState => {
              draftState.brandsProperties.brands = data.data;
              draftState.brandsProperties.fetchQueryFilterStatus = QueryHandler.getSuccessStatus();
            })
          );
        })
        .catch(err => {
          set(
            produce<BrandsIndexSlice>(draftState => {
              draftState.brandsProperties.fetchQueryFilterStatus = QueryHandler.getErrorStatus(err);
            })
          );
        });
    },
    loadBrandDetails: (
      id: number,
      envFilters: EnvironmentFilters,
      responseHandlers?: ResponseHandlers<BrandDetails>
    ) => {
      set(
        produce<BrandsIndexSlice>(draftState => {
          draftState.brandsProperties.fetchQueryStatus = QueryHandler.getStartStatus();
        })
      );

      getBrandDetails(id, envFilters)
        .then(data => {
          set(
            produce<BrandsIndexSlice>(draftState => {
              draftState.brandsProperties.brandDetails = data.data;
              draftState.brandsProperties.fetchQueryStatus = QueryHandler.getSuccessStatus();
            })
          );
          responseHandlers?.onSuccess?.(data.data);
        })
        .catch(err => {
          set(
            produce<BrandsIndexSlice>(draftState => {
              draftState.brandsProperties.fetchQueryStatus = QueryHandler.getErrorStatus(err);
            })
          );
          responseHandlers?.onError?.();
        });
    },
  },
}));
