import {
  ConfirmPopup,
  CustomWarrantyText,
  DescPopup,
  HistoryType,
  MultilineTextField,
  ProductHistory,
  RemarkButton,
  StyledTextField,
  TableWithPagination,
  TextFieldCounterForm,
} from '@/components';
import { ProcurementFormModel, ProductCategoryKind, ProductCategoryKindLabel, ProductTableRow } from '@/models';
import { useProcurementStore } from '@/zustand';
import { Info, TextSnippetOutlined } from '@mui/icons-material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Box, Button, CardMedia, Grid, ThemeOptions, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ColumnDef } from '@tanstack/react-table';
import { getIn, useFormikContext } from 'formik';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import { sm } from 'src/site-map';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';
import QuantityRequestedMoreThanAvailableIndicator from 'src/ui/components/smart/procurement/buyer/purchase-order-buyer/purchase-order-details/QuantityRequestedMoreThanAvailableIndicator';
import BuyerRequirementsIndicator from 'src/ui/components/smart/procurement/products-table/BuyerRequirementsIndicator';
import ProductQuickView from 'src/ui/components/smart/product/product-quick-view';
import { DeleteIcon as Delete } from 'src/ui/icons/delete';
import { DescriptionIndicator } from '../../requisition/requisition-alternatives-table/components';
import { CustomWarrantyPopper } from '../poppers';
import SelectCustomWarranty from 'src/ui/components/smart/general-terms/custom-warranty/SelectCustomWarranty';
import { TermPayload } from 'src/logic/models/procurement/general-terms';
import { isTermsEqual } from 'src/ui/components/smart/general-terms/utils';

const useStyles = makeStyles((theme: ThemeOptions) => ({
  cellHead: {
    color: '#8B8D98',
    fontSize: 16,
    fontWeight: 400,
    padding: 0,
    paddingLeft: 40,
    textTransform: 'uppercase',
    width: '400px',
  },
  cellHeadPaddingLeft: {
    paddingLeft: 40,
  },
  cellHeadpaddingRight: {
    paddingRight: 15,
  },
  columnMarginTop: {
    marginTop: 5,
    fontSize: 18,
  },
  columnNoPadding: {
    fontWeight: 400,
    padding: 0,
  },
  columnPaddingLeft: {
    paddingLeft: 40,
  },
  columnPaddingRight: {
    paddingRight: 10,
  },
  head: {
    backgroundColor: theme?.palette?.background?.primary?.main,
    '& th:first-child': { borderRadius: '6px 0 0 6px', marginTop: 10 },
    '& th:last-child': { borderRadius: '0 6px 6px 0' },
  },
  // rowBody: { borderRadius: '6px 6px 6px 6px', padding: 0 },
  rowBody: { borderRadius: '0', padding: 0 },
  rowBodyWhite: { backgroundColor: theme?.palette?.secondary.contrastText },
  rowBodyRedBorder: {
    backgroundColor: theme?.palette?.secondary.contrastText,
    borderBottom: '1px solid #ff0000',
    '& .MuiTableCell-root': {
      borderBottom: '1px solid red',
      borderTop: '1px solid red',
    },
    '& .MuiTableCell-root:first-child': {
      borderLeft: '1px solid red',
    },
    '& .MuiTableCell-root:last-child': {
      borderRight: '1px solid red',
    },
  },
  shrink: { width: '1%' },
  pl_small: { paddingLeft: 20 },
  w_10: { width: '10%' },
  w_18: { width: '18%' },
}));

type IndividualProductsTableProps = {
  handleAdd: (obj: any) => void;
  handleRemove: <T>(index: number) => T | undefined;
  purchaseOrder: ProcurementFormModel | null;
  isBundle: boolean;
  isLastPoInBundle?: boolean;
  sendActionIsRequested?: boolean;
};

const EMPTY_ARR = [] as any[];
export const IndividualProductsTable: React.FC<IndividualProductsTableProps> = ({
  purchaseOrder,
  isBundle,
  isLastPoInBundle = false,
  sendActionIsRequested,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const generalTermsSlice = useProcurementStore(state => state);
  const {
    changeOrderedQty,
    changePrice,
    changeWarrantyTerm,
    updateProductDetails,
    removeProduct,
    addNewRemark,
    changeAdditionalRequirements,
  } = useProcurementStore(state =>
    isBundle ? state.bundlePurchaseOrderBuyerDetailsSliceActions : state.purchaseOrderBuyerDetailsSliceActions
  );
  const { terms, warranty_term } = useProcurementStore(state => state.purchaseOrderBuyerDetailsSliceProperties);
  const { values, errors } = useFormikContext();
  const formikSlice = getIn(values, name) || EMPTY_ARR;

  const created_from_scratch = purchaseOrder?.model?.created_from_scratch;

  const [tableRows, setTableRows] = useState('');
  useEffect(() => {
    setTableRows(formikSlice);
  }, [formikSlice]);

  const router = useRouter();
  const onEmptyState = () => {
    if (!created_from_scratch) {
      router.push(sm.portals.buyer.procurement.purchaseOrders.index.url);
    }
  };
  // const onProductRemove = (productId: number) => removeProduct(productId, onEmptyState)
  const confirmDeletePopup = usePopup();
  const isLastProduct = values?.length === 1;
  const deletePopupSubtitle = React.useMemo(() => {
    if (created_from_scratch) {
      return t('popups.subTitles.remove_product', { formName: 'Purchase Order' });
    }
    if (isLastPoInBundle && isBundle && isLastProduct) {
      return t('popups.subTitles.delete_last_product_in_last_po');
    }
    if (isLastProduct) {
      return t('popups.subTitles.delete_last_product_in_po');
    }
    return t('popups.subTitles.remove_product', { formName: 'Purchase Order' });
  }, [isLastProduct, isBundle, isLastPoInBundle, created_from_scratch]);

  const [stagedProductIdToDelete, setStagedProductIdToDelete] = useState<number>();
  const handleDeleteStagedProduct = () => {
    if (stagedProductIdToDelete) {
      removeProduct(stagedProductIdToDelete, onEmptyState);
    }
    confirmDeletePopup.handleClose();
  };
  const onRequestToDeleteProduct = (productId: number) => {
    setStagedProductIdToDelete(productId);
    confirmDeletePopup.handleOpen();
  };
  const onRequestToCancelDeleteProduct = () => {
    setStagedProductIdToDelete(undefined);
    confirmDeletePopup.handleClose();
  };

  const debounceRef = useRef(null);

  const debounce = (func, delay) => {
    return (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedUpdateProductDetails = useCallback(
    debounce(original => {
      updateProductDetails(original);
    }, 1000),
    []
  );

  const columns = React.useMemo<ColumnDef<ProductTableRow>[]>(
    () => [
      {
        accessorKey: 'productDetails.category_kind',
        id: 'productDetails.category_kind',
        header: 'segment',
        meta: {
          filterType: 'search',
          columnIcon: null,
          headerCellClass: `${classes.cellHead} ${classes.pl_small}`,
          tableRow: {
            cellClass: `${classes.rowBody} ${classes.shrink}`,
          },
        },
        cell: info => {
          const { values } = useFormikContext();
          const product = values[info.row.index]?.productDetails;
          return (
            <Typography
              width='100%'
              color='#3A3E50'
              fontSize={11}
              fontWeight={400}
              textTransform={'uppercase'}
              textAlign={'center'}
              px={1}
            >
              {ProductCategoryKindLabel[product?.category_kind]}
            </Typography>
          );
        },
        enableColumnFilter: false,
        enableSorting: false
      },
      {
        accessorKey: 'productDetails',
        id: 'productDetails',
        header: 'product_details',
        cell: info => {
          const { values } = useFormikContext();
          const product = values[info.row.index]?.productDetails;
          const additional_requirements = values[info.row.index]?.additional_requirements;
          return (
            <ProductDetails
              content={info.getValue()}
              product={product}
              additional_requirements={additional_requirements}
              isRequirementsEditable={created_from_scratch}
              onRequirementsChange={(newRequirements, popupUtils) => {
                changeAdditionalRequirements(values[info.row.index], newRequirements);
                popupUtils?.handleClose();
              }}
            />
          );
        },
        meta: {
          filterType: 'search',
          columnIcon: UnfoldMoreIcon,
          headerCellClass: classes.cellHead,
          tableRow: {
            cellClass: `${classes.rowBody} `,
          },
        },
        enableColumnFilter: false,
        enableSorting: false
      },
      // {
      //     accessorKey: 'brandImage',
      //     id: 'brandImage',
      //     header: 'brand',
      //     cell: (info) => <ImageTable imageUrl={info.getValue()} />,
      //     meta: {
      //         filterType: 'search',
      //         columnIcon: UnfoldMoreIcon,
      //         headerCellClass: classes.columnPaddingLeft,
      //         tableRow: {
      //             cellClass: classes.styleRemarkColumn
      //         }
      //     },
      //     enableColumnFilter: false
      // },
      {
        accessorKey: 'warranty_term',
        id: 'warranty_term',
        header: 'warranty',
        cell: ({ row: { index, original }, getValue }) => {
          const { values } = useFormikContext();
          const productWarrantyTerm = values[index]?.warranty_term;
          const isCustomWarranty = !isTermsEqual(warranty_term, productWarrantyTerm);

          if (!created_from_scratch) {
            return (
              <CustomWarrantyText
                isWarranyTermCustom={isCustomWarranty}
                warrantyName={productWarrantyTerm?.name}
              />
            );
          }
          
          return (
            <Grid container alignItems={'start'} justifyContent={'start'} pt={0.5}>
              <Box width='180px'>
                <SelectCustomWarranty
                  generalTermsSlice={generalTermsSlice}
                  handleChange={(warranty_term: TermPayload) => changeWarrantyTerm(original, warranty_term)}
                  value={productWarrantyTerm ?? {}}
                  items={terms?.warranty_terms ?? []}
                />
              </Box>
              {productWarrantyTerm?.name && productWarrantyTerm?.name !== warranty_term?.name && <CustomWarrantyPopper />}
            </Grid>
          );
        },
        meta: {
          filterType: 'search',
          columnIcon: UnfoldMoreIcon,
          headerCellClass: classes.cellHead,
          tableRow: {
            cellClass: classes.styleWithPaddingColumn,
          },
        },
        enableColumnFilter: false,
        enableSorting: false
      },
      {
        accessorKey: 'requestedQuantity',
        id: 'requestedQuantity',
        header: 'ordered_qty',
        cell: ({ row: { index, original } }) => {
          const { values } = useFormikContext();
          const requestedQuantity = values?.[index]?.requestedQuantity;
          const availableQuantity = values?.[index]?.availableQuantity;
          const isRequestedMoreThanAvailable =
            requestedQuantity != undefined && availableQuantity != undefined && requestedQuantity > availableQuantity;

          return (
            <Grid container alignItems={'center'} justifyContent={'center'}>
              <Grid item>
                <TextFieldCounterForm
                  onChange={value => {
                    value && changeOrderedQty(original, value);
                    !isBundle && debouncedUpdateProductDetails(original);
                  }}
                  name={`${name}[${index}].requestedQuantity`}
                  onBlurAction={() => {
                    if (isBundle) {
                      updateProductDetails(original);
                      console.log('blur')
                    }
                  }}
                />
              </Grid>
              <Grid item md={2}>
                {isRequestedMoreThanAvailable && (
                  <span
                    style={{
                      marginInlineStart: '.5rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <QuantityRequestedMoreThanAvailableIndicator
                      availableQuantity={availableQuantity}
                      requestedQuantity={requestedQuantity}
                    />
                  </span>
                )}
              </Grid>
            </Grid>
          );
        },
        meta: {
          filterType: 'search',
          columnIcon: UnfoldMoreIcon,
          headerCellClass: classes.cellHead,
          tableRow: {
            cellClass: classes.styleWithPaddingColumn,
          },
        },
        enableColumnFilter: false,
        enableSorting: false
      },
      {
        accessorKey: 'unitPrice',
        id: 'unitPrice',
        header: 'unit_price',
        cell: ({ row: { index, original } }) => {
          const { values } = useFormikContext();
          const poHistoryItems = purchaseOrder?.model?.products[index]?.product?.purchaseHistoryItems;

          return (
            <Grid
              container
              md={12}
              alignItems={'center'}
              // justifyContent={'center'}
              // pl={poHistoryItems?.length != 0 ? 4 : 0}
              pl={4}
            >
              {created_from_scratch ? (
                <Box width='120px'>
                  <StyledTextField
                    type='number'
                    onChange={e => {
                      changePrice(original, e.target.value);
                      debouncedUpdateProductDetails(original);
                    }}
                    name={`${name}[${index}].unitPrice`}
                    translateKey='enter_the_price'
                    outlined
                    onBlurAction={() => { }}
                  />
                </Box>
              ) : (
                <>AED {values[index]?.unitPrice}</>
              )}
              {poHistoryItems && poHistoryItems?.length != 0 && (
                <ProductHistory
                  items={poHistoryItems}
                  type={HistoryType.purchase}
                  title={t('texts.purchase_history')}
                />
              )}
            </Grid>
          );
        },
        meta: {
          filterType: 'search',
          columnIcon: UnfoldMoreIcon,
          headerCellClass: classes.cellHead,
          tableRow: {
            cellClass: `${classes.rowBody} ${classes.w_18}`,
          },
        },
        enableColumnFilter: false,
        enableSorting: false
      },
      {
        accessorKey: 'remark',
        id: 'remark',
        header: 'remarks',
        cell: ({ row: { index, original } }) => (
          <Grid container alignItems={'start'} justifyContent={'start'} pl={0}>
            <AddRemark
              rowIndex={index}
              row={original}
              addNewRemark={addNewRemark}
              updateProductDetails={updateProductDetails}
            />
          </Grid>
        ),
        meta: {
          filterType: 'search',
          columnIcon: UnfoldMoreIcon,
          headerCellClass: classes.cellHead,
          tableRow: {
            cellClass: classes.columnPaddingLeft,
          },
        },
        enableColumnFilter: false,
        enableSorting: false
      },
      {
        accessorKey: 'empty',
        id: 'empty',
        header: 'empty',
        cell: ({ row: { index, original } }) => (
          <RemoveAction rowIndex={index} row={original} onRemove={onRequestToDeleteProduct} />
        ),
        meta: {
          // filterType: 'search',
          columnIcon: '',
          headerCellClass: classes.cellHead,
          tableRow: {
            cellClass: classes.styleRemarkColumn,
          },
        },
        enableColumnFilter: false,
        enableSorting: false
      },
    ],
    [warranty_term, terms, created_from_scratch]
  );

  const columnsWithErrors = React.useMemo<ColumnDef<ProductTableRow>[]>(
    () => [
      {
        accessorKey: 'productDetails',
        header: 'empty',
        cell: ({ row: { index, original } }) => {
          const { setFieldValue, values, errors } = useFormikContext();
          return <>{errors[index] && <Info color='error' />}</>;
        },
        meta: {
          filterType: 'search',
          columnIcon: null,
          tableRow: {
            cellClass: classes.columnPaddingRight,
          },
        },
        enableColumnFilter: false,
        enableSorting: false
      },
    ],
    []
  );
  return (
    <>
      <ConfirmPopup
        titleKey={`Delete Product`}
        subTitleKey={deletePopupSubtitle}
        buttonTitleKey={t('buttons.delete')}
        handleClose={onRequestToCancelDeleteProduct}
        handleConfirm={handleDeleteStagedProduct}
        open={confirmDeletePopup.isOpen}
      />
      <TableWithPagination
        style={{ overflow: 'visible' }}
        disablePagination
        columns={errors.length != 0 ? [...columns, ...columnsWithErrors] : columns}
        data={tableRows}
        key={tableRows}
        tableMeta={{
          getRowStyle: index => {
            return created_from_scratch && sendActionIsRequested && !values?.[index]?.unitPrice
              ? classes.rowBodyRedBorder
              : classes.rowBodyWhite;
          },
          headStyle: classes.head,
        }}
      />
    </>
  );
};

const ProductDetails = ({
  content,
  product,
  additional_requirements,
  isRequirementsEditable,
  onRequirementsChange,
}) => {
  const detailsPopup = usePopup();

  return (
    <>
      <DescPopup big open={detailsPopup.isOpen} handleClose={detailsPopup.handleClose} paperSx={{ borderRadius: 0 }}>
        <ProductQuickView product={product} />
      </DescPopup>
      <Grid container maxWidth={'285px'}>
        <Grid item md={2} pr={1}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pr: 1 }}>
            <CardMedia
              component='img'
              sx={{ objectFit: 'contain', height: '45px', width: '45px' }}
              image={product?.image_url}
              alt={''}
            />
          </Box>
        </Grid>
        <Grid item md={10}>
          <div style={{ display: 'flex' }}>
            <Typography
              variant='body2'
              fontWeight={700}
              color={'text.disabled'}
              className={'underline-on-hover cursor-pointer'}
              onClick={detailsPopup.handleOpen}
              noWrap
            >
              {content.name}
            </Typography>
            {product?.is_private_product && <PrivacyButton disableText />}
          </div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <DescriptionIndicator description={product?.description} category_kind={product?.category_kind} />
            {product?.category_kind === ProductCategoryKind.PRODUCT ? (
              <Typography fontSize={13} fontWeight={400} color='#8B8D98'>
                {product?.part_number}
              </Typography>
            ) : (
              <BuyerRequirementsIndicator
                additional_requirements={additional_requirements}
                editable={isRequirementsEditable}
                onSave={onRequirementsChange}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const ImageTable = ({ imageUrl }) => {
  return (
    <Grid container pl={2}>
      <Box sx={{ height: '25px', width: '50px' }} m={1}>
        <CardMedia component='img' sx={{ objectFit: 'contain', height: '100%' }} image={imageUrl} />
      </Box>
    </Grid>
  );
};

const RemoveAction = (props: { row: ProductTableRow; onRemove: Function }) => {
  return (
    <Grid container>
      <Button onClick={() => props.onRemove(props.row.productId)}>
        {/* <Box width='20px' height='30px' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: 'warning.contrastText', borderRadius: '4px' }}> */}
        {/* <DeleteSmallIcon /> */}
        <Delete fontSize='small' />
        {/* </Box> */}
      </Button>
    </Grid>
  );
};

const AddRemark = (props: {
  rowIndex: number;
  row: ProductTableRow;
  addNewRemark: Function;
  updateProductDetails: Function;
}) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext();
  const [showAddRemarkPopup, setShowAddRemarkPopup] = useState(false);
  const openAddRemarkPopup = () => setShowAddRemarkPopup(true);
  const closeAddRemarkPopup = () => setShowAddRemarkPopup(false);

  const updateRemarkOnProduct = (remark: string) => {
    setFieldValue(`${name}[${props.rowIndex}].remark`, remark);
    props.addNewRemark(props.row, remark);
    props.updateProductDetails(props.row);
    closeAddRemarkPopup();
  };
  const removeRemark = () => updateRemarkOnProduct('');
  const editRemark = (remark: string) => updateRemarkOnProduct(remark);
  return (
    <>
      {values[props.rowIndex]?.remark ? (
        <RemarkButton
          remark={values[props.rowIndex].remark}
          contentDetails={values[props.rowIndex]}
          deleteRemarkHandler={removeRemark}
          handleRemarkChange={editRemark}
        />
      ) : (
        <>
          <Button onClick={openAddRemarkPopup}>
            <Typography variant='body2' fontWeight={400} color={'warning.active'} sx={{ textDecoration: 'underline' }}>
              {t('buttons.add_remark')}
            </Typography>
          </Button>
          <DescPopup open={showAddRemarkPopup} handleClose={closeAddRemarkPopup}>
            <RemarkPopupContent updateRemarkOnProduct={updateRemarkOnProduct} />
          </DescPopup>
        </>
      )}
    </>
  );
};
const RemarkPopupContent = ({ updateRemarkOnProduct }) => {
  const { t } = useTranslation();
  const [remark, setRemark] = useState('');

  return (
    <Grid container gap={2} width={300}>
      <Grid item md={12} textAlign='center'>
        <TextSnippetOutlined sx={{ color: 'primary.main', fontSize: 40 }} />
        <Grid item md={12} textAlign='center'>
          <Typography variant='overline' color='primary.main' fontWeight='700' my={1}>
            {t('buttons.add_remark')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container gap={1}>
        <Grid item container gap={1} md={12}>
          <Typography variant='subtitle2' sx={{ color: 'text.third' }}>
            {t('buttons.add_remark')}
          </Typography>
          <MultilineTextField
            value={remark}
            placeholder={t('input_fields.type_your_remark')}
            handleValueChange={val => setRemark(val)}
          />
          <Button variant='contained' fullWidth onClick={() => updateRemarkOnProduct(remark)}>
            {t('buttons.add_remark')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
