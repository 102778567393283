import { request } from '@/helpers';

const addProductToQuote = ({
  quote_id,
  product_id,
  quantity,
  price,
  additional_requirements,
}: {
  quote_id: string;
  product_id: number;
  quantity: number;
  price: number;
  additional_requirements?: string | null;
}) =>
  request({
    url: `/seller/quote/${quote_id}/update-products`,
    method: 'PUT',
    data: {
      quote_id,
      product_id,
      quantity,
      price,
      additional_requirements,
    },
  });

const removeProductFromQuote = ({ quote_id, product_id }: { quote_id: string; product_id: number }) =>
  request({
    url: `/seller/quote/${quote_id}/product`,
    method: 'DELETE',
    data: {
      product_id,
    },
  });

const changeQuantity = ({
  quote_id,
  product_id,
  quantity,
}: {
  quote_id: string;
  product_id: number;
  quantity: number;
}) =>
  request({
    url: `/seller/quote/${quote_id}/update-products`,
    method: 'PUT',
    data: {
      product_id,
      quantity,
    },
  });

const changePrice = ({ quote_id, product_id, price }: { quote_id: string; product_id: number; price: number }) =>
  request({
    url: `/seller/quote/${quote_id}/update-products`,
    method: 'PUT',
    data: {
      product_id,
      price,
    },
  });

const changeWarrantyTerm = ({
  quote_id,
  product_id,
  warranty_term_id,
  warranty_term_custom_value,
}: {
  quote_id: string;
  product_id: number;
  warranty_term_id: number;
  warranty_term_custom_value?: string | number | null;
}) =>
  request({
    url: `/seller/quote/${quote_id}/update-products`,
    method: 'PUT',
    data: {
      product_id,
      warranty_term_id,
      warranty_term_custom_value,
    },
  });

const changeRemarks = ({ quote_id, product_id, remarks }: { quote_id: string; product_id: number; remarks: string }) =>
  request({
    url: `/seller/quote/${quote_id}/update-products`,
    method: 'PUT',
    data: {
      product_id,
      remarks,
    },
  });

const changeAdditionalRequirements = ({
  quote_id,
  product_id,
  additional_requirements,
}: {
  quote_id: string;
  product_id: number;
  additional_requirements: string;
}) =>
  request({
    url: `/seller/quote/${quote_id}/update-products`,
    method: 'PUT',
    data: {
      product_id,
      additional_requirements,
    },
  });

const QuoteSavingServices = {
  addProductToQuote,
  removeProductFromQuote,
  changeQuantity,
  changePrice,
  changeWarrantyTerm,
  changeRemarks,
  changeAdditionalRequirements,
};

export default QuoteSavingServices;
