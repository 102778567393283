import { apiRoutes, AuthenticateStatus, procurementStatus, UserPortalPermission } from "@/config";
import { request } from "@/helpers";
import { BundleProcurementDetails, GeneralObject, LinkType, ProcurementFormModel, ProcurementFormProduct, ProcurementFormProductDetailsApi, ProcurementModelDetails } from "@/models";
import { useUserData } from "@/zustand";
import produce from "immer";
import { useGetListOfApprovals } from "./approval-service";
import { TermPayload } from "src/logic/models/procurement/general-terms";


export const useGetAllRequisitionData = (userAuthStatus: AuthenticateStatus) => {
    if (userAuthStatus == AuthenticateStatus.AUTHENTICATED) {
        return request({ url: apiRoutes.procurementData, method: "GET" })
    } else {
        return new Promise(resolve => {
            resolve({
                data: {
                    requisitions: [],
                    projects: [],
                }
            });
        });
    }
}

export const useGetAllSellerProcurementData = (userAuthStatus: AuthenticateStatus) => {
    if (userAuthStatus == AuthenticateStatus.AUTHENTICATED) {
        return request({ url: apiRoutes.procurementData, method: "GET" })
    } else {
        return new Promise(resolve => {
            resolve({
                data: {
                    requisitions: [],
                    projects: [],
                }
            });
        });
    }
}

export const useGetSellerQuotes = () => {
    return request({ url: apiRoutes.quote.seller.index, method: "GET" })
}

export const useRequestExtension = async (formId: number | undefined, formType: string) => {
    return request({ url: apiRoutes.requestExtension, method: "POST", data: { expirable_id: formId, expirable_type: formType } })
}

export const useEditExpiryDate = async (formId: number | undefined, formType: LinkType, validityDate: string) => {
    return request({ url: apiRoutes.editValidityDate, method: "PUT", data: { form_id: formId, form_type: formType, validity_date: validityDate } })
}

export const useDeclineRequestExtension = async (formId: number | undefined, formType: string) => {
    return request({ url: apiRoutes.declineRequestExtension, method: "POST", data: { expirable_id: formId, expirable_type: formType } })
}

export enum ProcurementFormPermission {
    EDIT = "EDIT",
    EditAsMainAction = "EditAsMainAction",
    EditAndSend = "EditAndSend",
    CANCEL = "CANCEL",
    DELETE = "DELETE",
    SEND_FOR_APPROVAL = "SEND_FOR_APPROVAL",
    APPROVE = "APPROVE",
    REJECT = "REJECT",
    EDIT_RESPONSE = "EDIT_RESPONSE",
    CANCEL_APPROVAL = "CANCEL_APPROVAL",
    SEND_TO_SELLER = "SEND_TO_SELLER",
    SEND_TO_SELLERS = "SEND_TO_SELLERS",
    // VIEW_CT = "VIEW_CT",
    CONFIRM = "CONFIRM",
    DECLINE = "DECLINE",
    CHANGE_RESPONSE = "CHANGE_RESPONSE",
    RECALL_Bundle = "RECALL_Bundle",
    RECALL = "RECALL",
    ADD_PRODUCTS = "ADD_PRODUCTS",
    UPDATE_PO = "UPDATE_PO",
    PUBLISH = "PUBLISH",
    REQUEST_EXTENSION = "REQUEST_EXTENSION",
    DECLINE_REQUEST_EXTENSION = "DECLINE_REQUEST_EXTENSION",
    EditAsMainActionFromScratch = "EditAsMainActionFromScratch",
    EditAndSendFromScratch = "EditAndSendFromScratch",
    EDIT_FROM_SCRATCH = "EDIT_FROM_SCRATCH",
    REQUEST_EXTENSION_FOR_EXPIRED_QUOTE_BUYER = "REQUEST_EXTENSION_FOR_EXPIRED_QUOTE_BUYER",
    CANCEL_FORM = "CANCEL_FORM"
}

// export function getProcurementFormFormActions(formDetails: ProcurementModelDetails, userType: UserPortalPermission, hasApprovalPermission: boolean, isPartOfBundle?: boolean, canChangeResponse?: boolean, isAllQuotesExpired?: boolean, isThereOneChildCanBeRecalled?: boolean, hasApprovedStatus?: boolean): Record<ProcurementFormPermission, boolean> {
//     const canEditResponse = (formDetails.status == procurementStatus.APPROVED_BY_ME || formDetails.status == procurementStatus.REJECTED_BY_ME) && [procurementStatus.PARTIALLY_APPROVED, procurementStatus.PENDING_APPROVAL, procurementStatus.PARTIALLY_APPROVED, procurementStatus.REJECTED, procurementStatus.APPROVED].includes(formDetails.originalStatusForApprover) && userType == UserPortalPermission.approver && hasApprovalPermission;
//     const formActions: Record<ProcurementFormPermission, boolean> = {
//         [ProcurementFormPermission.EDIT]: (formDetails.status == procurementStatus.DRAFT || formDetails.status == procurementStatus.APPROVED || formDetails.status == procurementStatus.PARTIALLY_APPROVED || formDetails.status == procurementStatus.RECALLED) && userType == UserPortalPermission.owner && !isPartOfBundle,
//         [ProcurementFormPermission.EditAsMainAction]: (formDetails.status == procurementStatus.REJECTED) && userType == UserPortalPermission.owner && !isPartOfBundle,
//         [ProcurementFormPermission.EditAndSend]: (formDetails.status == procurementStatus.DECLINED || formDetails.status == procurementStatus.PARTIALLY_DECLINED) && userType == UserPortalPermission.owner && !isPartOfBundle,
//         [ProcurementFormPermission.CANCEL_APPROVAL]: formDetails.status == procurementStatus.PENDING_APPROVAL && userType == UserPortalPermission.owner && !isPartOfBundle,
//         [ProcurementFormPermission.CANCEL]: (formDetails.status == procurementStatus.SENT_TO_SELLER && userType == UserPortalPermission.owner && !isPartOfBundle) || canChangeResponse || false,
//         [ProcurementFormPermission.DELETE]: formDetails.deletable == true && userType == UserPortalPermission.owner,
//         [ProcurementFormPermission.SEND_FOR_APPROVAL]: formDetails.status == procurementStatus.DRAFT && userType == UserPortalPermission.owner && !isPartOfBundle && (formDetails.products ? formDetails.products?.length > 0 : true),
//         [ProcurementFormPermission.APPROVE]: (formDetails.status == procurementStatus.PENDING_MY_APPROVAL || canEditResponse) && (formDetails.status !== procurementStatus.APPROVED_BY_ME) && userType == UserPortalPermission.approver && hasApprovalPermission,
//         [ProcurementFormPermission.REJECT]: (formDetails.status == procurementStatus.PENDING_MY_APPROVAL || canEditResponse) && (formDetails.status !== procurementStatus.REJECTED_BY_ME) && userType == UserPortalPermission.approver && hasApprovalPermission,
//         [ProcurementFormPermission.EDIT_RESPONSE]: canEditResponse,
//         [ProcurementFormPermission.SEND_TO_SELLER]: (formDetails.status == procurementStatus.APPROVED || formDetails.status == procurementStatus.RECALLED) && formDetails.is_quote_expired !== true && userType == UserPortalPermission.owner,
//         [ProcurementFormPermission.SEND_TO_SELLERS]: ((formDetails.status == procurementStatus.APPROVED || formDetails.status == procurementStatus.PARTIALLY_APPROVED || formDetails.status == procurementStatus.PARTIALLY_SENT_TO_SELLERS || formDetails.status == procurementStatus.PARTIALLY_CONFIRMED || formDetails.status == procurementStatus.CONFIRMED) && !isAllQuotesExpired && hasApprovedStatus && userType == UserPortalPermission.owner && !isPartOfBundle) || false,
//         // [ProcurementFormPermission.VIEW_CT]: (formDetails.status == procurementStatus.SENT_TO_SELLER || formDetails.status == procurementStatus.DECLINED) && userType == UserPortalPermission.owner && !isPartOfBundle,
//         [ProcurementFormPermission.CONFIRM]: (formDetails.status == procurementStatus.PO_RECEIVED && !formDetails.is_expired && !canChangeResponse) || (formDetails.status == procurementStatus.DECLINED && canChangeResponse) || false,
//         [ProcurementFormPermission.DECLINE]: (formDetails.status == procurementStatus.PO_RECEIVED && !formDetails.is_expired && !canChangeResponse) || (formDetails.status == procurementStatus.CONFIRMED && canChangeResponse) || false,
//         [ProcurementFormPermission.CHANGE_RESPONSE]: !formDetails.is_expired && (formDetails.status == procurementStatus.CONFIRMED || formDetails.status == procurementStatus.DECLINED) && !isPartOfBundle && !canChangeResponse,
//         [ProcurementFormPermission.RECALL_Bundle]: (isThereOneChildCanBeRecalled && !isPartOfBundle) || false,
//         [ProcurementFormPermission.RECALL]: formDetails.can_be_recalled == true && userType == UserPortalPermission.owner,
//         [ProcurementFormPermission.ADD_PRODUCTS]: (formDetails.status == procurementStatus.DRAFT || (formDetails.status == procurementStatus.APPROVED && formDetails.is_individual == true)) && userType == UserPortalPermission.owner,
//         [ProcurementFormPermission.UPDATE_PO]: formDetails.status !== procurementStatus.SENT_TO_SELLER && formDetails.status !== procurementStatus.PENDING_APPROVAL && formDetails.status !== procurementStatus.REJECTED && formDetails.status !== procurementStatus.CONFIRMED && formDetails.status !== procurementStatus.DECLINED && userType == UserPortalPermission.owner,
//         // [ProcurementFormPermission.UPDATE_PO]: (formDetails.status == procurementStatus.DRAFT || (formDetails.status == procurementStatus.APPROVED && formDetails.is_individual)) && userType == UserPortalPermission.owner,
//         [ProcurementFormPermission.PUBLISH]: formDetails.status == procurementStatus.DRAFT && userType == UserPortalPermission.owner,
//         [ProcurementFormPermission.REQUEST_EXTENSION]: formDetails.is_expired == true && !formDetails.has_pending_extension_request && formDetails.extension_request_status !== procurementStatus.DECLINED && formDetails.extension_request_status !== procurementStatus.PENDING,
//         [ProcurementFormPermission.DECLINE_REQUEST_EXTENSION]: userType == UserPortalPermission.owner && formDetails.is_expired == true && formDetails.extension_request_status == procurementStatus.PENDING,
//         [ProcurementFormPermission.EditAsMainActionFromScratch]: formDetails.status == procurementStatus.REJECTED && userType == UserPortalPermission.owner && !isPartOfBundle,
//         [ProcurementFormPermission.EditAndSendFromScratch]: formDetails.status == procurementStatus.DECLINED && userType == UserPortalPermission.owner && !isPartOfBundle,

//     };
//     return formActions;
// }

/* new solution */
export function getProcurementFormFormActions(formDetails: ProcurementModelDetails, userType: UserPortalPermission, hasApprovalPermission: boolean, isPartOfBundle?: boolean, canChangeResponse?: boolean, isAllQuotesExpired?: boolean, isThereOneChildCanBeRecalled?: boolean, generatePermission?: boolean, hasApprovedStatus?: boolean): Record<ProcurementFormPermission, boolean> {
    const isGuest = useUserData.getState().authenticationStatus === AuthenticateStatus.NOT_AUTHENTICATED;

    const formActions: Record<ProcurementFormPermission, boolean> = {
        [ProcurementFormPermission.EDIT]: formDetails.form_action_permissions?.editable == true && formDetails.form_action_permissions?.updatable_directly !== true && formDetails.status !== procurementStatus.REJECTED && formDetails.status !== procurementStatus.DECLINED && userType == UserPortalPermission.owner && !isPartOfBundle,
        [ProcurementFormPermission.EditAsMainAction]: formDetails.form_action_permissions?.editable == true && formDetails.form_action_permissions?.updatable_directly !== true && [procurementStatus.REJECTED, procurementStatus.DECLINED].includes(formDetails.status) && userType == UserPortalPermission.owner && !isPartOfBundle,
        [ProcurementFormPermission.EditAndSend]: (formDetails.status == procurementStatus.DECLINED || formDetails.status == procurementStatus.PARTIALLY_DECLINED) && userType == UserPortalPermission.owner && !isPartOfBundle,
        [ProcurementFormPermission.CANCEL_APPROVAL]: formDetails.form_action_permissions?.can_cancel_approval_request == true && userType == UserPortalPermission.owner && !isPartOfBundle,
        [ProcurementFormPermission.CANCEL]: (formDetails.status == procurementStatus.SENT_TO_SELLER && userType == UserPortalPermission.owner && !isPartOfBundle) || canChangeResponse || false,
        [ProcurementFormPermission.DELETE]: formDetails.form_action_permissions?.deletable == true && userType == UserPortalPermission.owner,
        [ProcurementFormPermission.SEND_FOR_APPROVAL]: formDetails.form_action_permissions?.can_send_for_approval == true && userType == UserPortalPermission.owner && !isPartOfBundle && (formDetails.products ? formDetails.products?.length > 0 : true),
        [ProcurementFormPermission.APPROVE]: formDetails.form_action_permissions?.can_approve_approval_request == true && userType == UserPortalPermission.approver && hasApprovalPermission,
        [ProcurementFormPermission.REJECT]: formDetails.form_action_permissions?.can_reject_approval_request == true && userType == UserPortalPermission.approver && hasApprovalPermission,
        [ProcurementFormPermission.EDIT_RESPONSE]: formDetails.form_action_permissions?.can_edit_approval_decision == true && userType == UserPortalPermission.approver && hasApprovalPermission,
        [ProcurementFormPermission.SEND_TO_SELLER]: formDetails.form_action_permissions?.can_send_to_counter_party == true && userType == UserPortalPermission.owner,
        [ProcurementFormPermission.SEND_TO_SELLERS]: (formDetails.form_action_permissions?.can_send_to_counter_party == true && !isAllQuotesExpired && userType == UserPortalPermission.owner && !isPartOfBundle) || false,
        [ProcurementFormPermission.CONFIRM]: formDetails.form_action_permissions?.can_receiver_accept && (isGuest || (generatePermission == true && (formDetails.status == procurementStatus.PO_RECEIVED && !canChangeResponse) || (formDetails.status == procurementStatus.DECLINED && canChangeResponse))) || false,
        [ProcurementFormPermission.DECLINE]: formDetails.form_action_permissions?.can_receiver_decline && (isGuest || (generatePermission == true && (formDetails.status == procurementStatus.PO_RECEIVED && !formDetails.is_expired && !canChangeResponse) || (formDetails.status == procurementStatus.CONFIRMED && canChangeResponse))) || false,
        [ProcurementFormPermission.CHANGE_RESPONSE]: formDetails.form_action_permissions?.can_receiver_change_response == true && generatePermission == true && !isPartOfBundle && !canChangeResponse,
        [ProcurementFormPermission.RECALL_Bundle]: (formDetails.form_action_permissions?.recallable == true && userType == UserPortalPermission.owner && isThereOneChildCanBeRecalled && !isPartOfBundle) || false,
        [ProcurementFormPermission.RECALL]: formDetails.form_action_permissions?.recallable == true && userType == UserPortalPermission.owner,
        [ProcurementFormPermission.ADD_PRODUCTS]: formDetails.form_action_permissions?.updatable_directly == true && userType == UserPortalPermission.owner,
        [ProcurementFormPermission.UPDATE_PO]: (formDetails.form_action_permissions?.updatable_directly == true && formDetails.form_action_permissions?.editable == true) && userType == UserPortalPermission.owner,
        [ProcurementFormPermission.PUBLISH]: formDetails.status == procurementStatus.DRAFT && userType == UserPortalPermission.owner,
        [ProcurementFormPermission.REQUEST_EXTENSION]: formDetails.form_action_permissions?.can_request_extension == true && formDetails.form_action_permissions?.can_receiver_change_response == false,
        [ProcurementFormPermission.DECLINE_REQUEST_EXTENSION]: formDetails.form_action_permissions?.can_decline_request_extension == true,
        [ProcurementFormPermission.EDIT_FROM_SCRATCH]: formDetails.form_action_permissions?.updatable_directly != true && formDetails.form_action_permissions?.editable == true && userType == UserPortalPermission.owner && !isPartOfBundle,
        [ProcurementFormPermission.REQUEST_EXTENSION_FOR_EXPIRED_QUOTE_BUYER]: formDetails.quote?.form_action_permissions?.can_request_extension == true,
        [ProcurementFormPermission.CANCEL_FORM]: formDetails.form_action_permissions?.can_revoke == true,

    };
    return formActions;
}

export function buildProcurementFormDetails(formDetails: ProcurementModelDetails, permission: string, isPartOfBundle?: boolean, hasOwner?: boolean, canChangeResponse?: boolean) {
    let userType = '' as UserPortalPermission
    let approver = null
    const userData = useUserData.getState()
    const canApprovePO = userData[permission]
    if (hasOwner && formDetails?.owner?.id == userData.userInfo?.id) userType = UserPortalPermission.owner
    else if (hasOwner) {
        const listOfApprovers = useGetListOfApprovals(formDetails.owner.id, formDetails.approvers ?? [])
        approver = listOfApprovers?.find(approver => approver.approver_id == userData.userInfo?.id)
        if (approver) {
            userType = UserPortalPermission.approver
            formDetails = { ...formDetails, approvers: listOfApprovers, status: approver?.status, originalStatusForApprover: formDetails.status }
        }
    }

    let canGeneratePO = userData?.canCreatePO
    let canGenerateQuote = userData[permission]

    return {
        model: formDetails,
        numberOfProducts: formDetails.products?.length ?? 0,
        actions: getProcurementFormFormActions(formDetails, userType, canApprovePO, isPartOfBundle, canChangeResponse, false, false, canGeneratePO || canGenerateQuote)
    };
}

export function buildProcurementBundleFormDetails(formDetails: ProcurementModelDetails, permission: string, bundleChildren: string) {
    let userType = '' as UserPortalPermission
    const userData = useUserData.getState()
    const canApprovePO = userData[permission]

    let generatePermission = userData?.canCreatePO

    const childrenFormDetails = formDetails[bundleChildren]?.map((child) => buildProcurementFormDetails({ ...child, company_details: child.buyer_details }, permission, true, true))

    if (formDetails.owner.id == userData.userInfo?.id) userType = UserPortalPermission.owner
    else {
        let isApprover = childrenFormDetails[0].model.approvers.filter(approver => approver.approver_id == userData.userInfo?.id)[0]
        if (isApprover) {
            userType = UserPortalPermission.approver
            formDetails = { ...formDetails, status: getBundleStatusFromChildren(childrenFormDetails), approvers: getListOfChildrenApprovers(childrenFormDetails) }
        }
    }

    // delete formDetails[bundleChildren]
    // if (formDetails.status == procurementStatus.MIXED) {
    //     hasApprovedStatus = formDetails[bundleChildren]?.find((obj) => obj.status === procurementStatus.APPROVED) !== undefined;
    // }
    let isAllQuotesExpired;
    let isThereOneChildCanBeRecalled;
    let hasApprovedStatus;

    if (formDetails[bundleChildren]) {
        isAllQuotesExpired = formDetails[bundleChildren].every((obj) => obj.is_quote_expired === true);
    } else {
        isAllQuotesExpired = false;
    }

    if (formDetails[bundleChildren]) {
        isThereOneChildCanBeRecalled = formDetails[bundleChildren].some((obj) => obj.form_action_permissions?.recallable === true);
    } else {
        isThereOneChildCanBeRecalled = false;
    }

    if (formDetails[bundleChildren]) {
        hasApprovedStatus = formDetails[bundleChildren].some((obj) => obj.status === procurementStatus.APPROVED);
    } else {
        hasApprovedStatus = false;
    }


    return {
        model: formDetails,
        numberOfProducts: childrenFormDetails.reduce((acc, curr) => { return acc + curr.numberOfProducts }, 0),
        actions: getProcurementFormFormActions(formDetails, userType, canApprovePO, false, false, isAllQuotesExpired, isThereOneChildCanBeRecalled, generatePermission, hasApprovedStatus),
        children: childrenFormDetails
    };
}
export interface ChildApprovalChange {
    id: number;
    type: procurementStatus
    comment: string;
    reasonId: string | null
}
export function bulkApproval(formDetails: BundleProcurementDetails | null, bundleChildren: string, childId: number, status: procurementStatus, comment: string, changes: ChildApprovalChange[], reasonId?: string) {
    const userData = useUserData.getState()
    const childIndex = formDetails?.children.findIndex((child) => child.model.id === childId);
    let child = formDetails?.children[childIndex ?? 0] as ProcurementFormModel
    let newBundleStatus = '' as procurementStatus
    child = {
        ...child, model: {
            ...child.model, status: status, approvers: child?.model?.approvers.map(approver => {
                if (approver.approver_id == userData.userInfo?.id) return { ...approver, status: status }
                return approver
            })
        }
    }
    const childStatuses = formDetails?.children.map((c) => c.model?.status);
    if (childStatuses?.every((s) => s === procurementStatus.APPROVED_BY_ME))
        newBundleStatus = procurementStatus.APPROVED_BY_ME
    else if (childStatuses?.every((s) => s === procurementStatus.REJECTED_BY_ME))
        newBundleStatus = procurementStatus.REJECTED_BY_ME
    else newBundleStatus = procurementStatus.MIXED
    changes = changes.filter((change) => change.id !== childId);
    const change: ChildApprovalChange = { id: childId, type: status === procurementStatus.APPROVED_BY_ME ? 'approve' : 'reject', comment: comment, reasonId: reasonId ?? null };
    changes.push(change);
    const formDetailsModel = formDetails?.model ? produce(formDetails?.model, draftFormDetailsModel => {
        draftFormDetailsModel.status = newBundleStatus
        draftFormDetailsModel.approvers = formDetails?.model?.approvers.map(approver => {
            if (approver.user_name == child.model.seller_details.name) return { ...approver, status: status }
            return approver
        })
        draftFormDetailsModel[bundleChildren] = formDetails.model[bundleChildren].map(formChild => {
            if (formChild.id == childId) return child.model
            return formChild
        })
    }) : null
    return {
        changes: changes,
        formDetailsModel: formDetailsModel,
    }
}


export const getNumberofBundleRFQPendingApprovalChild = (bundle: ProcurementModelDetails | null, children: string) => {
    const childStatuses = bundle ? bundle[children].map((c) => c?.rfqModel.status) : null
    const pendingApprovalObjects = childStatuses?.filter(obj => obj === procurementStatus.PENDING_APPROVAL || obj === procurementStatus.PENDING_MY_APPROVAL);
    if (pendingApprovalObjects) return pendingApprovalObjects.length
    else return 0
}

export const getNumberofBundlePendingApprovalChild = (bundle: ProcurementModelDetails | null, children: string) => {
    const childStatuses = bundle ? bundle[children].map((c) => c?.status) : null
    const pendingApprovalObjects = childStatuses?.filter(obj => obj === procurementStatus.PENDING_APPROVAL || obj === procurementStatus.PENDING_MY_APPROVAL);
    if (pendingApprovalObjects) return pendingApprovalObjects.length
    else return 0
}

const getBundleStatusFromChildren = (children: ProcurementFormModel[]) => {
    const statuses = children.map(child => child.model.status);
    const allSame = statuses.every(status => status === statuses[0]);

    const allAllowed = statuses.every(status =>
        status === procurementStatus.PENDING_MY_APPROVAL ||
        status === procurementStatus.APPROVED_BY_ME ||
        status === procurementStatus.REJECTED_BY_ME
    );

    if (allSame && allAllowed) {
        return statuses[0];
    } else {
        return procurementStatus.MIXED;
    }
}


const getListOfChildrenApprovers = (children: ProcurementFormModel[]) => {
    return children.map(child => { return { status: child.model.status, user_name: child.model.name } })
}


export const buildFormProductsTable = (products: ProcurementFormProductDetailsApi[] | null, warrantyTerm?: TermPayload | null) => {
    let ProductsArray: ProcurementFormProduct[] = []
    products?.map((product: ProcurementFormProductDetailsApi) => ProductsArray.push({
        id: product.id,
        productId: product.product.id,
        productDetails: {
            ...product.product,
            name: product.product.name,
            partNumber: product.product.part_number,
            // image_url: product.product.category.image_url,
            selected: product.selected ?? false,
        },
        brandImage: product.product.brand.image_url,
        // warranty_term: product.warranty_term?.id ? { id: product.warranty_term?.id, name: product.warranty_term?.name, value: product.warranty_term?.value } : { id: warrantyTerm?.id, name: warrantyTerm?.name, value: warrantyTerm?.value },
        warranty_term: product.warranty_term?.id ? { id: product.warranty_term?.id, name: product.warranty_term?.name, value: product.warranty_term?.value } : { id: '', name: '', value: '' },
        requestedQuantity: product.requested_quantity,
        availableQuantity: product.available_quantity,
        unitPrice: product.unit_price,
        remark: product.remarks ?? '',
        additional_requirements: product.additional_requirements
    }))
    return ProductsArray
}

export enum RFQType {
    BUNDLE_RFQ = 'Bundle_RFQ',
    SINGLE_RFQ = 'Single_RFQ'
}

export const useChangeProject = (project: GeneralObject, procurementModel: ProcurementModelDetails, receiverProject?: boolean, containProject?: boolean) => {
    if (containProject) {
        return produce(procurementModel, draftProcurementModel => {
            draftProcurementModel.project = project
        })
    } else if (receiverProject) {
        return produce(procurementModel, draftProcurementModel => {
            draftProcurementModel.receiver_project = project
        })
    } else {
        return produce(procurementModel, draftProcurementModel => {
            draftProcurementModel.sender_project = project
        })
    }
}

export const compareTwoArrayOfObjects = (arr1: any[], arr2: any[]) => {
    function objectsAreSame(x, y) {
        var objectsAreSame = true;
        for (var propertyName in x) {
            if (x[propertyName] !== y[propertyName]) {
                objectsAreSame = false;
                break;
            }
        }
        return objectsAreSame;
    }
    const arraysEqual = (a1, a2) => a1.length === a2.length && a1.every((o, idx) => objectsAreSame(o, a2[idx]));
    return arraysEqual(arr1, arr2)
}

export const useGetAllProjects = (URLByUserType: string) => {
    return request({ url: URLByUserType, method: "GET" })
}

export const useGetSellerProjects = () => {
    return request({ url: apiRoutes.sellerProjects, method: "GET" })
}


export * from './approval-service';
export * from './bundle-purchase-order-buyer';
export * from './bundle-rfq';
export * from './purchase-order-buyer';
export * from './purchase-order-seller';
export * from './quote-buyer';
export * from './quote-seller';
export * from './request-for-quote';
export * from './request-for-quote-buyer';
export * from './requisition';
export * from './table';

