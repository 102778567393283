import { CustomWarrantyText, DescPopup, MultilineTextField, StyledPopper, StyledTable } from '@/components';
import { ProcurementFormProduct, ProcurementModelDetails, Product, ProductCategoryKind, ProductCategoryKindLabel } from '@/models';
import { ArrowDownward } from '@mui/icons-material';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Badge, Box, Button, CardMedia, Grid, ThemeOptions, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import { formatNumber } from 'src/logic/utils/numbers';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';
import QuantityRequestedMoreThanAvailableIndicator from 'src/ui/components/smart/procurement/buyer/purchase-order-buyer/purchase-order-details/QuantityRequestedMoreThanAvailableIndicator';
import BuyerRequirementsIndicator from 'src/ui/components/smart/procurement/products-table/BuyerRequirementsIndicator';
import ProductQuickView from 'src/ui/components/smart/product/product-quick-view';
import { DescriptionIndicator } from '../../requisition/requisition-alternatives-table/components';
import { isTermsEqual } from 'src/ui/components/smart/general-terms/utils';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: `${theme.palette.success.dark}`,
        boxShadow: `0 0 0 1px ${theme.palette.success.dark}`
    },
}));

const useStyles = makeStyles((theme: ThemeOptions) => ({
    cellHead: {
        backgroundColor:  theme?.palette?.background?.primary?.main,
        '& th:first-child': { borderRadius: '6px 0 0 6px', marginTop: 10 },
        '& th:last-child': { borderRadius: '0 6px 6px 0' }, color: theme?.palette?.text.third, fontSize: 16, fontWeight: 400, paddingLeft: 24, textTransform: 'uppercase', width: '400px'
    },
    rowBody: { backgroundColor: theme?.palette?.secondary.contrastText, borderRadius: '8px', fontWeight: 400, padding: 0 },
    styleWithPaddingColumn: { backgroundColor: theme?.palette?.secondary.contrastText, borderRadius: '8px', paddingLeft: 40 },
    styleRemarkColumn: { backgroundColor: theme?.palette?.secondary.contrastText, borderRadius: '8px', paddingLeft: 20 },
    shrink: { width: '1%' },
    big_cell: {  },
    pl: { paddingLeft: '75px' }
}));



type FormProductsTableProps = {
    key: string
    rowsData: ProcurementFormProduct
    formDetails: ProcurementModelDetails
    products: Product[]
    hideAvailable?: boolean
    hideRequested?: boolean
    requestedLabel?: string
    showOrderedQuantityIndicator?: boolean
}

export const FormProductsTable: React.FC<FormProductsTableProps> = ({ key, rowsData, formDetails, products, hideAvailable, hideRequested, requestedLabel, showOrderedQuantityIndicator = false }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const columns = React.useMemo<ColumnDef<ProcurementFormProduct>[]>(
        () => [
            {
                accessorKey: 'productDetails.category_kind',
                id: 'productDetails.category_kind',
                header: 'segment',
                meta: {
                    filterType: 'search',
                    columnIcon: null,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: `${classes.rowBody} ${classes.shrink}`
                    }
                },
                cell: info => {
                    const product = products[info.row.index]?.product
                    return <Typography width='100%' color="#3A3E50" fontSize={11} fontWeight={400} textTransform={'uppercase'} textAlign={'center'} px={1}>{ProductCategoryKindLabel[product?.category_kind]}</Typography>    
                },
                enableColumnFilter: false,
                enableSorting: false 
            },
            {
                accessorKey: 'productDetails',
                id: 'productDetails',
                header: 'product_details',
                cell: info => {
                    const additional_requirements = products[info.row.index]?.additional_requirements
                    const product = products[info.row.index]?.product
                    return <ProductTableDetails content={info.getValue()} product={product} additional_requirements={additional_requirements} />
                },                
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: `${classes.cellHead} ${classes.pl}`,
                    tableRow: {
                        cellClass: `${classes.rowBody} ${classes.big_cell}`
                    }
                },
                enableColumnFilter: false,
                enableSorting: false 
            },
            // {
            //     accessorKey: 'brandImage',
            //     id: 'brandImage',
            //     header: 'brand',
            //     cell: (info) => <BrandTableImage imageUrl={info.getValue()} />,
            //     meta: {
            //         filterType: 'search',
            //         columnIcon: UnfoldMoreIcon,
            //         headerCellClass: classes.cellHead,
            //         tableRow: {
            //             cellClass: classes.styleRemarkColumn
            //         }
            //     },
            //     enableColumnFilter: false
            // },
            {
                accessorKey: 'warranty_term',
                id: 'warranty_term',
                header: 'warranty',
                cell: ({ row: { index, original }, getValue }) => {
                    const productWarrantyTerm = original?.warranty_term;

                    return (
                        <Grid container alignItems={'start'} justifyContent={'start'}>
                            <CustomWarrantyText isWarranyTermCustom={productWarrantyTerm?.id && !isTermsEqual(formDetails?.terms_bundle?.warranty_term, productWarrantyTerm)} warrantyName={productWarrantyTerm?.name} />
                        </Grid>
                    )
                },
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleRemarkColumn
                    }
                },
                enableColumnFilter: false,
                enableSorting: false 
            },
            {
                accessorKey: 'availableQuantity',
                id: 'availableQuantity',
                header: hideRequested ? 'quantity' : 'available',
                cell: ({ row: { index, original }, getValue }) => (
                    <Grid container pl={2}>
                        <Typography>{getValue()}</Typography>
                        {getValue() ? (getValue() && original.requestedQuantity > getValue()) && <ArrowDownward color='error' sx={{ fontSize: 20, ml: 1 }} /> : '_'}
                    </Grid>
                ),
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleWithPaddingColumn
                    }
                },
                enableColumnFilter: false,
                enableSorting: false 
            },
            {
                accessorKey: 'requestedQuantity',
                id: 'requestedQuantity',
                header: requestedLabel ?? 'requested',
                cell: info => {
                    const { availableQuantity, requestedQuantity } = info.row.original;
                    const isRequestedMoreThanAvailable = showOrderedQuantityIndicator && requestedQuantity != undefined && availableQuantity != undefined && requestedQuantity > availableQuantity;

                    return <Quantity quantity={info.getValue()} isRequestedMoreThanAvailable={isRequestedMoreThanAvailable} availableQuantity={availableQuantity} requestedQuantity={requestedQuantity} />
                },
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleWithPaddingColumn
                    }
                },
                enableColumnFilter: false,
                enableSorting: false 
            },
            {
                accessorKey: 'unitPrice',
                id: 'unitPrice',
                header: 'unit_price',
                cell: info => {
                    let value = info.getValue()
                    let price = parseInt(value ?? 0)
                    return (<Typography variant='subtitle2' > {t('texts.aed')} {formatNumber(price ?? 0)} </Typography>)
                },
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleRemarkColumn
                    }
                },
                enableColumnFilter: false,
                enableSorting: false 
            },
            {
                accessorKey: 'remark',
                id: 'remark',
                header: 'remarks',
                cell: info => (<RemarkButton remark={info.getValue()} contentDetails={info.row.original} />),
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleRemarkColumn
                    }
                },
                enableColumnFilter: false,
                enableSorting: false 
            },
        ]
        ,
        [requestedLabel, showOrderedQuantityIndicator, formDetails?.terms_bundle?.warranty_term]
    )

    const columnsToDisplay = React.useMemo(() => {
        let ret = columns;
        if(hideAvailable) {
            ret = ret?.filter(column => column.accessorKey != "availableQuantity");
        }
        if(hideRequested) {
            ret = ret?.filter(column => column.accessorKey != "requestedQuantity");
        }
        return ret;
    }, [columns, hideAvailable, hideRequested])

    return (<StyledTable key={key} rows={rowsData} columns={columnsToDisplay} style={{ overflow: 'visible' }} />)
}

export const ProductTableDetails = ({ content, product, clickable = true, additional_requirements }) => {
    const detailsPopup = usePopup();

    return (
      <>
        {clickable && (
          <DescPopup
            big
            open={detailsPopup.isOpen}
            handleClose={detailsPopup.handleClose}
            paperSx={{ borderRadius: 0 }}
          >
            <ProductQuickView product={product} />
          </DescPopup>
        )}
        <Grid container>
          <Grid item md={2}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CardMedia
                component='img'
                sx={{ objectFit: 'contain', height: '50px', width: '50px' }}
                image={product?.image_url}
                alt={''}
              />
            </Box>
          </Grid>
          <Grid item md={10}>
            <div style={{ display: 'flex' }}>
              <Typography
                variant='body2'
                fontWeight={700}
                color={'text.disabled'}
                className={'underline-on-hover cursor-pointer'}
                onClick={clickable ? detailsPopup.handleOpen : () => {}}
                noWrap
              >
                {content.name}
              </Typography>
              {product?.is_private_product && <PrivacyButton disableText />}
            </div>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <DescriptionIndicator
                description={product.description}
                category_kind={product.category_kind}
              />
              {product.category_kind === ProductCategoryKind.PRODUCT ? (
                <Typography fontSize={13} fontWeight={400} color='#8B8D98'>
                  {product.part_number}
                </Typography>
              ) : (
                <BuyerRequirementsIndicator additional_requirements={additional_requirements} />
              )}
            </div>
          </Grid>
        </Grid>
      </>
    );
}

export const RemarkButton = ({ remark, contentDetails, deleteRemarkHandler, handleRemarkChange }) => {
    const { t } = useTranslation();
    const [remarkPopup, setOpenRemarkPopup] = React.useState<boolean>(false);
    const [readRemark, setReadRemark] = React.useState<boolean>(false);
    const [editRemark, seteditRemark] = React.useState<string>('');
    const openRemarkPopup = () => { setOpenRemarkPopup(true), setReadRemark(true) }
    const closeRemarkPopup = () => setOpenRemarkPopup(false)

    const editRemarkHandler = () => seteditRemark(remark)
    const changeRemark = (remark: string) => seteditRemark(remark)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => { setAnchorEl(event.currentTarget), setReadRemark(true) }
    const handleClosePopper = () => setAnchorEl(null)
    const handleSaveRemark = () => {
        handleRemarkChange(editRemark)
        seteditRemark('')
    }
    return (
        <>
            {remark && <Box sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }} onClick={openRemarkPopup}>
                <Typography variant="subtitle2" fontWeight={400} mr={0.5} color="text.third" >
                    <StyledBadge invisible={readRemark} overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} variant="dot">
                        <StickyNote2OutlinedIcon sx={{ height: 20 }} />
                    </StyledBadge>
                </Typography>
                <Typography variant="subtitle2" fontWeight={400} mr={1} sx={{ textTransform: 'lowercase' }}
                    onMouseOver={handleOpenPopper} onMouseOut={handleClosePopper}>1 {t('texts.remark')} </Typography>
            </Box>}

            <DescPopup medium handleClose={closeRemarkPopup} open={remarkPopup}>
                <Grid container gap={2}>
                    <Grid item md={12} textAlign='center'>
                        <StickyNote2OutlinedIcon sx={{ color: 'primary.main', fontSize: 40 }} />
                        <Grid item md={12} textAlign='center'>
                            <Typography variant='overline' color='primary.main' fontWeight="700" my={1}>
                                {t('texts.remark')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={12} sx={{ bgcolor: 'error.darker', borderRadius: '8px' }} m={3} p={2}>
                        <Grid container sx={{ display: 'flex', flexDirection: 'row' }} alignItems={'center'} justifyContent="space-between">
                            <Grid item md={8}>
                                <Typography variant='h6' fontWeight={700} color={'text.disabled'}
                                    sx={{ width: '100%', display: '-webkit-box', WebkitLineClamp: 1, lineBreak: 'loose', WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }} >
                                    {contentDetails.productDetails?.name}
                                </Typography>
                            </Grid>

                            {!editRemark && <Grid item md={4} justifyContent={'end'}>
                                {handleRemarkChange && <Button sx={{ color: "text.third" }} onClick={editRemarkHandler}> {t('buttons.edit')}  </Button>}
                                {deleteRemarkHandler && <Button color="error" onClick={deleteRemarkHandler}>  {t('buttons.delete')}  </Button>}
                            </Grid>}
                        </Grid>
                        {editRemark ? <>
                            <MultilineTextField value={editRemark} placeholder={t('input_fields.type_your_remark')} handleValueChange={val => changeRemark(val)} />
                            <Grid item md={12}><Button variant='contained' onClick={handleSaveRemark} fullWidth>{t('buttons.save')}</Button> </Grid>
                        </>
                            : <Typography variant='body2' fontWeight={400} color={'text.disabled'} mt={1}>{remark}</Typography>}
                    </Grid>

                </Grid>
            </DescPopup>

            <StyledPopper open={open} anchorEl={anchorEl} handleClose={handleClosePopper} >
                <Grid container p={1} width={'200px'} justifyContent="start">
                    <Typography variant='body2' fontWeight={400} color={'text.disabled'} mt={1}>{remark}</Typography>
                </Grid>
            </StyledPopper>
        </>
    )
}

const Quantity = ({ quantity, isRequestedMoreThanAvailable, availableQuantity, requestedQuantity }) => {
    const { t } = useTranslation();
    return (
        <Grid container alignItems="center">
            <Typography variant="subtitle2" color='text.third'>{t('texts.qty')}:</Typography>
            <span style={{ marginInlineEnd: '5px' }}>{quantity}</span>
            {isRequestedMoreThanAvailable && <QuantityRequestedMoreThanAvailableIndicator availableQuantity={availableQuantity} requestedQuantity={requestedQuantity} />}
        </Grid>
    )
}

export const BrandTableImage = ({ imageUrl }) => {
    return (
        <Grid container>
            <Box sx={{ height: '25px', width: '50px' }} m={1}>
                <CardMedia component="img" sx={{ objectFit: 'contain', height: '100%' }} image={imageUrl} />
            </Box>
        </Grid>
    )
}