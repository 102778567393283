import {
  ConfirmPopup,
  EditableTextField,
  PurchaseOrderProductActions,
  TextFieldCounter,
  TwoButtonsWithHoverToggle,
} from '@/components';
import { AuthenticateStatus, ProductManagerEnv, ProductManagerEnvItem, productManagerEnv } from '@/config';
import { getInputsTranslationKey } from '@/hooks';
import { Product, ProductCategoryKind, Seller } from '@/models';
import { NotifyError, NotifySuccess } from '@/services';
import { useProcurementStore, useUserData } from '@/zustand';
import { InfoOutlined, ShoppingCart } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';
import { t } from 'i18next';
import { useTranslation } from 'next-i18next';
import React, { FC, useState } from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import { useAdvancedFiltersSlice } from 'src/logic/zustand/catalouge/advanced-filters';
import { sm } from 'src/site-map';
import { ListPricePopup } from '../../shared/list-price-popup';
import SignupRequiredPopup from '../../shared/signup-required-popup';
import { CatalogActions } from './catalog';
import { EditBundleRfqProductActions } from './editing-rfq';
import LinkMultipleSellerAction from './link-multiple-sellers';
import LinkSellerAction from './link-seller';
import { QuoteProductActions } from './quote';
import { MainProductRequisitionButton, RequisitionProductActions } from './requisition';
import { LoadingButton } from '@mui/lab';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import { linkProduct } from 'src/logic/services/catalogue/private-seller';

type MainProductCardButtonsProps = {
  product: Product;
  canPerformActionRequisition: boolean;
  canPerformActionPO: boolean;
  isPageAlternative?: boolean;
  seller?: Seller;
};

export const MainProductCardButtons: React.VFC<MainProductCardButtonsProps> = props => {
  return (
    <Grid container p={2} justifyContent={'center'} onClick={event => event.stopPropagation()} gap={1}>
      {getProductActions(
        props.product,
        props.canPerformActionRequisition,
        props.canPerformActionPO,
        props.isPageAlternative,
        props.seller
      )}
    </Grid>
  );
};
const getProductActions = (
  product: Product,
  canPerformActionRequisition: boolean,
  canPerformActionPO: boolean,
  isPageAlternative?: boolean,
  seller?: Seller
) => {
  const { data } = useProcurementStore(state => state.productFormManagerProperties);
  const isSeller = useUserData().isSeller();

  switch (data.environment?.type) {
    case ProductManagerEnv.catalog:
      return <CatalogActions product={product} />;
    case ProductManagerEnv.quote:
    case ProductManagerEnv.edit_quote_from_scratch:
      return <QuoteProductActions product={product} isPageAlternative={isPageAlternative} />;
    case ProductManagerEnv.edit_po_from_scratch:
    case ProductManagerEnv.purchase_order:
      return <PurchaseOrderProductActions product={product} isPageAlternative={isPageAlternative} seller={seller} />;
    case ProductManagerEnv.requisition:
      return <RequisitionProductActions product={product} isPageAlternative={isPageAlternative} />;
    case ProductManagerEnv.edit_bundle_rfq:
      return <EditBundleRfqProductActions product={product} isPageAlternative={isPageAlternative} />;
    default:
      if (isSeller) return <ProductsSellerMainActions product={product} />;
      if (canPerformActionRequisition)
        return (
          <ProductsMainActions
            canPerformActionPO={canPerformActionPO}
            product={product}
            isPageAlternative={isPageAlternative}
            seller={seller}
          />
        );
  }
};

const ProductsMainActions: React.FC<{
  product: Product;
  canPerformActionPO: boolean;
  isPageAlternative?: boolean;
  seller?: Seller;
}> = props => {
  const loginPopup = usePopup();
  const isGuest = useUserData().authenticationStatus === AuthenticateStatus.NOT_AUTHENTICATED;
  const isProductLinkedToSeller = React.useMemo(() => {
    if (props.seller) {
      return props.product?.sellers?.find(sellerItem => sellerItem.seller.id == props.seller?.seller.id) !== undefined;
    }
    return true;
  }, [props.product, props.seller]);

  if (!isProductLinkedToSeller) {
    return <LinkSellerAction product={props.product} seller={props.seller} />;
  }

  return (
    <>
      <ProductQuantity product={props.product} isPageAlternative={props.isPageAlternative} />
      <MainProductRequisitionButton {...props.product} />
      {isGuest ? (
        <>
          <Button
            type='button'
            color='primary'
            variant='contained'
            onClick={loginPopup.handleOpen}
            fullWidth
            startIcon={<ShoppingCart />}
          >
            Add to P.O.
          </Button>
          {/* <LoginPopup loginPopup={loginPopup} refreshOnLogin /> */}
          <SignupRequiredPopup
            open={loginPopup.isOpen}
            handleClose={loginPopup.handleClose}
            signupUrl={sm.register.asBuyer.index.url}
          />
        </>
      ) : (
        <>
          {props.canPerformActionPO && (
            <>
              {props.product?.sellers?.length != 0 ? (
                <AddProductToEnvironmentButton
                  product={props.product}
                  titleForAddedProduct={'added_to_po'}
                  environment={productManagerEnv.purchase_order}
                  titleForAddingProduct={t('buttons.add_to_PO')}
                  isPageAlternative={props.isPageAlternative}
                  seller={props.seller}
                />
              ) : (
                <LinkMultipleSellerAction product={props.product} />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export const ProductsSellerMainActions: React.FC<{ product: Product; hidePriceInput?: boolean }> = props => {
  const { canGenerateQuote } = useUserData();
  const loadingState = useLoadingState();
  const { linkProduct } = useAdvancedFiltersSlice(state => state.actions);
  const handleLinkProduct = () => {
    loadingState.startLoading();
    linkProduct(props.product?.id, {
      onSuccess: () => {
        NotifySuccess('Product Linked');
        loadingState.finishLoading();
      },
      onError: loadingState.finishLoading,
    });
  }

  if (!canGenerateQuote) {
    return null;
  }

  if (!props.product?.part_of_seller_catalog) {
    return (
      <>
        {!props.hidePriceInput && <PriceInputForEnvironmentProduct product={props.product} />}
        <LoadingButton
          loading={loadingState.isLoading}
          variant="contained"
          color="success"
          type="button"
          fullWidth
          onClick={handleLinkProduct}
        >
          Link
        </LoadingButton>
      </>
    )

  }
  return (
    <>
      <ProductQuantity product={props.product} />
      {!props.hidePriceInput && <PriceInputForEnvironmentProduct product={props.product} />}
      <AddProductToEnvironmentButton
        product={props.product}
        titleForAddedProduct={'added_to_Quote'}
        environment={productManagerEnv.quote}
        titleForAddingProduct={t('buttons.add_to_quote')}
      />
    </>
  );
};

const EndEndornmentPrice = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClosePopper = () => setAnchorEl(null);
  return (
    <>
      <InfoOutlined
        sx={{ color: 'warning.active', fontSize: '18px', mr: 0.5 }}
        onMouseOver={handleOpenPopper}
        onMouseOut={handleClosePopper}
      />

      <ListPricePopup open={open} anchorEl={anchorEl} handleClosePopper={handleClosePopper} />

      {/* <StyledPopper open={open} anchorEl={anchorEl} handleClose={handleClosePopper}>
                <Grid sx={{ mt: 2, width: 300, height: 100 }} >
                    <Grid md={12} p={2}>
                        <Typography color='primary.main' variant='h6' sx={{ display: 'flex', alignSelf: 'center' }} onMouseOver={handleOpenPopper} onMouseOut={handleClosePopper}>
                            <InfoOutlined sx={{ fontSize: '20px', mr: 0.5 }} />{t('texts.purchase_order_price')}
                        </Typography>
                        <Typography mt={1} color='text.disabled' variant='subtitle2' onMouseOver={handleOpenPopper} onMouseOut={handleClosePopper}>
                            {t('texts.purchase_order_price_warning')}
                        </Typography>
                    </Grid>
                </Grid>
            </StyledPopper> */}
    </>
  );
};

export const ProductEditPrice: FC<{
  handleChangePrice: (val: string) => void;
  value: string;
  placeholder?: string;
  showEndAdornment: boolean;
  onBlur?: () => void;
  autoFocus?: boolean;
}> = props => {
  const { t } = useTranslation();
  const { data } = useProcurementStore(state => state.productFormManagerProperties);

  return (
    <EditableTextField
      endAdornment={props.showEndAdornment && data.environment?.type !== ProductManagerEnv.catalog ? EndEndornmentPrice() : undefined}
      handleChange={props.handleChangePrice}
      value={props.value}
      type='number'
      // placeholder={props.placeholder ? t(getInputsTranslationKey('labels.unit_price')) + " " + props.placeholder : t(getInputsTranslationKey('placeholders.unit_price'))}
      placeholder={t(getInputsTranslationKey('placeholders.enter_the_price'))}
      onBlur={props.onBlur}
      autoFocus={props.autoFocus}
    />
  );
};
export const ProductQuantity: React.FC<{ product: Product; isPageAlternative?: boolean }> = ({
  product,
  isPageAlternative,
}) => {
  const envStore = useProcurementStore(state => state.productFormManagerProperties);

  const { changeProductQuantity, changeProductAlternativesProductQuantity } = useProcurementStore();
  const resultsActions = useAdvancedFiltersSlice(state => state.actions);

  const handleChangeQuantity = (quantity: number) => {
    if (isPageAlternative) {
      changeProductAlternativesProductQuantity(product, quantity);
    } else {
      if (product.inCart) {
        changeProductQuantity(quantity, product);
      }
      resultsActions.changeProductQuantity(product.id, quantity);
      if (envStore.data.environment) {
        // envStore.actions.setQuantity(product, quantity)
      }
    }
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} width='100%'>
      <TextFieldCounter value={product.quantity} changeQuantity={handleChangeQuantity} />
    </Box>
  );
};
export const AddProductToEnvironmentButton: FC<{
  product: Product;
  titleForAddedProduct: string;
  environment: ProductManagerEnvItem;
  titleForAddingProduct: string;
  isPageAlternative?: boolean;
  seller?: Seller;
}> = ({ product, titleForAddedProduct, environment, titleForAddingProduct, isPageAlternative, seller }) => {
  const { data, actions } = useProcurementStore(state => state.productFormManagerProperties);
  const authenticationStatus = useUserData(state => state.authenticationStatus);
  const removeProductPopup = usePopup();
  const setCustomLoginPopup = useUserData(state => state.setCustomLoginPopup);

  const handleAddProductToEnvironment = () => {
    actions.setProductIdRequestedToBeAdded(product.id);
    if (authenticationStatus == AuthenticateStatus.AUTHENTICATED) {
      if (product.category_kind === ProductCategoryKind.SERVICE && !product.additional_requirements) {
        NotifyError('Missing Field: Buyer Requirements');
        return;
      }

      if (data.environment) actions.addProduct(product, isPageAlternative);
      else {
        const productToAdd = seller
          ? { ...product, quantity: seller?.quantity ?? product?.quantity ?? 1, price: seller?.price }
          : { ...product };
        actions.setCandidateProductToAdd(productToAdd);
        if (seller) actions.setCandidateSellerRelatedToForm(seller);
        actions.setProductManagerPopupStatus(environment.productManagerPopupStatus);
      }
    } else {
      setCustomLoginPopup(true);
    }
  };
  const handleRemoveProductFromEnvironment = () => {
    actions.removeProduct(product, isPageAlternative);
    removeProductPopup.handleClose();
  };

  return (
    <>
      {product.inCart ? (
        <>
          <TwoButtonsWithHoverToggle
            handleClickHiddenButton={removeProductPopup.handleOpen}
            hiddenButtonTitleKey='remove'
            mainButtonTitleKey={titleForAddedProduct}
          />
          <ConfirmPopup
            titleKey={t('popups.titles.remove_product')}
            subTitleKey={t('popups.subTitles.remove_product', { formName: data.formDetails?.name })}
            buttonTitleKey={t('buttons.remove')}
            handleClose={removeProductPopup.handleClose}
            handleConfirm={handleRemoveProductFromEnvironment}
            open={removeProductPopup.isOpen}
          />
        </>
      ) : (
        <Button
          variant='contained'
          onClick={handleAddProductToEnvironment}
          fullWidth
          startIcon={environment?.mainIconComponent}
          sx={{ lineHeight: 1 }}
        >
          {titleForAddingProduct}
        </Button>
      )}
    </>
  );
};
export const PriceInputForEnvironmentProduct: FC<{
  product: Product;
  isPageAlternative?: boolean;
  placeholder?: string;
}> = ({ placeholder, product, isPageAlternative }) => {
  const { changeProductPrice, changeAlternativesDetailsPrice } = useProcurementStore();
  const resultsActions = useAdvancedFiltersSlice(state => state.actions);

  const handleChangeProductPriceFromEnvironment = (price: string) => {
    isPageAlternative ? changeAlternativesDetailsPrice(product, price) : changeProductPrice(product, price);
    resultsActions.changeProductPrice(product.id, price);
  };
  return (
    <ProductEditPrice
      placeholder={placeholder}
      handleChangePrice={handleChangeProductPriceFromEnvironment}
      value={product.price}
      showEndAdornment
      isPageAlternative={isPageAlternative}
    />
  );
};

export * from './purchase-order';
export * from './requisition';

