import { useEffect } from 'react';
import { useProcurementStore } from '@/zustand';

const useSetHeaderData = (title: string | undefined, total: any) => {
    const { setHeaderData } = useProcurementStore((state) => state.productFormManagerProperties.actions);

    useEffect(() => {
        setHeaderData(title, total);
        return () => {
            setHeaderData('', null);
        };
    }, [title, total]);
};

export default useSetHeaderData;