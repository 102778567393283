import { BundleRFQ, Product, SellerData } from '@/models';
import { useGetBundleRFQDetailsForEditing, useSaveBundleRfqChanges } from '@/services';
import produce from 'immer';
import { StateCreator } from 'zustand';
import { GeneralTermsSlice } from '../shared/createGeneralTermsSlice';
import { GeneralTermsPayload, TermPayload } from 'src/logic/models/procurement/general-terms';
import { getProductNumberOfSelectedSellers } from './createConvertRequisitionToRFQ';

type ResponseHandlers = {
  onSuccess?: (data: any) => void;
  onError?: () => void;
};
export interface EditBundleRFQDetailsSlice {
  editBundleRFQDetailsSliceProperties: {
    bundleRfqModel: BundleRFQ | null;
    select_sellers_data: any | null;
    loadingBundleRFQ: boolean;
    products: Product[] | null;
    payment_term: TermPayload | null;
    warranty_term: TermPayload | null;
    delivery_term: TermPayload | null;
    isGeneralTermsValid: boolean;
    selectedMainProductId: number;
  };
  editBundleRFQDetailsSliceActions: {
    setProducts: (products: Product[], withLoading?: boolean) => void;
    loadBundleRFQForEditing: (bundleRFQId: number, onSuccess?: (data: any) => void) => void;
    updateGeneralTerms: (terms: GeneralTermsPayload) => void;
    setSelectedMainProductId: (newId: number) => void;
    changeQuantity: (main_product_id: number, newQuantity: number) => void;
    changeRequirements: (main_product_id: number, additional_requirements: string) => void;
    handleSelectWarrantyTermForMainProduct: (main_product_id: number, warranty: TermPayload) => void;
    clearSellerSelections: (main_product_id: number) => void;
    toggleSelectSellerForMainProduct: (main_product_id: number, sellerData: SellerData) => void;
    toggleSelectSellerForAlternativeProduct: (
      main_product_id: number,
      alternative_product_id: number,
      sellerData: SellerData
    ) => void;
    clearState: () => void;
    saveChanges: (responseHandlers: ResponseHandlers) => void;
    linkSellersInEditMode: (productId: number, newSellers: any) => void;
    getNumberOfMainProductsWithNoSellerSelected: () => number | undefined;
  };
}

export const createEditBundleRFQDetailsSlice: StateCreator<EditBundleRFQDetailsSlice> = (set, get, api) => ({
  editBundleRFQDetailsSliceProperties: {
    bundleRfqModel: null,
    loadingBundleRFQ: false,
    select_sellers_data: null,
    products: null,
    payment_term: { id: null, name: '' },
    warranty_term: { id: null, name: '' },
    delivery_term: { id: null, name: '' },
    isGeneralTermsValid: true,
    selectedMainProductId: 0,
  },

  editBundleRFQDetailsSliceActions: {
    clearState: () => {
      set(
        produce(draftState => {
          draftState.editBundleRFQDetailsSliceProperties.bundleRfqModel = null;
          draftState.editBundleRFQDetailsSliceProperties.loadingBundleRFQ = true;
          draftState.editBundleRFQDetailsSliceProperties.select_sellers_data = null;
          draftState.editBundleRFQDetailsSliceProperties.products = null;
          draftState.editBundleRFQDetailsSliceProperties.payment_term = { id: null, name: '' };
          draftState.editBundleRFQDetailsSliceProperties.warranty_term = { id: null, name: '' };
          draftState.editBundleRFQDetailsSliceProperties.delivery_term = { id: null, name: '' };
          draftState.editBundleRFQDetailsSliceProperties.isGeneralTermsValid = true;
          draftState.editBundleRFQDetailsSliceProperties.selectedMainProductId = 0;
        })
      );
    },
    setProducts: (products: Product[], withLoading: boolean = false) => {
      set(
        produce(draftState => {
          if (withLoading) {
            draftState.editBundleRFQDetailsSliceProperties.loadingBundleRFQ = true;
          }

          draftState.editBundleRFQDetailsSliceProperties.products = products;
        })
      );
    },
    loadBundleRFQForEditing: (bundleRFQId: number, onSuccess?: (data: any) => void) => {
      set(
        produce(draftState => {
          draftState.editBundleRFQDetailsSliceProperties.loadingBundleRFQ = true;
        })
      );
      const preview_products =
        get().editBundleRFQDetailsSliceProperties.products?.map(product => ({
          id: product.id,
          quantity: product.quantity,
          additional_requirements: product.additional_requirements ?? '',
          warranty_term_id: product.warranty_term?.id ?? null,
          warranty_term_custom_value: product.warranty_term?.value ?? null,
          catalog_item_ids: product.sellers.filter(item => item.is_selected).map(item => item.data.id),
          alternatives:
            product.alternatives?.map(altProd => ({
              id: altProd.id,
              catalog_item_ids: altProd.sellers.filter(item => item.is_selected).map(item => item.data.id),
            })) ?? [],
        }));

      useGetBundleRFQDetailsForEditing(bundleRFQId, preview_products)
        .then(data => {
          const { bundle, select_sellers_data } = data.data;

          set(
            produce(draftState => {
              draftState.editBundleRFQDetailsSliceProperties.bundleRfqModel = bundle;
              draftState.editBundleRFQDetailsSliceProperties.select_sellers_data = select_sellers_data;
              draftState.editBundleRFQDetailsSliceProperties.selectedMainProductId =
                select_sellers_data.products?.[0]?.main_product?.product?.id;
            })
          );

          const payment_term = get().editBundleRFQDetailsSliceProperties.payment_term;
          const warranty_term = get().editBundleRFQDetailsSliceProperties.warranty_term;
          const delivery_term = get().editBundleRFQDetailsSliceProperties.delivery_term;

          if (!payment_term?.id || !warranty_term?.id || !delivery_term?.id) {
            const terms_bundle = select_sellers_data.terms_bundle;

            set(
              produce(draftState => {
                draftState.editBundleRFQDetailsSliceProperties.payment_term = terms_bundle.payment_term;
                draftState.editBundleRFQDetailsSliceProperties.warranty_term = terms_bundle.warranty_term;
                draftState.editBundleRFQDetailsSliceProperties.delivery_term = terms_bundle.delivery_term;
              })
            )

            const generalTermsSlice = get() as GeneralTermsSlice;
            generalTermsSlice.generalTerms.actions.initializeGeneralTerms(select_sellers_data.terms, terms_bundle);
          }

          get().setTimeline(data.data.bundle.timeline);
          onSuccess?.(data.data);
        })
        .finally(() => {
          set(
            produce(draftState => {
              draftState.editBundleRFQDetailsSliceProperties.loadingBundleRFQ = false;
            })
          );
        });
    },
    getNumberOfMainProductsWithNoSellerSelected: () => {
      return get().editBundleRFQDetailsSliceProperties.products?.filter(
        product => !getProductNumberOfSelectedSellers(product?.sellers)
      ).length;
    },
    updateGeneralTerms: (terms: GeneralTermsPayload) => {
      const isGeneralTermsValid = (terms.payment_term.id && terms.warranty_term.id && terms.delivery_term.id) ? true : false;
      set(
        produce(draftState => {
          draftState.editBundleRFQDetailsSliceProperties.payment_term = terms.payment_term ?? { id: null, name: '' };
          draftState.editBundleRFQDetailsSliceProperties.warranty_term = terms.warranty_term ?? { id: null, name: '' };
          draftState.editBundleRFQDetailsSliceProperties.delivery_term = terms.delivery_term ?? { id: null, name: '' };
          draftState.editBundleRFQDetailsSliceProperties.isGeneralTermsValid = isGeneralTermsValid;
        })
      );
    },
    setSelectedMainProductId: (newId: number) => {
      set(
        produce(draftState => {
          draftState.editBundleRFQDetailsSliceProperties.selectedMainProductId = newId;
        })
      );
    },
    changeQuantity: (main_product_id: number, newQuantity: number) => {
      const products = get().editBundleRFQDetailsSliceProperties.products;
      const updatedProducts = products?.map(prod => {
        if (prod.id === main_product_id) {
          return {
            ...prod,
            quantity: newQuantity,
          };
        }
        return prod;
      });
      set(
        produce(draftState => {
          draftState.editBundleRFQDetailsSliceProperties.products = updatedProducts;
        })
      );
    },
    changeRequirements: (main_product_id: number, additional_requirements: string) => {
      const products = get().editBundleRFQDetailsSliceProperties.products;
      const updatedProducts = products?.map(prod => {
        if (prod.id === main_product_id) {
          return {
            ...prod,
            additional_requirements: additional_requirements,
          };
        }
        return prod;
      });
      set(
        produce(draftState => {
          draftState.editBundleRFQDetailsSliceProperties.products = updatedProducts;
        })
      );
    },
    handleSelectWarrantyTermForMainProduct: (main_product_id: number, warranty: TermPayload) => {
      const products = get().editBundleRFQDetailsSliceProperties.products;

      const updatedProducts = products?.map(prod => {
        if (prod.id === main_product_id) {
          return {
            ...prod,
            warranty_term: warranty,
          };
        }
        return prod;
      });
      set(
        produce(draftState => {
          draftState.editBundleRFQDetailsSliceProperties.products = updatedProducts;
        })
      );
    },
    clearSellerSelections: (main_product_id: number) => {
      const products = get().editBundleRFQDetailsSliceProperties.products;
      const updatedProducts = products?.map(prod => {
        if (prod.id === main_product_id) {
          return {
            ...prod,
            sellers: prod.sellers.map(seller => ({ ...seller, is_selected: false })),
            alternatives: prod.alternatives.map(alt => ({
              ...alt,
              sellers: alt.sellers.map(seller => ({ ...seller, is_selected: false })),
            })),
          };
        }
        return prod;
      });
      set(
        produce(draftState => {
          draftState.editBundleRFQDetailsSliceProperties.products = updatedProducts;
        })
      );
    },
    toggleSelectSellerForMainProduct: (main_product_id: number, sellerData: SellerData) => {
      const products = get().editBundleRFQDetailsSliceProperties.products;
      const updatedProducts = products?.map(prod => {
        if (prod.id === main_product_id) {
          return {
            ...prod,
            sellers: prod.sellers.map(seller => {
              if (seller.data.id === sellerData.data.id) {
                return { ...seller, is_selected: !seller.is_selected };
              }
              return seller;
            }),
          };
        }
        return prod;
      });
      set(
        produce(draftState => {
          draftState.editBundleRFQDetailsSliceProperties.products = updatedProducts;
        })
      );
    },
    toggleSelectSellerForAlternativeProduct: (
      main_product_id: number,
      alternative_product_id: number,
      sellerData: SellerData
    ) => {
      const products = get().editBundleRFQDetailsSliceProperties.products;
      const updatedProducts = products?.map(prod => {
        if (prod.id === main_product_id) {
          return {
            ...prod,
            alternatives: prod.alternatives.map(altProduct => {
              if (altProduct.id === alternative_product_id) {
                return {
                  ...altProduct,
                  sellers: altProduct.sellers.map(seller => {
                    if (seller.data.id === sellerData.data.id) {
                      return { ...seller, is_selected: !seller.is_selected };
                    }
                    return seller;
                  }),
                };
              }
              return altProduct;
            }),
          };
        }
        return prod;
      });
      set(
        produce(draftState => {
          draftState.editBundleRFQDetailsSliceProperties.products = updatedProducts;
        })
      );
    },
    saveChanges: ({ onSuccess, onError }: ResponseHandlers) => {
      const bundleId = get().editBundleRFQDetailsSliceProperties.bundleRfqModel?.id;

      const selected_products = get().editBundleRFQDetailsSliceProperties.products?.map(product => ({
        id: product.id,
        quantity: product.quantity,
        additional_requirements: product.additional_requirements,
        warranty_term_id: product.warranty_term?.id ?? null,
        warranty_term_custom_value: product.warranty_term?.value ?? null,
        catalog_item_ids: product.sellers.filter(item => item.is_selected).map(item => item.data.id),
        alternatives:
          product.alternatives?.map(altProd => ({
            id: altProd.id,
            catalog_item_ids: altProd.sellers.filter(item => item.is_selected).map(item => item.data.id),
          })) ?? [],
      }));

      const payment_term = get().editBundleRFQDetailsSliceProperties.payment_term;
      const warranty_term = get().editBundleRFQDetailsSliceProperties.warranty_term;
      const delivery_term = get().editBundleRFQDetailsSliceProperties.delivery_term;

      useSaveBundleRfqChanges(bundleId, {
        payment_term_id: payment_term?.id,
        payment_term_custom_value: payment_term?.value,
        warranty_term_id: warranty_term?.id,
        warranty_term_custom_value: warranty_term?.value,
        delivery_term_id: delivery_term?.id,
        delivery_term_custom_value: delivery_term?.value,
        selected_products,
      })
        .then(data => {
          onSuccess?.(data.data);
        })
        .catch(() => {
          onError?.();
        });
    },
    linkSellersInEditMode: (productId: number, newSellers: any) => {
      const products = get().editBundleRFQDetailsSliceProperties.products;
      if (!Array.isArray(products)) {
        console.error('Products is not an array or is undefined.');
        return;
      }

      const updatedProducts = products.map(rfqProduct => {
        let newProduct = { ...rfqProduct };
        if (rfqProduct.id === productId) {
          newProduct = {
            ...newProduct,
            sellers: [...newProduct.sellers, ...newSellers],
          };
        }
        newProduct = {
          ...newProduct,
          alternatives: newProduct.alternatives.map(alternativeProduct => {
            if (alternativeProduct.id === productId) {
              return {
                ...alternativeProduct,
                sellers: [...alternativeProduct.sellers, ...newSellers],
              };
            }
            return alternativeProduct;
          }),
        };
        return newProduct;
      });

      set(
        produce(draftState => {
          draftState.editBundleRFQDetailsSliceProperties.products = updatedProducts;
        })
      );
    },
  },
});
