import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';

// const drawerWidth = '15%';
const drawerWidth = 270;
const drawerWithSubMenuWidth = 270;
//Side menu components to adjust for opened and closed menu
const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const openedSubMenuMixin = (theme: Theme): CSSObject => ({
    width: drawerWithSubMenuWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 5px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 5px)`,
    },
});

//Apply the closed and opened styled to the drawer
export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, isDrawerSubMenuOpen }) => ({
        width: drawerWithSubMenuWidth,
        display: 'flex',
        flexShrink: 0,
        zIndex: 2,
        boxSizing: 'content-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && isDrawerSubMenuOpen && {
            ...openedSubMenuMixin(theme),
            '& .MuiDrawer-paper': openedSubMenuMixin(theme),
        }),
        ...(!open && !isDrawerSubMenuOpen && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);
export const ListStack = styled(List, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, isDrawerSubMenuOpen }) => ({
        width: open && !isDrawerSubMenuOpen ? drawerWidth : `calc(${theme.spacing(7)} + 5px)`,
        padding: 8,
    }),
);
export const SubMenuList = styled(List, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, isDrawerSubMenuOpen }) => ({
        width: isDrawerSubMenuOpen ? drawerWithSubMenuWidth : `calc(${theme.spacing(7)} + 5px)`,
        padding: 8,
        display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
    }),
);